import { deleteAPI, getAPI, postAPI } from "utils/api"

export function getCollectionData (id) {

    return getAPI(`collections/${id}`);

};

export function createCollection (data) {

    return postAPI(`collections/create`, data)

};

export function postCollection (id, data) {

    return postAPI(`collections/${id}`, data)

};

export function deleteCollection (id) {

    return deleteAPI(`collections/${id}`)

};

export function getCollectionList ({
    name,
    organizationId,
    page,
    pageSize
} = {}) {
    return getAPI(`collections`, {
        name,
        organization: organizationId,
        page,
        pageSize
    });
};
