import 'assets/css/card.css';

import React from "react";
import { Avatar } from "rsuite";

import ActionToolbar from "./ActionToolbar";
import ActionButton from "./ActionButton";


const CardHeader = ({
    title,
    hint,
    subtitle,
    actions,
    imageURL,
    usePlaceholder,
    icon,
    size,
    disabled
}) => <div className={`cardHeader${disabled ? ' disabled' : ''}`}>
        { icon && (
            <div className="icon">
                {icon}
            </div>
        )}

        { !icon && (imageURL || usePlaceholder) ? (
            <div className="icon">
                <Avatar 
                    src={imageURL} 
                    alt={typeof(title) === 'string' ? title : typeof(hint) === 'string' ? hint : ''}  
                    size={size} />
            </div>
        ) : (
            <div className="icon" style={{width: 0}}>
                <Avatar 
                    alt={typeof(title) === 'string' ? title : ''}  
                    size={size} />
            </div>
        )}

        <div className={`content rs-panel-header${disabled ? ' disabled' : ''}`}  title={hint}>
            <div className="title" title={ !hint && typeof(title) === 'string' ? title : undefined }>
                {title}
            </div>
            { subtitle && (
                <div className="subtitle" title={ !hint && typeof(title) === 'string' ? subtitle : undefined }>
                    {subtitle}
                </div>
            )}
        </div>

        {actions && (
            <ActionToolbar>
                {actions.map((action, i) => <ActionButton key={i} {...action} />)}
            </ActionToolbar>
        )}

    </div>


export default CardHeader;
