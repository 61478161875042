import 'assets/css/modal.css'

import React from "react";

import SimpleModal from './SimpleModal';
import { noop } from "utils/constants";


const FormModal = ({
    title,
    FormClass,
    formData,
    size,
    open,
    setOpen = noop,
    onSubmit = noop,
    onClose = noop,
    ...props
}) => {

    return (
        <SimpleModal
            title={title}
            size={size}
            open={open}
            setOpen={setOpen}
            onClose={onClose}
        >
            <FormClass 
                formData={formData}
                onSubmit={data => {
                    onSubmit(data)
                    setOpen(false);
                    onClose();
                }}
                { ...props }
            />
        </SimpleModal>
    );
};


export default FormModal;
