import React from 'react';

import { getOrganizationData } from "../organizationAPI";


function useOrganization(id) {

    const [organization, setOrganization] = React.useState();

    React.useEffect(() => {
        getOrganizationData(id)
            .then(({data}) => setOrganization(data));
    }, [id]);

    return organization

}

export default useOrganization;
