import React from "react";
import { Message, Nav, toaster } from "rsuite";
import { t } from "i18next";

import { ESRIIcon } from "components/icons";
import { noop } from "utils/constants";

import MapPropertiesForm from "./forms/MapPropertiesForm";
import MapElementList from "./MapElementList";
import ConfigurationList from "./ConfigurationList";
import { deleteConfig, deleteConfigItem, deleteElement, postConfigData, postConfigItemData, postElementData, postNewConfig, postNewConfigItem, postNewElement, postSubLayerData, postWebMapData } from "../webmapAPI";
import WebmapContext from "../context/WebmapContext";
import MapModules from "./MapModules";


const PROPERTIES_TAB = 'properties';
const ELEMENTS_TAB = 'elements';
const CONFIGURATIONS_TAB = 'configurations';
const MODULES_TAB = 'modules'


const MapSettings = () => {
    const [activeTab, setActiveTab] = React.useState(PROPERTIES_TAB);
  
    const {
        webmap, 
        updateWebmap,
    } = React.useContext(WebmapContext);

    const onMapElementReorder = (elementOrder) => {
        postWebMapData(webmap.id, {elementOrder})
            .then(({data}) => updateWebmap(data))
            .catch(err => {
                console.error(err);
                toaster.push(<Message type="error" showIcon  >
                    {t('Map/SaveFailed')}
                </Message>, {duration: 4000});
            });
    };

    const onMapElementChange = async ({id, type, params}) => {
        if (!id) {
            const postData = {
                webMapVersionId: webmap?.versionId,
                ...params,
            };
            return postNewElement(postData)
                .then(({data}) => updateWebmap(data))
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>);
                })
        };

        const func = type ? postElementData : postSubLayerData;
        if (Object.keys(params).includes('delete')) {
            return deleteElement(id)
                .then(({data}) => updateWebmap(data))
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>)
                });
        } else {
            return func(id, params)
                .then(({data}) => {
                    console.log(data);
                    updateWebmap(data)})
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>)
                });
        }
    };

    const onMapConfigReorder = (configOrder) => {
        postWebMapData(webmap.id, {configOrder})
            .then(({data}) => updateWebmap(data))
            .catch(err => {
                console.error(err);
                toaster.push(<Message type="error" showIcon duration={4000} >
                    {t('Map/SaveFailed')}
                </Message>);
            });
    };


    const onMapConfigChange = async ({id, params}) => {
        if (!id) {
            const postData = {
                webMapVersionId: webmap?.versionId,
                ...params,
            };
            return postNewConfig(postData)
                .then(({data}) => updateWebmap(data))
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>);
                })
        };

        if (Object.keys(params).includes('delete')) {
            return deleteConfig(id)
                .then(({data}) => updateWebmap(data))
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>)
                });
        } else {
            return postConfigData(id, params)
                .then(({data}) => {
                    console.log(data);
                    updateWebmap(data)})
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>)
                });
        }
        
    }

    const onMapConfigItemChange = async ({id, params}) => {
        if (!id) {
            return postNewConfigItem(params)
                .then(({data}) => updateWebmap(data))
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>);
                })
        };

        if (Object.keys(params).includes('delete')) {
            return deleteConfigItem(id)
                .then(({data}) => updateWebmap(data))
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>)
                });
        } else {
            return postConfigItemData(id, params)
                .then(({data}) => {
                    console.log(data);
                    updateWebmap(data)})
                .catch(err => {
                    console.error(err);
                    toaster.push(<Message type="error" showIcon duration={4000} >
                        {t('Map/SaveFailed')}
                    </Message>)
                });
        }
        
    }

    return <>
        <Nav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
            <Nav.Item eventKey={PROPERTIES_TAB} icon={<ESRIIcon name="settings2" withLabel />} >
                {t('Map/Properties')}
            </Nav.Item>
            <Nav.Item eventKey={ELEMENTS_TAB} icon={<ESRIIcon name="layer-list" withLabel />} >
                {t('Map/MapElements')}
            </Nav.Item>
            <Nav.Item eventKey={CONFIGURATIONS_TAB} icon={<ESRIIcon name="layers" withLabel />} >
                {t('Map/Configurations')}
            </Nav.Item>
            <Nav.Item eventKey={MODULES_TAB} icon={<ESRIIcon name="sketch-rectangle" withLabel />} >
                {t('Map/Modules')}
            </Nav.Item>
        </Nav>

        { activeTab === PROPERTIES_TAB ? (
            <MapPropertiesForm 
                fluid
                webMap={webmap}
                onSubmit={data => {
                    postWebMapData(webmap.id, data)
                        .then(({data}) => updateWebmap(data))
                        .catch(err => {
                            console.error(err);
                            toaster.push(<Message type="error" showIcon duration={4000}>
                                {t('Map/SaveFailed')}
                            </Message>)
                        })
                }} />
        ) : <></> }

        { activeTab === ELEMENTS_TAB ? <>
            <MapElementList 
                webMap={webmap}
                onChange={onMapElementChange} 
                onSort={onMapElementReorder} />
        </> : <></> }
        

        { activeTab === CONFIGURATIONS_TAB ? <>
            <ConfigurationList
                webMap={webmap}
                onChange={onMapConfigChange}
                onItemChange={onMapConfigItemChange}
                onSort={onMapConfigReorder} />
        </> : <></> }

        { activeTab === MODULES_TAB ? <>
            <MapModules 
                webMap={webmap} 
                updateWebmap={updateWebmap}
            />
        </> : <></>}
        
    </>
};

export default MapSettings;