import { LinkIcon } from 'components/icons';
import React from 'react'
import { Checkbox, Form, IconButton } from 'rsuite'
import { openInNewTab } from 'utils/browser';


const FormGroup = ({
    name,
    label,
    help,
    link,
    linkTitle,
    useHelpTooltip = true,
    error,
    accepter,
    value,
    onChange,
    children,
    labelWidth,
    ...props
}) => {
    const isCheckbox = accepter === Checkbox;
    return (
        <Form.Group  className={`formGroup${isCheckbox ? ' checkboxGroup' : ''}`}>
            { isCheckbox && !labelWidth && (
                <Form.Control
                    name={name}
                    accepter={accepter}
                    checked={value}
                    onChange={(value, checked) => onChange(checked)}
                    {...props}
                    style={{
                        ...(props?.style || {})
                    }} />
            )}
            { label && (
                <Form.ControlLabel style={labelWidth ? {width: labelWidth} : undefined}>
                    {label}
                </Form.ControlLabel>
            )}

            { link && (
                <IconButton 
                    title={linkTitle}
                    appearance='link'
                    size='xs'
                    onClick={() => openInNewTab(link)}
                    icon={<LinkIcon />}
                />
            )}

            { help && useHelpTooltip && (
                <Form.HelpText tooltip>
                    {help}
                </Form.HelpText>
            ) }

            { !isCheckbox && accepter && (
                <Form.Control
                    name={name}
                    accepter={accepter}
                    value={value}
                    onChange={onChange}
                    {...props}
                    style={{
                        ...(props?.style || {})
                    }} />
            )}
            <Form.ErrorMessage show={error}>
                {error}
            </Form.ErrorMessage>
            { help && !useHelpTooltip && (
                <Form.HelpText>
                    {help}
                </Form.HelpText>
            ) }
            {children}
        </Form.Group>
    );
};


export default FormGroup;
