import { AvatarCard } from 'components/cards';
import { COUNT_INTERVAL_TYPES, COUNT_INTERVAL_TYPE_LABELS } from 'features/onboardCount/utils/constants';
import React from 'react'
import { formatDate } from 'utils/format';


const CountParametersCard = ({
    size = "md",
    countIntervalType,
    startDate,
    endDate,
    dayTypeIds = [],
    dayTypes = {},
}) => {
    const typeLabel = COUNT_INTERVAL_TYPE_LABELS[countIntervalType];
    const dayTypeNames = dayTypeIds.map(id => dayTypes[id]?.name);
    const dayTypesLabel = dayTypeNames.length ? dayTypeNames.join(', ') : undefined;

    return (
        <AvatarCard size={size}
            title={typeLabel + (dayTypesLabel ? ` (${dayTypesLabel})` : '')}
            subtitle={formatDate(startDate, {dateSeparator: '.'}) + '-' + formatDate(endDate, {dateSeparator: '.'})}
        />
    );
};


export default CountParametersCard;
