import 'assets/css/badge.css';

import React from 'react';


const Badge = ({
    size="md",
    type="",
    children
}) => {
    return (
        <div className={`badge ${size} ${type}`}>
            {children}
        </div>
    )
}

export default Badge