import { FormGroup } from 'components/forms';
import { isNextDay, secondsFromDate, secondsToDate } from 'features/onboardCount/utils/time';
import { t } from 'i18next';
import React from 'react'
import { Checkbox, Col, DateInput, DatePicker, Form, Row } from 'rsuite';
import { noop } from 'utils/constants';

const OnboardCountDateSelectorFormFields = ({
    countIntervals = {},
    values = {},
    onChange = noop,
}) => {

    const [ countDate, setCountDate ] = React.useState(values?.countDate);

    const [startTime, setStartTime] = React.useState(values?.startTime);
    const [startTimeDate, setStartTimeDate] = React.useState(secondsToDate(values?.startTime));
    const [nextDayStart, setNextDayStart] = React.useState(isNextDay(values?.startTime));
    const [endTime, setEndTime] = React.useState(values?.endTime);
    const [endTimeDate, setEndTimeDate] = React.useState(secondsToDate(values?.endTime));
    const [nextDayEnd, setNextDayEnd] = React.useState(isNextDay(values?.endTime));

    
    React.useEffect(() => {
        onChange({
            countDate,
            startTime,
            endTime,
            countInterval: undefined,
            countIntervalType: undefined,
            startDate: undefined,
            endDate: undefined,
            timeSeries: undefined,
            dayTypeIds: undefined,
        })
    }, [countDate, startTime, endTime]);

    React.useEffect(() => {
        setCountDate(values?.countDate)
    }, [values?.countDate]);
    

    React.useEffect(() => {
        setStartTime(secondsFromDate(startTimeDate, nextDayStart));
    }, [startTimeDate, nextDayStart])

    React.useEffect(() => {
        setEndTime(secondsFromDate(endTimeDate, nextDayEnd));
    }, [endTimeDate, nextDayEnd])


    return <>
        <FormGroup 
            name='countDate'
            label={t('FT/CountDate')}
            help={t('FT/CountDatePrompt')}
            accepter={DatePicker}   
            cleanable={false}   
            oneTap={true}
            isoWeek
            showWeekNumbers
            ranges={[]}
            format="yyyy.MM.dd"
            value={countDate}
            onChange={setCountDate}
        />

        <FormGroup
            name="interval"
            label={ t('FT/Interval') }
            help={t('FT/IntervalPrompt')}
        >
            <Row>
                <Col xs={12}>
                    <Form.Control 
                        name="intervalStart"
                        placeholder={t('Start')}
                        accepter={DateInput}
                        format="HH:mm"
                        value={startTimeDate}
                        onChange={setStartTimeDate} />

                    <Checkbox 
                        defaultChecked={nextDayStart}
                        value={nextDayStart}
                        onChange={() => setNextDayStart(!nextDayStart)} 
                    >
                        { t('FT/NextDay') }
                    </Checkbox>
                </Col>
                <Col xs={12}>
                    <Form.Control 
                        name="intervalEnd"
                        placeholder={t('End')}
                        accepter={DateInput}
                        format="HH:mm"
                        value={endTimeDate}
                        onChange={setEndTimeDate} />

                    <Checkbox 
                        defaultChecked={nextDayEnd}
                        value={nextDayEnd}
                        onChange={() => setNextDayEnd(!nextDayEnd)} 
                    >
                        { t('FT/NextDay') }
                    </Checkbox>
                </Col>
            </Row>
        </FormGroup>
    </>
}

export default OnboardCountDateSelectorFormFields