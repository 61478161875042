import React from "react";
import { WebmapContext } from "features/webmap";
import { useOnboardCount } from "../hooks";


const OnboardCountContext = React.createContext();


export const OnboardCountContextProvider = ({
    children
}) => {
    const {webmap, arcGISMap, setJoins} = React.useContext(WebmapContext);
    const count = useOnboardCount({
        webmap,
        arcGISMap,
        setJoins
    })

    return (
        <OnboardCountContext.Provider value={count}>
            { children }
        </OnboardCountContext.Provider>
    );
};


export default OnboardCountContext;