import 'assets/css/mapViewer.css';

import React from 'react';


const MapSidebar = ({
    children
}) => {
    return (
        <div className="mapSidebar">
            {children}
        </div>
    );
};


export default MapSidebar;
