export function getElementURL (element) {
    if (!element?.url) return;

    return element.url
        .replace('?f=json', '') + 
        'query?outFields=*&f=json';
}

export function getElementCacheID (element) {
    return `Element-${element.id}-${getElementURL(element)}`
}