export function parseIntSafe(s) {
    return parseInt(s.replace(/\D/g,''));
}

export const compareFirstNumeric = (a, b) => (/^\d+$/.test(a[0]) ? 0 : 1) - (/^\d+$/.test(b[0]) ? 0 : 1);
export const compareFullNumeric = (a, b) => (/^\d+$/.test(a) ? 0 : 1) - (/^\d+$/.test(b) ? 0 : 1);
export const compareStrings = (a, b) => a.localeCompare(b, 'hu')
export const compareStringsAsInt = (a, b) => parseIntSafe(a) - parseIntSafe(b);

export function compareNumStrings(a, b) {
    return compareFirstNumeric(a, b) ||
        compareStringsAsInt(a, b) ||
        compareFullNumeric(a, b) ||
        compareStrings(a, b)
};

export function checkObjectValues (a, b) {
    switch (typeof a) {
        case 'function':
            return (typeof b === 'function');
        case 'object':
            if (a === null) {
                return a === b;
            }

            if (a instanceof Date) {
                if (b instanceof Date) {
                    return a.getTime() === b.getTime();
                } else {
                    return false
                }
            }


            if (Array.isArray(a)) {
                return a?.length === b?.length && a.every( (value, i) => checkObjectValues(value, b?.[i]) )
            }

            return Object.entries(a).every(([name, value]) => checkObjectValues(value, b?.[name]));
        default:
            return a === b;
    }
};