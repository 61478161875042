import React from "react";
import { useParams } from "react-router-dom";
import { Col, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";
import { CollectionTilesPanel } from "features/collection";
import SimplePage from "layouts/SimplePage";

import useOrganization from "../hooks/useOrganization";

function OrganizationPage () {

    const { organizationId } = useParams();
    const organization = useOrganization(organizationId);

    return (
        <SimplePage>
            
            <Jumbotron backgroundUrl={organization?.coverUrl} >
                <PageTitle item={organization} />
            </Jumbotron>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        { organization?.id && <CollectionTilesPanel organizationId={organization?.id} /> }
                        
                    </Col>
                </Row>
            </Grid>  

        </SimplePage>
    )
};


export default OrganizationPage;
