import React from 'react';
import { useNavigate } from "react-router-dom";

import { AvatarCard } from "components/cards";
import { noop } from "utils/constants";
import { DeleteIcon, EditIcon } from 'components/icons';
import { t } from 'i18next';


const PageListItem = ({
    endpoint,
    item,
    getTitle = noop,
    getSubtitle = noop,
    getImageURL = noop,
    getChildren = noop,
    editModalClass,
    deleteModalClass,
    onModalClose = noop,
    ...props
}) => {
    const navigate = useNavigate();
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

    const EditModal = editModalClass;
    const DeleteModal = deleteModalClass;

    const actions = [...(props?.actions || [])];
    if (editModalClass) {
        actions.push({
            appearance: "primary",
            color: "yellow",
            icon: <EditIcon />,
            title: t('Edit'),
            onClick: () => setEditModalVisible(true),
        })
    }
    if (deleteModalClass) {
        actions.push({
            appearance: "primary",
            color: "red",
            icon: <DeleteIcon />,
            title: t('Delete'),
            onClick: () => setDeleteModalVisible(true),
        })
    }

    return <>
        <div className="pageListItem">
            <AvatarCard 
                title={getTitle(item) || item.name}
                subtitle={getSubtitle(item)}
                imageURL={getImageURL(item) || item?.thumbnailUrl} 
                usePlaceholder
                maintenance={item?.maintenance}
                onClick={endpoint ? () => navigate(`/${endpoint}/${item.id}`) : undefined}
                style={{marginBottom: 10}}
                { ...props }
                actions={actions}
            >
                { getChildren(item) }
            </AvatarCard>
        </div>
        { editModalClass && <EditModal open={editModalVisible} setOpen={setEditModalVisible} item={item} onClose={onModalClose} /> }
        { deleteModalClass && <DeleteModal open={deleteModalVisible} setOpen={setDeleteModalVisible} item={item} onClose={onModalClose} /> }
    </>;
};


export default PageListItem;
