import { formatDate, formatString, zfill } from "utils/format";
import { COUNT_INTERVAL_TYPE_LABELS, COUNT_INTERVAL_TYPE_ORDER, DATE_INTERVAL, MONTH_INTERVAL, TIMETABLE_INTERVAL, WEEK_INTERVAL } from "../utils/constants";
import { t } from "i18next";
import { asRoman } from "utils/math";
import { noop } from "utils/constants";
import DayType from "./DayType";
import { dateToStr } from "utils/date";


function getISOWeek (d) {
    const date = new Date( d.getTime()) ;
    date.setHours(0, 0, 0, 0);

    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    
    // January 4th is always in week 1.
    const jan4 = new Date(date.getFullYear(), 0, 4);

    // Get time elapsed since January 4th
    let difference = date.getTime() - jan4.getTime();

    // Convert to days
    difference = difference / 86400 / 1000;

    // Adjust for first week
    difference += 4 + (jan4.getDay() + 6) % 7;

    // Convert to weeks
    return Math.round(difference / 7);
};


function weekOfYearRange (year, week) {
    var date = new Date(year, 0, 4);
    date.setDate(date.getDate() + 7 * ( week - 1 ));

    var dayOfWeek = date.getDay() || 7;

    date.setDate(date.getDate() - dayOfWeek + 1);

    var endDate = new Date(date.getTime());
    endDate.setDate(date.getDate() + 6);

    return [date, endDate];
};


function monthOfYearRange (year, month) {
    var date = new Date(year, month - 1, 1);
    var endDate = new Date(year, month, 1);
    endDate.setDate(endDate.getDate() - 1);

    return [date, endDate];
};


const CountInterval = (interval) => {
    if (!interval) return;

    if (interval instanceof Date) {
        interval = {date: interval};
    }
    
    const {
        type,
        date,
        year,
        month,
        week,
        startDate,
        endDate,
        dayTypeId,
    } = interval;

    const countInterval = {
        elementType: 'countInterval',
        type,
        typeLabel: undefined,
        date,
        year,
        month,
        week,
        startDate,
        endDate,
        dayTypeId,
        str: undefined,
        label: undefined,
        intervalStr: undefined,
        compare: noop,
    };
    
    if (['number', 'string'].includes(typeof interval)) {

        const [intervalStr, dayTypeIdStr] = `${interval}`.split('-');
        countInterval.dayTypeId = dayTypeIdStr ? Number(dayTypeIdStr) : undefined;

        if (intervalStr.length === 8) {
            countInterval.year = Number(intervalStr.slice(0, 4));
            countInterval.month = Number(intervalStr.slice(4, 6));
            countInterval.week = Number(intervalStr.slice(6, 8));
            countInterval.type = countInterval?.week ? WEEK_INTERVAL : MONTH_INTERVAL;
        } else if (intervalStr.length === 10) {
            countInterval.date = new Date(intervalStr);
            countInterval.type = DATE_INTERVAL;  
        } else if (intervalStr.length === 16) {
            countInterval.startDate = new Date(`${intervalStr.slice(0,4)}.${intervalStr.slice(4,6)}.${intervalStr.slice(6,8)}`);
            countInterval.endDate = new Date(`${intervalStr.slice(8,12)}.${intervalStr.slice(12,14)}.${intervalStr.slice(14,16)}`);
            countInterval.type = TIMETABLE_INTERVAL;
        }
    }


    if (countInterval.year && countInterval.week) {

        [countInterval.startDate, countInterval.endDate] = weekOfYearRange(countInterval.year, countInterval.week);
        countInterval.type = WEEK_INTERVAL;

    } else if (countInterval.year && countInterval.month) {

        [countInterval.startDate, countInterval.endDate] = monthOfYearRange(countInterval.year, countInterval.month);
        countInterval.type = MONTH_INTERVAL;

    } else if (countInterval.startDate && countInterval.endDate) {
        switch (type) {
            case WEEK_INTERVAL:
                countInterval.year = countInterval.startDate.getFullYear();
                countInterval.week = getISOWeek(countInterval.startDate);
                break;
            case MONTH_INTERVAL:
                countInterval.year = countInterval.startDate.getFullYear();
                countInterval.month = countInterval.startDate.getMonth() + 1;
                break;
            default:
                countInterval.type = TIMETABLE_INTERVAL;
                countInterval.str = '' + 
                    formatDate(countInterval.startDate, {dateSeparator: ''}) + 
                    formatDate(countInterval.endDate, {dateSeparator: ''});
        }

    } else if (countInterval.date) {
        countInterval.type = DATE_INTERVAL
        countInterval.str = formatDate(countInterval.date, {dateSeparator: '.'});
    } else {
        throw Error('Invalid interval parameters!')
    }

    switch (countInterval.type) {
        case WEEK_INTERVAL:
            countInterval.typeLabel = t('FT/WeeklyAverage');
            countInterval.str = `${countInterval.year}00${zfill(countInterval.week, 2)}`;
            countInterval.label = formatString(t('FT/WeekIntervalLabel'), {
                year: countInterval.year,
                week: countInterval.week,
            });
            break;
        case MONTH_INTERVAL:
            countInterval.typeLabel = t('FT/MonthlyAverage');
            countInterval.str = `${countInterval.year}${zfill(countInterval.month, 2)}00`;
            countInterval.label = formatString(t('FT/MonthIntervalLabel'), {
                year: countInterval.year,
                month: countInterval.month,
                romanMonth: asRoman(countInterval.month)
            });
            break;
        case TIMETABLE_INTERVAL:
            countInterval.typeLabel = t('FT/IntervalAverage');
            countInterval.str = '' + 
                formatDate(countInterval.startDate, {dateSeparator: ''}) + 
                formatDate(countInterval.endDate, {dateSeparator: ''});

            countInterval.label = '' + 
                formatDate(countInterval.startDate, {dateSeparator: '.'}) +
                ' - ' + 
                formatDate(countInterval.endDate, {dateSeparator: '.'});
            break;
        case DATE_INTERVAL:
            countInterval.typeLabel = t('FT/CountDate');
            countInterval.str = formatDate(countInterval.date, {dateSeparator: '.'});
            countInterval.label = countInterval.str;
            break;
    }

    if (countInterval.dayTypeId) {
        countInterval.str += `-${countInterval.dayTypeId}`;
    }

    countInterval.intervalStr = countInterval.date 
        ? '' 
        : '' + 
            formatDate(countInterval.startDate, {dateSeparator: '.'}) +
            ' - ' + 
            formatDate(countInterval.endDate, {dateSeparator: '.'});
    countInterval.id = countInterval.str;

    countInterval.typeLabel = COUNT_INTERVAL_TYPE_LABELS[countInterval.type];
    countInterval.compare = other => compareCountIntervals(countInterval, other);

    return countInterval;
}


export function compareCountIntervals (a, b) {
    if (!a) {
        return 1
    }
    if (!b) {
        return -1
    }
    return COUNT_INTERVAL_TYPE_ORDER.indexOf(a.type) - COUNT_INTERVAL_TYPE_ORDER.indexOf(b.type) ||
        a.startDate - b.startDate ||
        a.endDate - b.endDate ||
        a.date - b.date ||
        DayType({id: a.dayTypeId}).compare(DayType({id: b.dayTypeId}))
}


export function getAvailableCountIntervalIds (countIntervals = {}, filters = {}) {
    return Object.values(countIntervals)
        .filter( countInterval => (
            countInterval.type === filters?.countIntervalType &&
            countInterval.startDate >= filters?.startDate &&
            countInterval.endDate <= filters?.endDate &&
            filters?.dayTypeIds.includes(countInterval.dayTypeId)
        ))
        .map(countInterval => countInterval.str);
}


function getRowIntervalIds (row) {
    return Object.keys(row?.traffic || {})
}

export function getMeasuredCountIntervalIds (elements = {}, firstRowOnly = false) {
    if (firstRowOnly) {
        return getRowIntervalIds(Object.values(elements)[0])
    }
    
    const measuredCountIntervalIds = []
    Object.values(elements).forEach(row => {
        getRowIntervalIds(row).forEach(id => {
            if (!measuredCountIntervalIds.includes(id)) {
                measuredCountIntervalIds.push(id)
            }
        })
    })

    return measuredCountIntervalIds
}

export default CountInterval;
