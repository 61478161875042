const { numberToHex } = require("./math");


const Color = (color) => {
    let r = undefined;
    let g = undefined;
    let b = undefined;
    let a = undefined;

    if (typeof color === 'string') {
        if (color[0] === '#') {
            r = Number(`0x${color.slice(1, 3)}`);
            g = Number(`0x${color.slice(3, 5)}`);
            b = Number(`0x${color.slice(5, 7)}`);
            a = (color.slice(7, 9) ? Number(`0x${color.slice(7, 9)}`) : 255) / 255;
        } else if (color.slice(0, 4) === 'rgb(') {
           [ r, g, b] = color.slice(4, color.length - 1).split(',').map(s => Number(s));
            a = 1;
        } else if (color.slice(0, 5) === 'rgba(') {
            [r, g, b, a] = color.slice(5, color.length - 1).split(',').map(s => Number(s));
        }
    } else if (color?.length === 3)  {
        [r, g, b] = color;
        a = 1;
    } else if (color?.length === 4) {
        [r, g, b, a] = color;
    }

    if ( [r, g, b, a].includes(undefined) ) return;

    return {
        hex: '#' +
            numberToHex(r) +
            numberToHex(g) +
            numberToHex(b) +
            (a === 1 ? '' : numberToHex(Math.round(255*a))),
        rgb: (a === 1 ? 'rgb' : 'rgba') +
            `(${[r, g, b].join(',')}` +
            (a === 1 ? '' : `,${a}`) +
            ')',
        array: [r, g, b, a],
        setAlpha: a => Color([r, g, b, a]), 
        r,
        g,
        b,
        a
    };
};


export default Color;
