export function getTraffic(element, countInterval) {
    if (countInterval) {
        return element?.traffic?.[countInterval];
    } else {
        return Object.values(element?.traffic || {})[0];
    }
};


export function getEmptyTraffic() {
    const baseStats = {
        avg: 0,
        min: 0,
        max: 0,
    };

    return {
        alight: {...baseStats},
        board: {...baseStats},
        onward: {...baseStats},
    }
}

export function getInvalidTraffic() {
    const baseStats = {
        avg: '',
        min: '',
        max: '',
    };

    return {
        alight: {...baseStats},
        board: {...baseStats},
        onward: {...baseStats},
    }
}
