import { AvatarCard } from 'components/cards';
import React from 'react'
import { BackwardIcon, HighwayIcon, MotorwayIcon, OnwardIcon } from '../icons';
import RoadBadge from '../badges/RoadBadge';
import { t } from 'i18next';

const SegmentPartCard = ({
    size="md",
    roadName,
    motorway,
    highway,
    mainRoad,
    startSection,
    endSection,
    onward,
    backward
}) => {
    return (
        <AvatarCard size={size}
            icon={<>
                <RoadBadge size={size} name={roadName} highway={highway} motorway={motorway} mainRoad={mainRoad} />
            </>}
            title={<div title={t('OKKF/ValiditySegment')}>
                {startSection}
                {startSection && endSection && ' - '}
                {endSection}
                <span style={{marginLeft: 4}}>
                    { onward && <OnwardIcon /> }
                    { backward && <BackwardIcon /> }
                </span>
            </div>}
        />
    )
};

export default SegmentPartCard