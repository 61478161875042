import React from "react";
import { useParams } from "react-router-dom";

import { CrossSectionRoadCountContextProvider } from "features/crossSectionRoadCount";
import { OnboardCountContextProvider } from "features/onboardCount";
import { ModuleContent, ModuleHeader } from "features/webmapModule";
import SimplePage from "layouts/SimplePage";

import MapViewer from "../components/MapViewer";
import WebmapContext, { WebmapContextProvider } from "../context/WebmapContext";
import { t } from "i18next";
import CenteredPanelPage from "layouts/CenteredPanelPage";
import CenteredPage from "layouts/CenteredPage";
import { Message } from "rsuite";

function WebMapPageContent () {
    const { webmap } = React.useContext(WebmapContext); 

    const maintenance = webmap?.maintenance;

    return (
        <SimplePage
            style={{padding: '0 20px'}}
            extraHeader={ !maintenance && <ModuleHeader />}
            breadcrumbData={[{
                label: webmap?.name && (webmap.name + (webmap?.isDemo ? ` (${t('Map/Demo')})` : '')),
                active: true
                
            }]}
        >
            { webmap?.maintenance ? <>
                <div style={{margin: 60}}>
                    <Message showIcon type="warning" header={ t('Map/UnderMaintenance') }>
                        { t('Map/UnderMaintenanceDescription') }
                    </Message>
                </div>
            </> : <>
                <MapViewer />
                <ModuleContent />
            </> }
        </SimplePage>  
    )
}

function WebMapPage () {
    
    const { webmapId } = useParams();

    return (
        <WebmapContextProvider id={webmapId}>
            <OnboardCountContextProvider>
                <CrossSectionRoadCountContextProvider>
                    <WebMapPageContent />
                </CrossSectionRoadCountContextProvider>
            </OnboardCountContextProvider>
        </WebmapContextProvider>
    );
};

export default WebMapPage;