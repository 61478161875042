import React from 'react'
import { t } from 'i18next';

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { BOARD_COUNT, DAILY_TRAFFIC_DISTRIBUTION, LINE_TRAFFIC_DISTRIBUTION } from 'features/onboardCount/utils/constants';
import ModuleChartPanel from 'features/webmapModule/components/chart/ModuleChartPanel';
import TransportLineBarChart from './TransportLineBarChart';
import DailyTrafficDistributionChart from './DailyTrafficDistributionChart';
import LineTrafficDistributionChart from './LineTrafficDistributionChart';
import TimeSeriesBarChart from './TimeSeriesBarChart';


const OnboardCountChartPanel = () => {

    const count = React.useContext(OnboardCountContext);

    if (!count?.elements?.transportLines) return;

    const menu = count?.chartMenu;
    const {
        section,
        area,
        stopPlace,
        timeSeriesIntervalIds,
    } = count?.selectedElements;

    const toolbarHeight = count?.toolbarRef?.current?.offsetHeight || 0;
    const chartHeight = `calc(100vh - ${toolbarHeight + 40}px)`
    const chartSize = {
        height: chartHeight,
    };
    
    return (
        <ModuleChartPanel
            module={count}
            title={t('FT/TrafficCharts')}
        >
            { menu?.values[1] === BOARD_COUNT && <>
                { timeSeriesIntervalIds.length > 1 ? <>
                    <TimeSeriesBarChart 
                        ref={count?.chart}
                        boardOnly={!(section || area || stopPlace)}
                        size={chartSize} />
                </> : <>    
                    <TransportLineBarChart 
                        ref={count?.chart} 
                        boardOnly={!(section || area || stopPlace)}
                        size={chartSize} />
                </> }
            </> }

            { menu?.values[1] === DAILY_TRAFFIC_DISTRIBUTION && <>
                <DailyTrafficDistributionChart 
                    ref={count?.chart} 
                    rollingHourCount 
                    ticksPerHour={4}
                    size={chartSize} />
            </> }

            { menu?.values[1] === LINE_TRAFFIC_DISTRIBUTION && <>
                <LineTrafficDistributionChart 
                    ref={count?.chart}
                    size={chartSize} />
                
            </> }

        </ModuleChartPanel>
    )
}

export default OnboardCountChartPanel