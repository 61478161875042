import React from 'react'

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import { Slider, Toggle } from 'rsuite';
import { FormGroup } from 'components/forms';
import { t } from 'i18next';
import useDelayedState from 'hooks/useDelayedState';
import { getTraffic } from 'features/onboardCount/utils/traffic';
import { arrayStats } from 'utils/math';

const OnboardCountViewPreferencesFormFields = ({
    labelWidth = 300
}) => {

    const onboardCount = React.useContext(OnboardCountContext);

    const [ minTrafficSize, setMinTrafficSize ] = useDelayedState(
        onboardCount?.viewPreferences?.minTrafficSize || 1, 
        value => onboardCount?.updateViewPreferences({minTrafficSize: value})
    );
    const [ maxTrafficSize, setMaxTrafficSize ] = useDelayedState(
        onboardCount?.viewPreferences?.maxTrafficSize || 50, 
        value => onboardCount?.updateViewPreferences({maxTrafficSize: value})
    );

    const {
        areas = {}
    } = onboardCount?.elements || {};

    const {
        countInterval
    } = onboardCount?.filters || {}

    const trafficValues = [];
    Object.values(areas).forEach(area => {
        trafficValues.push(
            getTraffic(area, countInterval)?.alight?.avg, 
            getTraffic(area, countInterval)?.board?.avg
        )
    })

    const trafficStats = arrayStats(trafficValues.filter(val => val > 0));
    
    return <>
            <FormGroup 
                name="labelStopPlaces"
                label={t('FT/LabelStopPlaces')}
                labelWidth={labelWidth}
                accepter={Toggle}
                value={onboardCount?.viewPreferences?.labelStopPlaces}
                onChange={value => onboardCount?.updateViewPreferences({labelStopPlaces: value})}
            />
            {/* <FormGroup 
                name="labelSections"
                label={t('FT/LabelSections')}
                labelWidth={labelWidth}
                accepter={Toggle}
                value={onboardCount?.moduleParameters?.labelSections}
                onChange={onboardCount?.setLabelSections}
            /> */}
            <FormGroup 
                name="minTrafficSize"
                label={<>{ t('FT/MinStopPlaceTrafficSize') } <br/>{ t('FT/MinTrafficValue') }: { trafficStats.min }</>}
                labelWidth={labelWidth}
                accepter={Slider}
                min={1}
                max={20}
                style={{width: 180, maxWidth: `calc(100vw - ${labelWidth+100}px)`, marginTop: 20, }}
                value={minTrafficSize}
                onChange={setMinTrafficSize}
            />
            <FormGroup 
                name="maxTrafficSize"
                label={<>{ t('FT/MaxStopPlaceTrafficSize') } <br/>{ t('FT/MaxTrafficValue') }: { trafficStats.max }</>}
                labelWidth={labelWidth}
                accepter={Slider}
                min={1}
                max={100}
                style={{width: 180, maxWidth: `calc(100vw - ${labelWidth+100}px)`, marginTop: 20}}
                value={maxTrafficSize}
                onChange={setMaxTrafficSize}
            />
    </>
}

export default OnboardCountViewPreferencesFormFields