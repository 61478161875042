import { t } from 'i18next'
import React from 'react'
import { ButtonGroup, ButtonToolbar, IconButton } from 'rsuite'

import { ESRIIcon } from 'components/icons'

const ModuleChartToolbar = ({module}) => {

    
    function exportChart() {
        module?.chart?.current.saveAsImage(module?.getExportName());
    }

    return (
        <ButtonToolbar>
            <ButtonGroup size='xs'> 
                <IconButton
                    title={t('SaveAsImage') + (module?.isDemo ? `\n${t('Map/UnavailableInDemo')}` : '')}
                    icon={<ESRIIcon name="save" />}
                    appearance='default'
                    disabled={module?.isDemo}
                    onClick={() => exportChart()} />
            </ButtonGroup>
        </ButtonToolbar>
    )
}

export default ModuleChartToolbar