import React from 'react'
import { Affix } from 'rsuite';

import { CrossSectionRoadCountToolbar } from 'features/crossSectionRoadCount';
import { OnboardCountToolbar } from 'features/onboardCount';

const ModuleHeader = () => {
    return <Affix>
        <OnboardCountToolbar />
        <CrossSectionRoadCountToolbar />
    </Affix>
}

export default ModuleHeader;