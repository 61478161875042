import { t } from 'i18next';


export const ROADS_VIEW = 'roads';
export const AADT_VIEW = 'aadt';
export const MADT_VIEW = 'madt';


export const VIEW_TYPES = {
    [ROADS_VIEW]: t('OKKF/RoadNetwork'),
    [AADT_VIEW]: t('OKKF/AnnualAverageDailyTraffic'),
    [MADT_VIEW]: t('OKKF/MonthlyAverageDailyTraffic')
};


export const VIEW_TYPE_ABBREVIATIONS = {
    [ROADS_VIEW]: t('OKKF/RoadNetwork'),
    [AADT_VIEW]: t('OKKF/AADT'),
    [MADT_VIEW]: t('OKKF/MADT'),
};

export const VIEW_TYPE_OPTIONS = [ROADS_VIEW, AADT_VIEW, MADT_VIEW].map(k => ({
    label: VIEW_TYPES[k],
    value: k,
}));

export const STATION_SEGMENT_LAYER = 'segment';
export const STATION_TRAFFIC_LAYER = 'segmentTraffic';
export const ROAD_SEGMENT_LAYER = 'roadSegment';
export const BORDER_CROSSING_LAYER = 'borderCrossing';
export const ROAD_SECTION_LAYER = 'roadSection';
export const ROAD_NETWORK_LAYER = 'roadNetwork';
export const SETTLEMENT_LAYER = 'settlement';

export const LAYER_ORDER = [
    SETTLEMENT_LAYER,
    STATION_TRAFFIC_LAYER,
    STATION_SEGMENT_LAYER,
    ROAD_SEGMENT_LAYER,
    BORDER_CROSSING_LAYER,
    ROAD_SECTION_LAYER,
    ROAD_NETWORK_LAYER,
]

export const LAYERS = {
    [BORDER_CROSSING_LAYER]: t('OKKF/BorderCrossings'),
    [ROAD_SECTION_LAYER]: t('OKKF/RoadSections')
};

export const LAYER_OPTIONS = [BORDER_CROSSING_LAYER, ROAD_SECTION_LAYER].map(k => ({
    label: LAYERS[k],
    value: k
}));

export const STATION_CODE_SEARCH_TYPE = 'stationCode';
export const ROAD_SECTION_SEARCH_TYPE = 'roadSection';
export const SETTLEMENT_SEARCH_TYPE = 'settlement';

export const SEARCH_TYPES = {
    [STATION_CODE_SEARCH_TYPE]: t('OKKF/StationCode'),
    [ROAD_SECTION_SEARCH_TYPE]: t('OKKF/RoadSection'),
};

export const MAP_SEARCH_TYPES = {
    [SETTLEMENT_SEARCH_TYPE]: t('OKKF/Settlement'),
}

export const SEARCH_TYPE_OPTIONS = [
    STATION_CODE_SEARCH_TYPE,
    ROAD_SECTION_SEARCH_TYPE,
].map(k => ({label: SEARCH_TYPES[k], value: k}));


export const MAP_SEARCH_TYPE_OPTIONS = [
    SETTLEMENT_SEARCH_TYPE,
].map(k => ({label: MAP_SEARCH_TYPES[k], value: k}));


export const ROAD_CATEGORIES = {
    1: t("OKKF/Highway"),
    2: t("OKKF/Speedway"),
    3: t("OKKF/PrimaryRoad"),
    4: t("OKKF/SecondaryRoad"),
    5: t("OKKF/ArterialRoad"),
    6: t("OKKF/LocalRoad"),
    7: t("OKKF/StationRoad"),
    8: t("OKKF/SpeedwayLink"),
    9: t("OKKF/OtherLink"),
};

export const MAJOR_ROAD_CATEGORIES = [1, 2, 3, 4, 8];
export const MINOR_ROAD_CATEGORIES = [5, 6, 7, 9];

export const VEHICLE_CLASS_CONVERSIONS = {
    A: ['A1', 'A2'],
    C: ['C1k', 'C1n+C2', 'I'],
    E: ['E', 'F'],
}


export const YEARLY_COUNT_METHODS = {
    [-1]: t('OKKF/Unknown'),
    0: t('OKKF/Imported'),
    1: t('OKKF/Estimated'),
    2: t('OKKF/Composed'),
    10: t('OKKF/MeasuredC'),
    20: t('OKKF/MeasuredABC'),
    30: t('OKKF/MeasuredBC'),
    40: t('OKKF/Measured365'),
    100: t('OKKF/GrowthRate'),
    101: t('OKKF/GrowthRate'),
    102: t('OKKF/GrowthRate'),
    103: t('OKKF/GrowthRate'),
    104: t('OKKF/GrowthRate')
};


export const MONTHLY_COUNT_METHODS = {
    10: t('OKKF/Measured'),
    20: t('OKKF/Estimated')
};


export const MONTH_LABELS = {
    1: t("January"),
    2: t("February"),
    3: t("March"),
    4: t("April"),
    5: t("May"),
    6: t("June"),
    7: t("July"),
    8: t("August"),
    9: t("September"),
    10: t("October"),
    11: t("November"),
    12: t("December"),
}


export const STATION_TRAFFIC = 'stationTraffic';
export const TRAFFIC_BY_MONTH = 'trafficByMonth';
export const TRAFFIC_BY_DAY_TYPE = 'trafficByDayType';
export const YEARLY_TRAFFIC_BY_YEAR = 'yearlyTrafficByYear'
export const MONTHLY_TRAFFIC_BY_YEAR = 'monthlyTrafficByYear'
export const MONTHLY_TRAFFIC = 'monthlyTraffic'

export const COUNTY_NAMES = {
    1: 'Budapest',
    2: 'Baranya vármegye',
    3: 'Bács-Kiskun vármegye',
    4: 'Békés vármegye',
    5: 'Borsod-Abaúj-Zemplén vármegye',
    6: 'Csongrád-Csanád vármegye',
    7: 'Fejér vármegye',
    8: 'Győr-Moson-Sopron vármegye',
    9: 'Hajdú-Bihar vármegye',
    10: 'Heves vármegye',
    11: 'Komárom-Esztergom vármegye',
    12: 'Nógrád vármegye',
    13: 'Pest vármegye',
    14: 'Somogy vármegye',
    15: 'Szabolcs-Szatmár-Bereg vármegye',
    16: 'Jász-Nagykun-Szolnok vármegye',
    17: 'Tolna vármegye',
    18: 'Vas vármegye',
    19: 'Veszprém vármegye',
    20: 'Zala vármegye'
}