import React from "react";
import { Button, List, Modal } from "rsuite";
import { t } from "i18next";

import { noop } from "utils/constants";

import ConfigurationSettings from "./ConfigurationSettings";
import ConfigurationForm from "./forms/ConfigurationForm";


const ConfigurationList = ({
    webMap,
    onChange = noop,
    onItemChange = noop,
    onSort = noop,
    ...props
}) => {
    const [editorVisible, setEditorVisible] = React.useState(false);
    const [sortable, setSortable] = React.useState(true)

    return <>
        <List 
            sortable={sortable}
            onSort={({oldIndex, newIndex}) => {
                const itemIds = webMap?.configurations.map( item => item.id );
                const moveId = itemIds.splice(oldIndex, 1)[0];
                itemIds.splice(newIndex, 0, moveId);
                onSort(itemIds);
            }} 
            {...props} 
        >
            { webMap?.configurations.map( (configuration, i) => <ConfigurationSettings 
                key={i}
                setSortable={setSortable}
                configuration={configuration}
                webMap={webMap}
                onChange={onChange}
                onItemChange={onItemChange} />) }
        </List>
        <Button 
            appearance="primary" 
            className="addMapElementButton"
            onClick={() => setEditorVisible(true)}
        >
            {t('Map/AddConfiguration')}
        </Button>

        <Modal 
            size="xs"
            open={editorVisible} 
            onClose={() => setEditorVisible(false)}
        >
            <Modal.Header>
                <Modal.Title>{t('Map/AddConfiguration')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ConfigurationForm
                    fluid
                    onSubmit={value => {
                        setEditorVisible(false);
                        onChange({params: value});
                    }}
                    onCancel={() => setEditorVisible(false)} />
            </Modal.Body>
        </Modal>
    </>
};


export default ConfigurationList;
