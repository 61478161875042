import React from "react";
import { Loader } from 'rsuite';
import Layover from "./Layover";


const LoadingLayover = ({loading, children, ...props}) => {
    return loading ? (
        <Layover {...props}>
            <Loader style={{marginRight: 8}}/> { children }
        </Layover>
    ) : <></>;
};


export default LoadingLayover;
