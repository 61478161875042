import FormGroup from 'components/forms/FormGroup';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import { AADT_VIEW, MADT_VIEW } from 'features/crossSectionRoadCount/utils/constants';
import { t } from 'i18next';
import React from 'react'
import { Button, Col, Form, Row, SelectPicker } from 'rsuite'
import { asRoman } from 'utils/math';

const MonthlyIntervalForm = ({
    layout
}) => {

    const module = React.useContext(CrossSectionRoadCountContext);


    const {
        dayTypes = {},
    } = module?.elements;

    const {
        stationIntervalType,
        stationMonth,
        stationDayTypeId,
    } = module?.filters

    const [month, setMonth] = React.useState(stationMonth);
    const [dayTypeId, setDayTypeID] = React.useState(stationDayTypeId);

    React.useEffect(() => {
        setMonth(stationIntervalType === MADT_VIEW ? stationMonth : undefined);
        setDayTypeID(stationIntervalType === MADT_VIEW ? stationDayTypeId : undefined);
    }, [stationIntervalType, stationMonth, stationDayTypeId])

    function submit() {
        module.applyFilters({
            stationIntervalType: MADT_VIEW,
            stationMonth: month,
            stationDayTypeId: dayTypeId,
        })
    }

    const monthOptions = [];
    for (let i = 1; i <= 12; i++) {
        monthOptions.push({label: `${asRoman(i)}.`, value: i});
    };

    const dayTypeOptions = Object.values(dayTypes).map(dayType => ({
        label: `${dayType.id} - ${dayType.name}`,
        value: dayType.id
    }))

    return <Form layout={layout}>
        <FormGroup
            name="month"
            label={t('OKKF/MonthAbbrev')}
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={monthOptions}
            value={month}
            onChange={setMonth}
        />
        <FormGroup
            name="dayType"
            label={t('OKKF/DayType')}
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={dayTypeOptions}
            value={dayTypeId}
            onChange={setDayTypeID}
        />
        <FormGroup>
        <Button
            appearance="primary"
            disabled={!month || !dayTypeId}
            onClick={submit}
        >
            { t('OKKF/SetInterval') }
        </Button>
        </FormGroup>
    </Form>
}

export default MonthlyIntervalForm