import { crossSectionRoadCountColors } from "features/crossSectionRoadCount/utils/colors";


function getLabelClass ({
    size,
    ...props
}) {
    return {
        deconflictionStrategy: 'static', // 'static' or 'none'
        labelExpressionInfo: {
            expression: `$feature.KMJEL`
        },
        labelPlacement: 'center-center',
        symbol: {
            type: 'text',
            color: crossSectionRoadCountColors.white.array,
            haloColor: crossSectionRoadCountColors.black.array,
            haloSize: 1,
            font: {
                family: "Arial",
                style: "normal",
                weight: "bold",
                size
            }
        },
        ...props
    };
}

function roadSectionLabeling () {
    return [
        getLabelClass({
            minScale: 100000,
            size: 9
        }),
        
        getLabelClass({
            minScale: 500000,
            maxScale: 100000,
            size: 9,
            where: `MOD( KMJEL, 10 ) = 0`,
        }),
        
        getLabelClass({
            minScale: 1500000,
            maxScale: 500000,
            size: 8,
            where: `MOD( KMJEL, 20 ) = 0`,
        })
    ];
};


export default roadSectionLabeling;

