import { crossSectionRoadCountColors } from "features/crossSectionRoadCount/utils/colors";


const font = {
    family: "Arial",
    style: "normal",
    size: 9
};

function getSymbol (size) {
    return {
        type: 'text',
        color: crossSectionRoadCountColors.white.array,
        haloColor: crossSectionRoadCountColors.settlement.array,
        haloSize: 1.5,
        font: {
            family: "Arial",
            style: "normal",
            size
        }
    }
}

function settlementLabeling () {

    const baseLabeling = {
        deconflictionStrategy: 'none', // 'static' or 'none'
        labelPlacement: "always-horizontal",
        labelExpressionInfo: {
            expression: `$feature.NAME`
        }
    }

    const countyAuthorityLabeling = {
        symbol: getSymbol(10),
        ...baseLabeling,
        where: "Level IN (3, 4)",
        minScale: 1500000,
        labelExpressionInfo: {
            expression: `Upper($feature.NAME)`
        }
    };

    const cityLabeling =  {
        symbol: getSymbol(8),
        ...baseLabeling,
        where: "Level IN (1, 2)",
        minScale: 750000,
    };


    const villageLabeling = {
        symbol: getSymbol(7),
        ...baseLabeling,
        where: "Level = 0",
        minScale: 250000,
    };


    return [countyAuthorityLabeling, cityLabeling, villageLabeling];
};


export default settlementLabeling;

