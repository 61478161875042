import 'assets/css/modal.css'

import React from "react";
import { Modal } from "rsuite";

import { noop } from "utils/constants";


const SimpleModal = ({
    title,
    size="sm",
    open,
    setOpen = noop,
    onClose = noop,
    children,
}) => {

    return (
        <Modal size={size} open={open} onClose={() => {
            setOpen(false);
            onClose();
        }}>
            <Modal.Header>
                <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { children }
            </Modal.Body>
        </Modal>
    );
};


export default SimpleModal;
