import 'assets/css/form.css';

import React from 'react';
import { Button, ButtonToolbar, Form, Input } from 'rsuite';
import { t } from 'i18next';
import { FormGroup } from 'components/forms';
import { noop } from 'utils/constants';


const ChangePasswordForm = ({
    layout,
    loading,
    onSubmit = noop,
}) => {
    const [currentPassword, setCurrentPassword] = React.useState();
    const [password, setPassword] = React.useState();
    const [password2, setPassword2] = React.useState();

    return (
        <Form layout={layout}>
            <FormGroup
                name="current"
                label={t('Security/CurrentPassword')}
                accepter={Input}
                type="password"
                autoComplete="off"
                value={currentPassword}
                onChange={setCurrentPassword}
            />
            <FormGroup
                name="password"
                label={t('Security/NewPassword')}
                accepter={Input}
                type="password"
                autoComplete="off"
                value={password}
                onChange={setPassword}
            />
            <FormGroup
                name="password2"
                label={t('Security/ConfirmPassword')}
                accepter={Input}
                type="password"
                autoComplete="off"
                error={password !== password2 ? t('Security/PasswordsDontMatch') : undefined}
                value={password2}
                onChange={setPassword2}
            />
            <Form.Group>
                <ButtonToolbar>
                    <Button 
                        appearance="primary" 
                        loading={loading}
                        disabled={!password || !currentPassword || (password != password2)} 
                        onClick={() => onSubmit({ password, currentPassword })}
                    >
                        { t('Security/ChangePassword') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );   
};

export default ChangePasswordForm;