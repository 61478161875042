import React from "react";


const PageSection = ({ 
    id, 
    margin=20, 
    offset=40,
    children,
    ...props
}) => {
    return <>
        <div 
            id={id} 
            style={{
                marginTop: -offset, 
                marginBottom: offset + margin
            }}
            {...props}
        />
        { children }
    </>;
};


export default PageSection;
