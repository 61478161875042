import React from 'react'
import { t } from 'i18next';

import ModuleChartPanel from 'features/webmapModule/components/chart/ModuleChartPanel';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import MonthlyStationTrafficChart from './MonthlyStationTrafficChart';
import { TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH } from 'features/crossSectionRoadCount/utils/constants';
import DayTypesStationTrafficChart from './DayTypesStationTrafficChart';


const CrossSectionRoadCountChartPanel = () => {

    const count = React.useContext(CrossSectionRoadCountContext);

    if (!count?.filters?.stationCode || !count?.filters?.stationMonth) return;
    
    const menu = count?.chartMenu;

    const toolbarHeight = count?.toolbarRef?.current?.offsetHeight || 0;
    const chartHeight = `calc(100vh - ${toolbarHeight + 40}px)`
    const chartSize = {
        height: chartHeight,
        marginLeft: 'auto',
        marginRight: 'auto',
    };
    
    return (
        <ModuleChartPanel
            module={count}
            title={t('FT/TrafficCharts')}
        >
            { menu?.values[1] === TRAFFIC_BY_MONTH && <>
                <MonthlyStationTrafficChart 
                    ref={count?.chart}
                    size={chartSize} />
            </> }

            { menu?.values[1] === TRAFFIC_BY_DAY_TYPE && <>
                <DayTypesStationTrafficChart 
                    ref={count?.chart}
                    size={chartSize} />
            </> }

        </ModuleChartPanel>
    )
}

export default CrossSectionRoadCountChartPanel