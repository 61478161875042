import 'assets/css/panel.css'

import React from "react";


const PanelHeader = ({
    icon,
    title,
    toolbar,
}) => {
    return (
        <div className="panelHeader">
            <h5 className="title">
                {icon}
                {title}
            </h5>
            <div className="toolbar">
                {toolbar}
            </div>
        </div>
    )
};


export default PanelHeader;
