import React from 'react'
import { Input, Message } from 'rsuite';


export const ModuleFilter = React.forwardRef(({
    icon,
    name,
    acceptor = Input,
    help,
    instructionText,
    children,
    className,
    ...props
}, ref) => {
    const Control = acceptor;

    return (
        <div className={`moduleFilter ${className}${ instructionText ? ' highlight' : ''}`}>
            <div className="label">

                {icon ? <>
                    <div className="icon">
                        {icon}
                    </div>
                </> : <></>}

                {name ? <>
                    <div className="name" title={help}>
                        {name}
                    </div>
                </> : <></>}

            </div>
            <div className="input">
                <Control 
                    ref={ref}
                    {...props}
                    style={{
                        ...(props?.style || {}),
                        width: '100%'
                    }}
                >
                    {children}
                </Control>
            </div>
            { instructionText && (
                <div className="instructions">
                    <Message showIcon >
                        {instructionText}
                    </Message>

                </div>
            )}
        </div>
    );
});


export default ModuleFilter;
