import DataChart, { Dataset } from 'features/chart/components/DataChart';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import { onboardCountColors } from 'features/onboardCount/utils/colors';
import { getChartLabels } from 'features/onboardCount/utils/labels';
import { t } from 'i18next';
import React from 'react'
import { formatString } from 'utils/format';

const TransportLineBarChart = React.forwardRef(({
    boardOnly,
    ...props
}, ref) => {
    
    const count = React.useContext(OnboardCountContext);

    const {
        transportLines = {}
    } = count?.elements || {};

    const {
        section
    } = count?.selectedElements;

    const countInterval = count?.selectedElements?.countInterval

    const datasets = [];
    
    if (section) {
        datasets.push(
            Dataset({
                name: 'onward',
                label: t('FT/DailyAverageOnwardCount'),
                getValue: item => item?.traffic?.[countInterval?.str]?.onward?.avg || 0,
                color: onboardCountColors.onward,
            })
        );

    } else {
        datasets.push(
            Dataset({
                name: 'board',
                label: t('FT/DailyAverageBoardCount'),
                getValue: item => item?.traffic?.[countInterval?.str]?.board?.avg || 0,
                color: onboardCountColors.board,
            })
        );

        if (!boardOnly) {
            datasets.push(Dataset({
                name: 'alight',
                label: t('FT/DailyAverageAlightCount'),
                getValue: item => 0 - item?.traffic?.[countInterval?.str]?.alight?.avg || 0,
                color: onboardCountColors.alight,
            }))
        }

    }

    return (
        <DataChart 
            ref={ref}
            title={formatString(t('FT/DailyAverageTransportLineCountChartTitle'), getChartLabels(count))}
            data={Object.values(transportLines).filter(transportLine => transportLine.traffic?.[countInterval?.str]?.board?.avg).sortBy(`traffic.${countInterval?.str}.board.avg`, true)}
            sets={datasets}
            animation={false}
            scales={{
                x: {
                    stacked: true
                },
                y: {
                    ticks: {
                        callback: val => Math.abs(val)
                    }
                }
            }}
            {...props}
        />
    )
});

export default TransportLineBarChart