import { getAPI } from "utils/api"

export const getCrossSectionRoadCountFormData = (countId, signal) => getAPI(`crosssectionroadcount/${countId}/form`, signal)

export const getStations = (countId, params, signal) => getAPI(`crosssectionroadcount/${countId}/count_data`, params, signal)

export const getYearlyStationCounts = (
    countId, 
    stationCode, 
    signal
) => getAPI(`crosssectionroadcount/${countId}/station/${stationCode}/yearly`, {}, signal);

export const getMonthlyStationCounts = (
    countId, 
    stationCode, 
    params, 
    signal
) => getAPI(`crosssectionroadcount/${countId}/station/${stationCode}/monthly`, params, signal);