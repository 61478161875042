function borderCrossingLabeling () {
    return [
        {
            deconflictionStrategy: 'static', // 'static' or 'none'
            labelPlacement: 'above-center', 
            labelExpressionInfo: {
                expression: `$feature.Name`
            },
            minScale: 750000,
            symbol: {
                type: 'text',
                color: 'red',
                haloColor: 'white',
                haloSize: 2,
                font: {
                    family: "Arial",
                    style: "normal",
                    size: 9
                }
            },
        }
    ];
};


export default borderCrossingLabeling;

