const roadSectionRenderer = () => {
    return {
        type: 'simple',
        symbol: {
            type: 'simple-marker',
            color: 'white',
            outline: {
                color: 'black',
                width: 1,
            }
        },
        visualVariables: [{
            type: "size",
            valueExpression: "$view.scale",
            stops: [{
                value: 750000,
                size: 2
            }, {
                value: 150000,
                size: 3
            },{
                value: 25000,
                size: 4
            }],
        }]
    }
};

export default roadSectionRenderer;