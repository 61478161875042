import { getSegmentJoins } from "./segmentJoins";
import { getRoadNetworkJoins } from "./roadNetworkJoins";
import { getSettlementJoins } from "./settlementJoins";
import { getRoadSectionJoins } from "./roadSectionJoins";
import { getBorderCrossingJoins } from "./borderCrossingJoins";
import { noop } from "utils/constants";
import { MADT_VIEW, MAJOR_ROAD_CATEGORIES } from "../constants";
import { t } from "i18next";


function getRenderEvents(setRenderingLayers, name) {
    function onRenderStarted () {
        setRenderingLayers(prev => ({
            ...prev,
            [name]: {
                loaded: prev?.[name]?.loaded || 0,
                total: prev?.[name]?.total || 0 + 1
            }
        }))
    }

    function onRendered () {
        setRenderingLayers(prev => { 
            const result = {
                ...prev,
                [name]: {
                    loaded: prev?.[name]?.loaded || 0 + 1,
                    total: prev?.[name]?.total || 0
                }
            };

            if (!Object.values(result).filter(({loaded, total}) => loaded < total).length) {
                return {};
            }

            return result;
        })
    }

    return { onRenderStarted, onRendered }
}


function getJoins({
    segmentElementId,
    borderCrossingElementId,
    settlementElementId,
    roadSectionElementId,
    roadNetworkElementId,
    emptySegmentIds = [],
    emptyRoadNetworkIds = [],
    elements,
    filters,
    setRenderingLayers = noop,
    setInitialized = noop,
}) {

    const {
        viewType,
        vehicleClassCodes,
        roadCategoryCodes = MAJOR_ROAD_CATEGORIES,
        widthModifier,
    } = filters;

    const {
        stations = {},
    } = elements;

    if ([segmentElementId, borderCrossingElementId, roadSectionElementId].includes(undefined)) {
        return {};
    }

    const emptySegmentElements = Object.fromEntries(emptySegmentIds.map(id => [id, [
        ...getSegmentJoins({
            vehicleClassCodes,
            widthModifier,
            name: `emptySegment-${id}`,
            visible: false,
            ...getRenderEvents(setRenderingLayers, `element-${id}`),
        }),
    ]]));

    const emptyRoadNetworkElements = Object.fromEntries(emptyRoadNetworkIds.map(id => [id, [
        ...getRoadNetworkJoins({
            name: undefined, 
            visible: false,
            ...getRenderEvents(setRenderingLayers, `element-${id}`),
        }),
    ]]));

    const result = {
        ...emptySegmentElements,
        ...emptyRoadNetworkElements,
        [borderCrossingElementId]: getBorderCrossingJoins(getRenderEvents(setRenderingLayers, `element-${borderCrossingElementId}`)),
        [roadSectionElementId]: getRoadSectionJoins(getRenderEvents(setRenderingLayers, `element-${roadSectionElementId}`)),
        [settlementElementId]: getSettlementJoins(getRenderEvents(setRenderingLayers, `element-${settlementElementId}`)),
        [segmentElementId]: getSegmentJoins({
            stations: Object.values(stations), 
            vehicleClassCodes,
            roadCategoryCodes,
            widthModifier,
            setInitialized,
            title: viewType === MADT_VIEW ? t('OKKF/MonthlyAverageDailyTraffic') : t('OKKF/AnnualAverageDailyTraffic'),
            ...getRenderEvents(setRenderingLayers, `element-${segmentElementId}`),
        }),
        [roadNetworkElementId]: getRoadNetworkJoins(getRenderEvents(setRenderingLayers, `element-${roadNetworkElementId}`)),
    }

    return result

};


export default getJoins;