import DataChart, { Dataset } from 'features/chart/components/DataChart';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import { crossSectionRoadCountColors } from 'features/crossSectionRoadCount/utils/colors';
import { MONTH_LABELS, ROAD_CATEGORIES } from 'features/crossSectionRoadCount/utils/constants';
import { t } from 'i18next';
import React from 'react'
import { formatString, numStringSuffix } from 'utils/format';

const DayTypesStationTrafficChart = React.forwardRef(({
    ...props
}, ref) => {

    const count = React.useContext(CrossSectionRoadCountContext);

    const {
        stationYear,
        stationMonth,
    } = count?.filters;

    const vehicleClasses = count?.getYearlyVehicleClasses(stationYear) || {};
    const {
        station = {}
    } = count?.selectedElements || {};

    const datasets = Object.values(vehicleClasses).map(vehicleClass => Dataset({
        name: vehicleClass.code,
        label: `${vehicleClass.code} - ${vehicleClass.name}`,
        getValue: item => item?.vehicleClasses?.[vehicleClass.code]?.amount || 0,
        color: crossSectionRoadCountColors[vehicleClass.code],
    }));

    return (
        <DataChart 
            ref={ref}
            title={formatString(t('OKKF/TrafficByDayTypeChartTitle'), {
                stationCode: station?.code,
                roadName: station?.roadName,
                roadCategory: ROAD_CATEGORIES[station?.roadCategory]?.toLowerCase(),
                startSection: station?.startSection,
                endSection: station?.endSection,
                month: MONTH_LABELS[stationMonth],
                year: stationYear,
            })}
            data={Object.values(station?.monthlyTraffic || {}).filter(row => row.month === stationMonth).sortBy('month')}
            sets={datasets}
            getLabel={row => row?.dayType?.name}
            fullWidth
            padding={{
                right: 0,
                left: 0
            }}
            animation={false}
            scales={{
                x: {
                    stacked: true,
                    ticks: {
                        align: 'center',
                        minRotation: 90,
                        maxRotation: 90,
                    }
                },
                y: {
                    stacked: true,
                }
            }}
            plugins={{
                legend: {
                    position: 'right',
                    align: 'left',
                }
            }}
            {...props}
        />
    )
});

export default DayTypesStationTrafficChart