import React from "react";

import { loginUser, logoutUser, getProfileData } from "../authAPI";


function useAuth() {
    const [user, setUser] = React.useState();
    const [adminVisible, setAdminVisible] = React.useState(true);

    const isAdmin = user?.roles?.includes('admin');
    
    React.useEffect(() => {
        if (user === undefined) {
            getProfileData()
                .then( ({ data })  => setUser(data) )
                .catch( err => setUser(null));
        };
    }, [user])

    function showAdminTools(value) {

        if (value && !isAdmin) {
            console.warn('User has no admin rights!');
        } else {
            setAdminVisible(value);
        }
    }

    async function login({ username, password }) {
        return loginUser({ username, password })
            .then( ({ data }) => setUser(data) )
            .catch(err => {
                setUser(null);
                throw err
            })
    }
    async function logout() {
        return logoutUser().then(setUser(null))
    }

    const isAnonymous = user === null;

    return {
        user, 
        isAnonymous, 
        isAdmin,
        adminVisible: isAdmin && adminVisible,
        showAdminTools,
        login, 
        logout,
    };        
};

export default useAuth;