import React from "react";

import CenteredPanelPage from "layouts/CenteredPanelPage";

import ResetPasswordForm from "../components/forms/ResetPasswordForm";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../authAPI";
import { Message, toaster } from "rsuite";
import { t } from "i18next";

const ResetPasswordPage = () => {

    const { token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    function submit({password}) {
        setLoading(true);
        resetPassword({ token, password })
        .then(() => {
            toaster.push(<Message showIcon>
                {t(`Security/ResetPasswordSuccesful`)}
            </Message>)
            navigate('/login');
        })
        .catch( err => {
            toaster.push(<Message showIcon type="error" closable>
                {t(`API/${err.message}`)}
            </Message>)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <CenteredPanelPage>
            <ResetPasswordForm onSubmit={submit} loading={loading} />
        </CenteredPanelPage>
    );
};

export default ResetPasswordPage;