import React from 'react'
import TimeBadge from './TimeBadge'
import { StartTimeIcon } from '../icons'

const StartTimeBadge = ({size, startTime}) => {
    return (
        <TimeBadge 
            size={size}
            icon={<StartTimeIcon />}
            start={startTime}
        />
    )
}

export default StartTimeBadge