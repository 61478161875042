import React from 'react'
import { Nav } from 'rsuite';

import { noop } from 'utils/constants'


const PanelMenuNav = ({
    appearance,
    data = [],
    module,
    value,
    setValue = noop,
}) => {

    return (
        <Nav
            appearance={appearance}
            activeKey={value}
            onSelect={setValue}
        >
            {data.map((item) => {
                const props = {...item};
                Object.entries(props).forEach(([name, value]) => {
                    if (typeof value === 'function') {
                        props[name] = value(module);
                    }
                }) 
                const label = props?.label
                const key = props?.key
                delete props.key
                delete props.label


                return (
                    <Nav.Item key={key} eventKey={key} {...props} >
                        {label}
                    </Nav.Item>
                )
            })}

        </Nav>
    )
}


const PanelMenu = ({
    navs = [],
    module
}) => {

    return <>
        { navs.map(({data, value, setValue}, i) => (
            data.length > 1
                ? <PanelMenuNav 
                    key={i}
                    appearance={!i ? 'tabs' : ( i === 1 ? 'subtle' : 'default')}
                    data={ data }
                    module={module}
                    value={ value }
                    setValue={setValue}
                /> : <></>
            
        )) }
    </>
}

export default PanelMenu