import { getAPI, postAPI } from "utils/api";


export const loginUser = ({
    username,
    password
}) => {
    return postAPI('login', { username, password })
};

export const logoutUser = () => postAPI('logout')

export const forgotPassword = ({
    email
}) => {
    return postAPI('forgot_password', { email })
};

export const registerUser = ({
    username,
    email,
    password,
    organizationIds,
    subscriptionTypeIds,
    validFrom,
}) => {
    return postAPI('register', {
        username, 
        email, 
        password,
        organizationIds,
        subscriptionTypeIds,
        validFrom,
    })
};

export const getUserFromToken = (token) => {
    return getAPI('confirm', { token })
}

export const confirmUser = ({
    token,
    password,
}) => {
    return postAPI('confirm', { token, password })
}

export const resetPassword = ({
    token, 
    password
}) => {
    return postAPI('reset_password', { token, password })
};


export const getProfileData = () => getAPI('profile/')