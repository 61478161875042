import React from 'react';
import { useToaster } from 'rsuite';

import { noHook, noop } from 'utils/constants';

import useElements from './useElements';
import useFilters from './useFilters';
import ModuleNotification from '../components/ModuleNotification';


function defaultRenderItem (item) {
    if (item?.name) {
        return item.name;
    }

    if (item?.elementType) {
        return `${item.elementType} ${item?.elementId}`;
    }

    if (item?.id) {
        return `${item.id}`
    }

    return item;
}


export default function useModule({
    moduleName = 'module',
    map,
    webmap,
    moduleParameters,
    tutorialSteps = 0,
    joinsHook = noHook,
    menuHook = noHook,
    setJoins = noop,
    queryFunctions = {},
    renderItem = defaultRenderItem,
    getExportName = (filters, elements) => 'Export',
    elementTypeOrder = [],
    viewPreferences = {},
}) {

    
    const showTutorial = webmap?.userConfig && !webmap?.userConfig?.hideTutorial;

    const [initialized, setInitialized] = React.useState(false);
    const [tutorialStep, setTutorialStep] = React.useState(0);
    const [renderingLayers, setRenderingLayers] = React.useState({});

    const table = React.useRef();
    const chart = React.useRef();

    const {filters, applyFilters} = useFilters();

    if (map?.elementTypeOrderRef && moduleParameters?.id && elementTypeOrder.length) {
        map.elementTypeOrderRef.current = elementTypeOrder;
    }

    const { 
        elements,
        loading,
        runningQueries,
        updateElements,
        clearElements, 
    } = useElements(queryFunctions, filters);


    const joins = joinsHook({
        moduleParameters,
        viewPreferences,
        elements,
        filters,
        runningQueries,
        setJoins,
        setInitialized,
        setRenderingLayers,
    });

    const { tableMenu, chartMenu } = menuHook();

    const toolbarRef = React.useRef();
    const sidebarRef = React.useRef();

    const queryToast = runningQueries.join(', ')
    const queryRef = React.useRef({});

    function updateViewPreferences (data) {
        webmap.updateUserConfig(data);
    };
    
    function setTaskProgress (name, done, total) {
        return map?.setTaskProgress(name, done, total, moduleName)
    }


    React.useEffect(() => {
        if (showTutorial) {
            setTutorialStep(1);
        }
    }, [showTutorial])

    React.useEffect(() => {

        if (!tutorialStep && showTutorial && tutorialSteps) {
            webmap?.updateUserConfig({
                hideTutorial: true
            })
        }

    }, [tutorialStep])

    React.useEffect(() => {
        console.log('Running queries changed...')

        Object.entries(queryRef.current).forEach(([name, value]) => {
            if (value && !runningQueries.includes(name)) {
                queryRef.current[name] = false;
            }
        })

        runningQueries.forEach(name => {
            queryRef.current[name] = true;
        })

        const running = runningQueries.length;
        const total = Object.keys(queryRef.current).length;
        setTaskProgress('query', total-running, total);

        if (!running) {
            queryRef.current = {}
        } 

    }, [queryToast])

    React.useEffect(() => {
        if (!initialized) {
            let loaded = 0;
            let total = 0;
            Object.values(renderingLayers).forEach(item => {
                loaded += item?.loaded;
                total += item?.total;
            })
            setTaskProgress('initialize', loaded, total)
        } else {
            setTaskProgress('initialize', 1, 1)
        }

    }, [initialized, JSON.stringify(renderingLayers)])

    return {
        name: moduleName,
        isDemo: webmap?.isDemo,
        moduleParameters: moduleParameters || {},
        loading,
        tutorialStep,
        tutorialSteps,
        map,
        table,
        tableMenu,
        chart,
        chartMenu,
        toolbarRef,
        sidebarRef,
        queryFunctions,
        runningQueries,
        viewPreferences,
        elements,
        filters,
        joins,
        initialized,
        setInitialized,
        setTutorialStep,
        renderItem,
        updateElements,
        clearElements,
        applyFilters,
        updateViewPreferences,
        getExportName: () => getExportName(filters, elements),
        setTaskProgress,
    };
};
