function DayType ({
    id,
    name
}) {
    const dayType = {
        id,
        name,
        label: `${id} - ${name}`,
    };

    return dayType;
};

export default DayType;
