import { logEvent } from 'features/logging';
import React from 'react';
import { checkObjectValues } from 'utils/compare';


export default function useElements(queryFunctions, filters) {

    const [ loading, setLoading ] = React.useState(false);
    const [ elements, setElements ] = React.useState({});
    const queriesRef = React.useRef({});

    const queryCacheRef = React.useRef({});

    const runningQueries = Object.keys(queriesRef.current).filter(name => queriesRef.current?.[name]?.controller);

    function processRows (name, rows) {
        if (rows) {
            setElements(prev => ({
                ...prev, 
                [name]: Object.fromEntries(
                    rows.map(row => [row?.id, row])
                ),
            }));
            console.log(`Finished loading '${name}' elements...`)
        }
    }

    function updateElements(...names) {
        console.log('updateElements', {
            names,
            filters,
        })
        logEvent('updateElements', {
            elements,
            filters,
        })
        names.map(name => {
            const queryFunction = queryFunctions?.[name];
            if (typeof queryFunction !== 'function') {
                console.error(`Invalid query function: ${name}`);
                logEvent('invalidQueryFunction', {
                    name,
                });
                return;
            };

            const cache = queryCacheRef.current?.[name];
            if (cache?.length) {
                for (let i = 0; i < cache?.length; i++) {
                    const {
                        data,
                        params
                    } = cache[i] || {};
    
                    if (checkObjectValues(filters, params)) {
                        console.log('Using cached query data...');
                        logEvent('usingCachedQuery', {
                            name
                        });
                        processRows(name, data);
                        return;
                    }
                }
            }

            const currentQuery = queriesRef.current?.[name];
            if (currentQuery?.controller) {
                if (checkObjectValues(currentQuery?.filters, filters)) {
                    console.log(`'${name}' query with same filters is already running...`);
                    logEvent('waitingForQueryWithSameParameters', {
                        name
                    });
                    return;
                } else {
                    console.log(`Aborting previous '${name}' query...`)
                    logEvent('abortingPreviousQuery', {
                        name
                    });
                    currentQuery.controller.abort();
                }
            }

            const controller = new AbortController();
            queriesRef.current[name] = {
                filters: {...filters},
                controller
            };
            
            setLoading(true);
            console.log(`Loading '${name}' elements...`);
            
            
            setElements(prev => ({
                ...prev, 
                [name]: {},
            }));

            logEvent('queryingElements', {
                name
            })
            
            queryFunction(filters, controller.signal)
                .then( ({rows, responseTime = 0}) => {
                    if (controller.signal.aborted) {
                        throw 'Query aborted'
                    }
                    logEvent('processingQueryRows', {
                        name,
                        responseTime,
                        records: rows?.length || 0
                    })
                    processRows(name, rows);
                    if (rows) {
                        queryCacheRef.current = {
                            ...queryCacheRef.current,
                            [name]: [
                                ...(queryCacheRef.current?.[name] || []),
                                {
                                    params: filters,
                                    data: rows
                                }
                            ]
                        }
                    }
                })
                .catch(err => {
                    console.log(`Failed loading '${name}' elements!`)
                    console.error(err);
                })
                .finally(() => {
                    
                    logEvent('elementsUpdateEnded', {
                        name,
                    })
                    queriesRef.current[name] = undefined;
                    
                    if ( !runningQueries.length ) {
                        setLoading(false);
                    }
                });
        });
    };

    
    function clearElements(...names) {
        setElements(prev => ({
            ...prev,
            ...Object.fromEntries(names.map(name => [name, undefined])),
        }));
    };

    return {
        elements,
        updateElements,
        clearElements,
        loading,
        runningQueries
    }
}
