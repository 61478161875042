import React from 'react'
import { Button, CheckTreePicker } from 'rsuite'

import { ModuleFilter } from 'features/webmapModule'
import { t } from 'i18next';
import ServicePatternCard from '../cards/ServicePatternCard';
import TransportLineCard from '../cards/TransportLineCard';
import TransportLine from 'features/onboardCount/elements/TransportLine';
import { getTransportLineFilters } from 'features/onboardCount/utils/filter';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';


const TransportLinesFilter = ({
}) => {

    const module = React.useContext(OnboardCountContext);

    const controlRef = React.useRef();
    
    const defaultValue = [
        ...(module?.filters?.servicePatternIds || []).map(id => `servicePattern-${id}`),
        ...(module?.filters?.transportLineIds || []).map(id => `transportLine-${id}`),
    ];

    const [ value, setValue ] = React.useState(defaultValue);

    React.useEffect(() => {
        setValue(defaultValue);
    }, [`${defaultValue}`])

    const transportLines = module?.elements?.transportLines || {};
    const servicePatterns = module?.elements?.servicePatterns || {};

    const data = Object.values(transportLines).map(transportLine => TransportLine({...transportLine, servicePatterns})).sortBy().map(transportLine => ({
        label: transportLine.name,
        elementType: 'transportLine',
        element: transportLine,
        value: `transportLine-${transportLine.id}`,
        children: (transportLine.servicePatterns || []).sortBy().map(servicePattern => ({
            label: `${transportLine.name} ${servicePattern.name}`,
            elementType: 'servicePattern',
            element: servicePattern,
            value: `servicePattern-${servicePattern.id}`,
        }))
    }))


    function submitValues(values) {
        module.applyFilters(getTransportLineFilters(values, servicePatterns));
    }

    return ( 
        <ModuleFilter 
            ref={controlRef}
            name={t('FT/TransportLines')}
            acceptor={CheckTreePicker}
            disabled={!data.length}
            data={data}      
            renderTreeNode={item => {
                switch (item?.elementType) {
                    case 'transportLine':
                        return <TransportLineCard size="xs" {...(item?.element || {})} />
                    case 'servicePattern':
                        return <ServicePatternCard size="xs" {...(item?.element || {})} />
                }
            }}
            value={value}
            onChange={setValue}
            onExit={() => submitValues(value)}
            onClean={() => submitValues([])}
            renderExtraFooter={() => <div style={{padding: 10}}>
                <Button 
                    appearance="primary"
                    block 
                    onClick={() => {
                        controlRef.current.close()
                    }
                }>
                    { t('ApplyFilter') }
                </Button>
            </div>}
        />
     )
}

export default TransportLinesFilter