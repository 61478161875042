import React from 'react';
import { t } from "i18next";

import { PageTileGrid } from "components/tiles";
import { PaginatedPanel } from "features/pagination";

import { deleteWebMap, getWebMapList } from "../webmapAPI";
import WebMapModal from "./WebMapModal";
import { AuthContext } from 'features/auth';
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal';


const WebMapTilesPanel = ({
    organizationId,
    collectionId,
    defaultPageSize = 12,
    pageSizeOptions = [6, 12, 24, 48],
    tileHeight,
    imgHeight,
    ...props
}) => {

    const { adminVisible } = React.useContext(AuthContext);
    const [update, setUpdate] = React.useState(false);

    function forceUpdate() {
        setUpdate(update => !update);
    }

    const DeleteModal = ({
        item,
        ...props
    }) => (
        <ConfirmDeleteModal
            onDelete={() => deleteWebMap(item?.id).then(forceUpdate)}
            itemLabel={t('WebMap')} 
            {...props} />  
    )

    return (
        <PaginatedPanel 
            title={t('WebMaps')}
            createModalClass={adminVisible && WebMapModal}
            createValues={{
                organizationId,
                collections: collectionId ? [{id: collectionId}] : []
            }}
            getItems={({page, pageSize}) => getWebMapList({
                organizationId,
                collectionId,
                page,
                pageSize,
            })}
            renderItems={({data}) => <PageTileGrid
                data={data}
                endpoint="webmaps"
                tileHeight={tileHeight}
                imgHeight={imgHeight}
                renderItemContent={item => item?.location}
                editModalClass={adminVisible && WebMapModal}
                deleteModalClass={adminVisible && DeleteModal}
            />}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            {...props}
        />
    )
};


export default WebMapTilesPanel;
