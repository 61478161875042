import { t } from 'i18next'
import { Join } from 'features/webmap'

import { LAYER_ORDER, SETTLEMENT_LAYER } from '../constants'
import settlementRenderer from 'features/crossSectionRoadCount/lib/arcGISRenderers/settlementRenderer'
import settlementLabeling from 'features/crossSectionRoadCount/lib/arcGISLabels/settlementLabeling'

export const getSettlementJoins = () => {
    return [Join({
        index: LAYER_ORDER.indexOf(SETTLEMENT_LAYER),
        name: SETTLEMENT_LAYER,
        baseField: 'Name',
        targetField: 'Name',
        legendEnabled: false,
        renderer: settlementRenderer(),
        label: t('OKKF/Settlements'),
        // labelingInfo: settlementLabeling(),
        geometryType: 'polygon'
    })]
}
