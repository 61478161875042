import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { ModuleSidebar } from 'features/webmapModule'
import React from 'react'
import AreaTrafficTable from '../tables/AreaTrafficTable';
import TransportLineTrafficTable from '../tables/TransportLineTrafficTable';
import JourneyTrafficTable from '../tables/JourneyTrafficTable';
import StatCard from 'components/cards/StatCard';
import { Col, Row } from 'rsuite';
import { t } from 'i18next';
import { AlightIcon, BoardIcon, OnwardIcon } from '../icons';

const OnboardCountSidebar = () => {
    const module = React.useContext(OnboardCountContext);

    const sidebarRef = React.useRef();
    const journeyTableRef = React.useRef();
    const stopPlaceTableRef = React.useRef();
    const transportLineTableRef = React.useRef();

    const [journeyTableHeight, setJourneyTableHeight] = React.useState(120);

    const {
        countInterval,
        element,
        area,
        feature,
    } = module?.selectedElements || {};

    const sidebarHeight = document.getElementsByClassName('mapSidebar')?.[0]?.clientHeight;
    const journeyTableTop = 154 + (stopPlaceTableRef?.current?.body?.clientHeight || -10) + (transportLineTableRef?.current?.body?.clientHeight || -10);

    React.useEffect(() => {
        setJourneyTableHeight(Math.max(120, sidebarHeight - journeyTableTop));
    }, [sidebarHeight, journeyTableTop])

    if (!module?.countId || !feature) return;
    const traffic = element?.traffic?.[countInterval.str] || {};


    return (
        <ModuleSidebar ref={sidebarRef} module={module}>
            <Row>
                { element?.elementType === 'section' ? <>
                    <Col xs={12} lg={6}>
                        <StatCard 
                            icon={<OnwardIcon />}
                            name={t('FT/OnwardLong')}
                            title={t('FT/DailyAverageCrossSectionCount')}
                            unit={t('FT/travellerPerDay')}
                            value={element?.direction === 1 ? traffic?.forward?.avg : traffic?.backward?.avg}
                        />
                    </Col>
                </> : <>
                    <Col xs={12} lg={6}>
                        <StatCard 
                            icon={<AlightIcon />}
                            name={t('FT/Alight')}
                            title={t('FT/DailyAverageAlightCount')}
                            unit={t('FT/travellerPerDay')}
                            value={traffic?.alight?.avg}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <StatCard 
                            icon={<BoardIcon />}
                            name={t('FT/Board')}
                            title={t('FT/DailyAverageBoardCount')}
                            unit={t('FT/travellerPerDay')}
                            value={traffic?.board?.avg}
                        />
                    </Col>
                
                </> }
            
            </Row>

            { area ? <AreaTrafficTable ref={stopPlaceTableRef} stopPlacesOnly maxRows={3} /> : undefined }

            <TransportLineTrafficTable 
                ref={transportLineTableRef}
                selectedIntervalOnly
                servicePatternsOnly
                maxRows={3} />

            <JourneyTrafficTable ref={journeyTableRef} height={journeyTableHeight}/>

        </ModuleSidebar>
    )
}

export default OnboardCountSidebar