import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Form, Loader, SelectPicker, TreePicker } from "rsuite";
import { t } from "i18next";

import { getServices } from 'features/webmap/webmapAPI';
import { noop } from "utils/constants";


const MapElementForm = ({ 
    element,
    onSubmit = noop,
    onCancel = noop,
    ...props 
}) => {
    const [services, setServices] = React.useState();
    const [path, setPath] = React.useState(element?.path);
    const [name, setName] = React.useState(element?.name);
    const [elementType, setElementType] = React.useState(element?.type);

    const submitData = {
        path,
        name,
        elementType
    }

    const mapElementTypes = [
        {
            label: t('Map/MapImageLayer'),
            value: 'map_image_layer',
        },
        {
            label: t('Map/TileLayer'),
            value: 'tile_layer',
        },
        {
            label: t('Map/FeatureLayer'),
            value: 'feature_layer',
        },
        {
            label: t('Map/ClientSideLayer'),
            value: 'client_side_layer',
        },
    ];

    React.useEffect(() => {
        getServices()
            .then(({data}) => setServices(data?.children));
    }, []);

    React.useEffect(() => {
        setPath(element?.path);
    }, [services]);

    return (
        <Form {...props}>
            <Form.Group controlId="service">
                <Form.ControlLabel>{t('Map/Service')}</Form.ControlLabel>
                { services ? (
                    <Form.Control 
                        name="service" 
                        placeholder={t('FT/SelectService')}
                        accepter={TreePicker}
                        data={services}
                        value={path} 
                        onChange={setPath} />
                ) : <>
                    <Loader /> 
                    {t('Map/LoadingServices')}...
                </> }
            </Form.Group>
            <Form.Group controlId="name">
                <Form.ControlLabel>{ t('Name') }</Form.ControlLabel>
                <Form.Control 
                    name="name"
                    value={name}
                    onChange={setName} />
            </Form.Group>
            <Form.Group controlId="type">
                <Form.ControlLabel>{ t('Type') }</Form.ControlLabel>
                <Form.Control 
                    name="type" 
                    placeholder={t('FT/SelectElementType')}
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    data={mapElementTypes}
                    value={elementType}
                    onChange={setElementType} />
            </Form.Group>
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit && onSubmit(submitData)}>
                        { t('Save') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default MapElementForm;
