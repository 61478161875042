import React from 'react';

import { getWebMapData, postUserConfig } from '../webmapAPI';
import { useNavigate } from 'react-router-dom';
import { Message, toaster } from 'rsuite';
import { t } from 'i18next';


function useWebmap (id) {
    
    const navigate = useNavigate();
    const [ webmap, setWebmap ] = React.useState();

    function updateWebmap (data) {
        if (data) {
            console.log(data);
            setWebmap({
                ...data,
                updateUserConfig,
            });
        } else {
            getWebMapData(id)
            .then(({data}) => {
                console.log('Querying Webmap data...')
                updateWebmap(data)
            })
            .catch((err) => {
                if (err?.status === 403) {
                    toaster.push(<Message type="error" closable>{t('Map/Unauthorized')}</Message>, {duration: 0})
                    navigate('/');
                }
            });
        }
    };

    function updateUserConfig (data) {
        postUserConfig(id, data).then(({data}) => {
            console.log('Updated user config');
            updateWebmap(data);
        })
    }

    React.useEffect(() => {
        if (id) {
            updateWebmap();
        }
    }, [id]);

    return {webmap, updateWebmap}
};


export default useWebmap;
