import 'assets/css/crossSectionRoadCount.css';

import React from 'react'
import { HighwayIcon, MotorwayIcon } from '../icons';
import Badge from 'components/badge';


const RoadBadge = ({
    size="md",
    name,
    useIcon=true,
    highway,
    motorway,
    mainRoad,
}) => {
    const type = `roadBadge ${highway ? 'highway' : ''}${motorway ? 'motorway' : ''}${mainRoad ? 'mainRoad' : ''}`
    return (
        <Badge size={size} type={type}>
            { useIcon && motorway && <HighwayIcon style={{marginRight: 4}} /> }
            { useIcon && highway && <MotorwayIcon style={{marginRight: 4}} /> }
            { name }
        </Badge>
    )
}

export default RoadBadge