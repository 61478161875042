import React from "react";
import 'assets/css/home.css';

import { Col, Grid, Row } from "rsuite";
import { t } from "i18next";

import { Jumbotron } from 'components/pages';
import { CollectionTilesPanel } from "features/collection";
import { WebMapListPanel } from "features/webmap";
import SimplePage from "layouts/SimplePage";
import { BlogEntryListPanel } from 'features/blog';
import { AuthContext } from 'features/auth';

const HomePage = () => {

    
    const { user } = React.useContext(AuthContext);

    return (
        <SimplePage >
            <Jumbotron 
                title={t('Home/Welcome')}
                /*subtitle={t('Home/Description')}*/ />
            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} md={user?.blogActive ? 24 : 16}>         
                        <CollectionTilesPanel />
                    </Col>
                    <Col xs={24} md={user?.blogActive ? 12 : 8} >
                        <WebMapListPanel />
                    </Col>
                    { user?.blogActive && (  
                        <Col xs={24} md={12} >         
                            <BlogEntryListPanel />
                        </Col>
                    )}
                </Row>
            </Grid>
        </SimplePage>
    );
};


export default HomePage;
