import React from "react";
import { Col, Grid, Row } from "rsuite";

const Jumbotron = ({
    title,
    subtitle,
    children,
    backgroundUrl = "/DarkBaseMap.png",
    ...props
}) => {
    return (
        <div 
            className='jumbotron' 
            style={{
                backgroundImage: `url(${backgroundUrl})`,
            }}
            {...props}
        >
            <h1>{title}</h1>
            <h6>{subtitle}</h6>
            
            <Grid className='jumbotronContent'>
                <Row>
                    <Col xs={24}>
                        {children}
                    </Col>
                </Row>
            </Grid>
        </div>
    )
}

export default Jumbotron;