import React from 'react'
import AreaCard from './AreaCard'
import JourneyCard from './JourneyCard'
import JourneyStopCard from './JourneyStopCard'
import SectionCard from './SectionCard'
import ServicePatternCard from './ServicePatternCard'
import TransportLineCard from './TransportLineCard'
import StopPlaceCard from './StopPlaceCard'

const ElementCard = ({
    item,
    ...props
}) => {
    switch( item?.elementType ) {
        case 'area':
            return <AreaCard {...item} {...props} />
        case 'journey':
            return <JourneyCard {...item} {...props} />
        case 'journeyStop':
            return <JourneyStopCard {...item} {...props} />
        case 'section':
            return <SectionCard {...item} {...props} />
        case 'servicePattern':
            return <ServicePatternCard {...item} {...props} />
        case 'stopPlace':
            return <StopPlaceCard {...item} {...props} />
        case 'transportLine':
            return <TransportLineCard {...item} {...props} />
    }
}

export default ElementCard