import 'assets/css/card.css';

import React from "react";
import { ButtonToolbar } from "rsuite";


const ActionToolbar = ({ children, ...props }) => {
    return (
        <ButtonToolbar className="actionToolbar" {...props} >
            {children}
        </ButtonToolbar>
    );
};


export default ActionToolbar;
