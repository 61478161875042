import { AvatarCard } from "components/cards";


const PageTitle = ({
    item,
    title,
    subtitle,
}) => {
    return <AvatarCard
        size="lg"
        bordered
        filled
        imageURL={item?.thumbnailUrl}
        title={<h2>{title ||item?.title || item?.name}</h2>}
        subtitle={subtitle || item ?.subtitle ? <span style={{fontWeight: 'bold'}} >{subtitle || item ?.subtitle}</span> : undefined}
    />
};


export default PageTitle;
