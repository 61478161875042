import React from 'react'
import { checkObjectValues } from 'utils/compare';
import { noop } from 'utils/constants'
import getJoins from '../utils/joins/getJoins';
import CrossSectionRoadCountContext from '../context/CrossSectionRoadCountContext';
import { zfill } from 'utils/format';

function getElementId (name, elements = {}, year=1970, month=1) {
    const dateStr = `${year}-${zfill(month, 2)}-01`;

    const elementIds = Object.entries(elements?.[name] || {});
    let elementId;
    switch (elementIds?.length) {
        case 0:
            break;
        case 1:
            elementId = elementIds[0][1];
            break;
        default:

            for (let i = 0; i < elementIds.length; i++) {
                const currentDate = elementIds?.[i]?.[0];
                const nextDate = elementIds?.[i+1]?.[0];

                if (!nextDate || nextDate > dateStr) {
                    elementId = elements[name][currentDate];
                    break
                }
            }
    }
    return elementId;
}


const useCrossSectionRoadCountJoins = ({
    moduleParameters = {},
    elements = {},
    filters = {},
    runningQueries = [],
    setJoins = noop,
    setInitialized = noop,
    setRenderingLayers = noop,
}) => {

    const segmentElementId = getElementId('segments', moduleParameters.elements, filters?.year, filters?.month);
    const borderCrossingElementId = getElementId('borderCrossings', moduleParameters.elements, filters?.year, filters?.month);
    const settlementElementId = getElementId('settlements', moduleParameters.elements, filters?.year, filters?.month);
    const roadSectionElementId = getElementId('roadSections', moduleParameters.elements, filters?.year, filters?.month);
    const roadNetworkElementId = getElementId('roadNetwork', moduleParameters.elements, filters?.year, filters?.month);

    const emptySegmentIds = Object.values(moduleParameters?.elements?.segments || {}).filter(id => id !== segmentElementId);
    const emptyRoadNetworkIds = Object.values(moduleParameters?.elements?.roadNetwork || {}).filter(id => id !== roadNetworkElementId);

    const joinsRef = React.useRef();
    const prevElements = React.useRef({});
    const prevFilters = React.useRef({});

    const {
        stations = {},
    } = elements;
    const {
        year = {},
        widthModifier = 1,
        roadCategoryCodes,
    } = filters;

    const skippedElementTypes = {
        selectedStations: undefined,
        yearlyStationCounts: undefined,
        monthlyStationCounts: undefined,
        monhtlyStationCountsByMonth: undefined,
        monhtlyStationCountsByDayType: undefined,
        monhtlyStationCountsForYear: undefined,
    }


    const queryCheck = ['stations'].every(name => !runningQueries.includes(name));
    const data = {
        ...elements,
        ...skippedElementTypes,
        year,
        widthModifier,
        roadCategoryCodes,
    };
    const prevData = {
        ...prevElements.current,
        ...skippedElementTypes,
        year: prevFilters.current?.year,
        widthModifier: prevFilters.current?.widthModifier || 1,
        roadCategoryCodes: prevFilters.current?.roadCategoryCodes,
    };
    const dataCheck = !checkObjectValues(data, prevData);

    React.useEffect(() => {
        if ( queryCheck && dataCheck ) {
            console.log('Updating joins...')
            prevElements.current = elements;
            prevFilters.current = filters;

            joinsRef.current = getJoins({
                segmentElementId,
                borderCrossingElementId,
                settlementElementId,
                roadSectionElementId,
                roadNetworkElementId,
                emptySegmentIds,
                emptyRoadNetworkIds,
                elements,
                filters,
                setInitialized,
                setRenderingLayers,
            });
            
            setJoins(joinsRef.current);
        };

    }, [
        stations,
        `${Object.keys(stations || {})}`, 
        widthModifier, 
        segmentElementId, 
        borderCrossingElementId, 
        settlementElementId, 
        roadSectionElementId, 
        roadNetworkElementId, 
        `${roadCategoryCodes}`
    ]);

    return joinsRef.current;
}

export default useCrossSectionRoadCountJoins