import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Form } from "rsuite";
import { t } from "i18next";

import { noop } from "utils/constants";

const ConfigurationForm = ({ 
    configuration,
    onSubmit = noop,
    onCancel = noop,
    ...props 
}) => {
    
    const [name, setName] = React.useState(configuration?.name);

    return (
        <Form {...props}>
            <Form.Group controlId="name">
                <Form.ControlLabel>{ t('Name') }</Form.ControlLabel>
                <Form.Control 
                    name="name"
                    value={name}
                    onChange={setName} />
            </Form.Group>
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit({name})}>
                        { t('Save') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};

export default ConfigurationForm;
