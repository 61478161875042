import { Form, SelectPicker } from "rsuite";

import { FormGroup } from "components/forms";
import { noop } from "utils/constants";


function defaultRenderItem (item) {
    if (item?.label) {
        return item.label;
    }

    if (item?.name) {
        return item.name;
    }

    if (item?.elementType) {
        return `${item.elementType} ${item?.elementId}`;
    }

    return item;
};


const SelectFeatureForm = ({
    map,
    getLabel = defaultRenderItem,
    renderItem = defaultRenderItem
}) => {
    const {
        selection = [],
        selectionIndex,
        setSelectionIndex = noop,
    } = map;

    const data = selection.map((item, value) => ({label: getLabel(item?.attributes), value}));

    return (
        <Form>
            <FormGroup
                name="selectedFeature"
                accepter={SelectPicker}
                searchable={false}
                cleanable
                data={data}
                value={selectionIndex}
                onChange={setSelectionIndex}
            />
        </Form>
    )
};


export default SelectFeatureForm;
