import React from 'react';
import { Pagination } from 'rsuite';


const PaginationNav = ({
    page,
    setPage,
    pageSize,
    numberOfPages,
}) => {
    return <Pagination 
        prev
        last
        next
        first
        total={numberOfPages*pageSize}
        limit={pageSize}
        activePage={page}
        onSelect={setPage} />
}

export default PaginationNav;