import { faArrowDown, faArrowUp, faCalendar, faCalendarAlt, faCalendarDay, faCalendarWeek, faCar, faMapMarker, faMapMarkerAlt, faRoad } from '@fortawesome/free-solid-svg-icons';
import FaIcon, { ESRIIcon } from 'components/icons';


export const LayersIcon = ({ ...props }) => <ESRIIcon name="layers" />

export const ViewIcon = ({ ...props }) => <ESRIIcon name="visible" />

export const SearchIcon = ({ ...props }) => <ESRIIcon name="search" />

export const OnwardIcon = ({ ...props }) => <FaIcon icon={faArrowUp} {...props} />

export const BackwardIcon = ({ ...props }) => <FaIcon icon={faArrowDown} {...props} />

export const MotorwayIcon = ({ ...props }) => <FaIcon icon={faCar} {...props} />

export const HighwayIcon = ({ ...props }) => <FaIcon icon={faRoad} {...props} />

export const YearIcon = ({ ...props }) => <FaIcon icon={faCalendar} {...props} />

export const MonthIcon = ({ ...props }) => <FaIcon icon={faCalendarAlt} {...props} />

export const WeekIcon = ({ ...props }) => <FaIcon icon={faCalendarWeek} {...props} />

export const DayIcon = ({ ...props }) => <FaIcon icon={faCalendarDay} {...props} />

export const StationIcon = ({ ...props }) => <FaIcon icon={faMapMarkerAlt} {...props} />
