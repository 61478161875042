import React from 'react';

import { noop } from 'utils/constants';

const HeaderCell = ({
    children,
    value,
    selected,
    multiselect,
    selectedColumns = [],
    onChange = noop
}) => {
    const checked = selectedColumns.includes(value);

    function handleChange(key, checked) {
        if (!checked) {
            if (multiselect) {
                onChange(selectedColumns.filter(key => key !== value));
            } else {
                onChange([]);
            }
        } else if (checked && !selectedColumns.includes(key)) {
            if (multiselect) {
                onChange([...selectedColumns, key]);
            } else {
                onChange([key]);
            }
        }
    };

    return (
        <div className={`headerCell${selected ? ' selected' : ''}`}>
            {/* { selectable && (
            <div className="selector">
                <Checkbox 
                    value={value} 
                    checked={checked} 
                    onChange={handleChange} />
            </div>
        )} */}
            <div className="content" onClick={() => handleChange(value, !checked)}>
                {children}
            </div>
        </div>
    )
}


export default HeaderCell;
