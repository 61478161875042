import React from 'react';
import { t } from "i18next";

import { PageTileGrid } from "components/tiles";
import { PaginatedPanel } from "features/pagination";

import { deleteCollection, getCollectionList } from "../collectionAPI";
import CollectionModal from './CollectionModal';
import { AuthContext } from 'features/auth';
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal';


const CollectionTilesPanel = ({
    organizationId,
    defaultPageSize = 12,
    pageSizeOptions = [6, 12, 24, 48],
    tileHeight,
    imgHeight,
    ...props
}) => {
    
    const { adminVisible } = React.useContext(AuthContext);
    const [update, setUpdate] = React.useState(false);

    function forceUpdate() {
        console.log('forcing update')
        setUpdate(!update);
    }
    
    const DeleteModal = ({
        item,
        ...props
    }) => (
        <ConfirmDeleteModal 
            onDelete={() => deleteCollection(item?.id).then(forceUpdate)}
            itemLabel={t('Collection')} 
            prompt={t('DeleteCollectionPrompt')}
            {...props} />  
    )

   
    return (
        <PaginatedPanel
            title={t('MapCollections')}
            createModalClass={adminVisible && CollectionModal}
            createValues={{
                organizations: organizationId ? [{id: organizationId}] : []
            }}
            getItems={({page, pageSize}) => getCollectionList({
                organizationId,
                page,
                pageSize,
            })}
            renderItems={({data}) => <PageTileGrid
                data={data}
                endpoint="collections"
                tileHeight={tileHeight}
                imgHeight={imgHeight}
                editModalClass={adminVisible && CollectionModal}
                deleteModalClass={adminVisible && DeleteModal}
                onModalClose={forceUpdate}
            />}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            {...props}
        />
    )
};


export default CollectionTilesPanel;
