import { getAPI } from "utils/api"

export function getSubscriptionList ({
    valid,
    past,
    future,
    page,
    pageSize
} = {}) {
    return getAPI(`subscriptions`, {
        valid,
        past,
        future,
        page,
        pageSize
    });
};

export function getSubscriptionTypeList ({
    page,
    pageSize
} = {}) {
    return getAPI(`subscription_types`, {
        page,
        pageSize
    });
};
