import { t } from "i18next";
import Color from "utils/color";


export const WEEK_INTERVAL = 'week';
export const MONTH_INTERVAL = 'month';
export const TIMETABLE_INTERVAL = 'interval';
export const DATE_INTERVAL = 'date';


export const COUNT_INTERVAL_TYPES = {
    [DATE_INTERVAL]: t('FT/QueryByDate'),
    [WEEK_INTERVAL]: t('FT/CalendarWeek'),
    [MONTH_INTERVAL]: t('FT/CalendarMonth'),
    [TIMETABLE_INTERVAL]: t('FT/TimetableInterval'),
};

export const COUNT_INTERVAL_TYPE_LABELS = {
    [DATE_INTERVAL]: t('FT/SelectCountDate'),
    [WEEK_INTERVAL]: t('FT/SelectCalendarWeek'),
    [MONTH_INTERVAL]: t('FT/SelectCalendarMonth'),
    [TIMETABLE_INTERVAL]: t('FT/SelectTimetableInterval')
};

export const COUNT_INTERVAL_TYPE_SHORT_LABELS = {
    [DATE_INTERVAL]: t('FT/QueryByDate'),
    [WEEK_INTERVAL]: t('FT/WeeklyAverage'),
    [MONTH_INTERVAL]: t('FT/MonthlyAverage'),
    [TIMETABLE_INTERVAL]: t('FT/IntervalAverage'),
}


export const COUNT_INTERVAL_TYPE_ORDER = [
    DATE_INTERVAL,
    WEEK_INTERVAL,
    MONTH_INTERVAL,
    TIMETABLE_INTERVAL,
];


export const TRANSPORT_LINE_TYPES = {
    5: 'underground',
    6: 'suburban',
    7: 'ship',
    2: 'tram',
    3: 'trolleyBus',
    1: 'bus',
    4: 'night',
};


export const TRANSPORT_LINE_NAMES = {
    5: t('FT/Underground'),
    6: t('FT/Suburban'),
    7: t('FT/Ship'),
    2: t('FT/Tram'),
    3: t('FT/TrolleyBus'),
    1: t('FT/Bus'),
    4: t('FT/Night'),
};


export const TRANSPORT_LINE_COLORS = {
    5: Color([139, 69, 19]),
    6: Color([0, 128, 0]),
    7: Color([128, 0, 128]),
    2: Color([218, 165, 32]),
    3: Color([255, 0, 0]),
    1: Color('#3498ff'),
    4: Color([128, 128, 128])
};


export const TRANSPORT_LINE_TYPE_ORDER = [5, 6, 7, 2, 3, 1, 4];



export const TRANSPORT_LINE_TRAFFIC = 'transportLineTraffic';
export const STOP_PLACE_TRAFFIC = 'stopPlaceTraffic';
export const SECTION_TRAFFIC = 'sectionTraffic';

export const BOARD_COUNT = 'boardCount';
export const ALIGHT_BOARD_COUNT = 'alightBoardCount';
export const CROSS_SECTION_COUNT = 'crossSectionCount';

export const DAILY_TRAFFIC_DISTRIBUTION = 'dailyTrafficDistribution';
export const LINE_TRAFFIC_DISTRIBUTION = 'lineTrafficDistribution';

export const QUERY_LABELS = {
    dayType: t('FT/DayTypes'),
    countIntervals: t('FT/CountIntervals'),
    sections: t('FT/Sections'),
    areas: t('FT/Areas'),
    stopPlaces: t('FT/StopPlaces'),
    transportLines: t('FT/TransportLines'),
    servicePatterns: t('FT/ServicePatterns'),
    journeys: t('FT/Journeys'),
    journeyStops: t('FT/JourneyStops'),
};
