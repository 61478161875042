export const countNearestRound = (value, decimals) => {
    if (!value) { return 0 }

    const lg10 = Math.log10(value);
    const res = lg10 % 1;

    const d = decimals ? [...decimals] : [1, 2, 3, 4, 5, 6, 7, 8, 9]

    const diff = d.map(v => {
        const r = Math.log10(v) % 1;
        return Math.min(Math.abs(res - r), Math.abs(res - r - 1))
    });

    return d[diff.indexOf(Math.min(...diff))] * 10**Math.floor(lg10);
};


export const countCeilingRound = (value, decimals) => {
    if (!value) { return 0 }

    const lg10 = Math.log10(value);
    const res = lg10 % 1;

    const d = decimals ? [...decimals] : [1, 2, 3, 4, 5, 6, 7, 8, 9]

    for (let i = 0; i < d.length; i++) {
        const v = d[i]
        if (res <= Math.log10(v) % 1) {
            return v * 10**Math.floor(lg10)
        }
    }

    return 10**(Math.floor(lg10) + 1);
};


export const countFloorRound = (value, decimals) => {
    if (!value) { return 0 }
    
    const lg10 = Math.log10(value);
    const res = lg10 % 1;

    const d = decimals ? [...decimals] : [1, 2, 3, 4, 5, 6, 7, 8, 9]

    d.reverse();

    for (let i = 0; i < d.length; i++) {
        const v = d[i]
        if (res >= Math.log10(v) % 1) {
            return v * 10**Math.floor(lg10)
        }
    }

    return 10**(Math.floor(lg10) + 1);
};


export const asRoman = (num) => {
    var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
    for ( i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman
}

export const xor = (a, b) => ((a ? !b : b) ? true : false)

export const sum = (arr) => arr.reduce((a, b) => a + b, 0);

export const avg = (arr) => arr.length
    ? sum(arr) / arr.length
    : undefined;

export const percentile = (arr, p) => arr.length 
    ? [...arr].sort()[Math.floor(arr.length * p / 100 - 1)]
    : undefined;

export const med = arr => percentile(arr, 50);

export const arrayStats = arr => ({
    min: arr.length 
        ? Math.min(...arr)
        : undefined,
    max: arr.length 
        ? Math.max(...arr)
        : undefined,
    avg: avg(arr),
    med: med(arr),
    count: arr?.length || 0,
    percentile: p => percentile(arr, p)
})

export function numberToHex(n) {
    let s = n.toString(16);
    return s.padStart(s.length + (s.length % 2), '0');
};


export function interpolate(start, end, ratio) {
    return start + (end - start) * ratio
}