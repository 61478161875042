import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const HTMLInput = ({ editorRef, value, onChange, height = 200, ...props }) => {
    return (
        <div {...props}>
            <Editor
                tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
                onInit={(evt, editor) => {
                    if (editorRef) {
                        editorRef.current = editor
                    }
                }}
                value={value}
                onEditorChange={onChange}
                init={{
                    height,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'bold italic underline table align bullist numlist | ' +
                        'outdent indent | ' +
                        'formatselect | ' +
                        'removeformat | code | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }} />
        </div>
    )
}

export default HTMLInput;