import FormGroup from 'components/forms/FormGroup';
import { MAP_SEARCH_TYPE_OPTIONS, SETTLEMENT_SEARCH_TYPE } from 'features/crossSectionRoadCount/utils/constants';
import { t } from 'i18next';
import React from 'react'
import { Input, SelectPicker } from 'rsuite';
import { noop } from 'utils/constants'

const SearchFormFields = ({
    onChange = noop,
}) => {

    const [settlement, setSettlement] = React.useState('');
    const [searchType, setSearchType] = React.useState(SETTLEMENT_SEARCH_TYPE)

    React.useEffect(() => {
        onChange({
            settlement: searchType === SETTLEMENT_SEARCH_TYPE ? settlement : undefined,
        })
    }, [settlement, searchType])

    return <>
        <FormGroup 
            name="searchType"
            label={t('OKKF/SearchType')}
            accepter={SelectPicker}
            data={MAP_SEARCH_TYPE_OPTIONS}
            searchable={false}
            cleanable={false}
            value={searchType}
            onChange={setSearchType}
        />

        { searchType === SETTLEMENT_SEARCH_TYPE ? <>
            <FormGroup 
                name="settlement"
                label={t('OKKF/Settlement')}
                accepter={Input}
                value={settlement}
                onChange={setSettlement}
            />
        </> : <></>}

    </>
}

export default SearchFormFields