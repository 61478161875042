import React from 'react'
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import { StopPlaceIcon } from '../icons';

const StopPlaceCard = ({
    size="md",
    name,
    transportLines = [],
    servicePatterns = [],
}) => {
    return (
        <AvatarCard size={size}
            icon={<StopPlaceIcon />}
            title={<>
                {name}
                <span style={{marginRight: 4}} />
                {transportLines.sortBy().map(transportLine => (
                    <TransportLineBadge size={size} {...transportLine} />
                ))}
            </>}
            subtitle={[...new Set(servicePatterns.map(servicePattern => servicePattern.name))].join(',')}
            hint={`${name} (${transportLines.map(transportLine => transportLine.name).join(", ")})`}
        />
    )
};

export default StopPlaceCard