const settlementRenderer = () => {
    return {
        type: 'simple',
        symbol: {
            type: 'simple-fill',
            color: [0, 0, 0, 0],
            style: 'solid',
            outline: {
                color: [0, 0, 0, 0],
                width: 1
            }
        }
    }
};

export default settlementRenderer;