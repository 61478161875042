import { noop } from "utils/constants";

const Join = ({
    name,
    index = 0,
    baseField,
    targetField,
    getJoinData,
    records = [],
    formatRecord = r => r,
    filterRecord = r => true,
    discardCache = false,
    orderBy,
    fieldTypes = {},
    fieldLabels = {},
    onRenderStarted = noop,
    onRendered = noop,
    ...props
}) => {

    const join = {
        name,
        index,
        baseField,
        targetField,
        getJoinData: getData,
        records: [],
        joinRecords: {},
        discardCache,
        orderBy,
        fields: Object.entries(fieldTypes).map(([name, type]) => ({
            name,
            type,
            alias: fieldLabels?.[name],
        })),
        layerProps: props,
        onRenderStarted,
        onRendered,
    };

    if (Array.isArray(records)) {
        join.records = records;
    } else {
        join.records = Object.values(records);
    }

    join.records = join.records.map(formatRecord).filter(filterRecord);
    join.joinRecords =  Object.fromEntries(join.records.map(r => [`${r?.[targetField]}`, r]));


    function getData (feature) {
        if (getJoinData) {
            return getJoinData(feature)
        } else {
            return join.joinRecords[`${feature?.attributes?.[baseField]}`];
        }
    }

    return join;
};


export default Join;
