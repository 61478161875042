import { FilterIcon, ResetIcon } from 'components/icons'
import { ModuleToolbar } from 'features/webmapModule'
import { t } from 'i18next'
import React from 'react'
import CountIntervalFilter from './CountIntervalFilter'
import TransportLinesFilter from './TransportLinesFilter'
import JourneyFilter from './JourneyFilter'
import SelectionFilter from './SelectionFilter'
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { IconButton } from 'rsuite'
import CountIntervalSlider from './CountIntervalSlider'


const OnboardCountToolbar = ({}) => {
    const module = React.useContext(OnboardCountContext);

    if (!module?.countId) return;

    return <>
        <ModuleToolbar
            module={module}
            title={t('Filters')}
            icon={<FilterIcon />}
            navigation
            applyInstantly
            footer={<CountIntervalSlider />}
        >
            <CountIntervalFilter />

            <TransportLinesFilter />

            <JourneyFilter />
            
            <SelectionFilter />

            <IconButton 
                icon={<ResetIcon />}
                title={t('ResetFilters')}
                onClick={() => module.applyFilters({
                    servicePatternIds: [],
                    transportLineIds: [],
                    journeyId: undefined,
                    areaId: undefined,
                    stopPlaceId: undefined,
                    sectionId: undefined,
                    sectionDirection: undefined
                })}

            />
        </ModuleToolbar>
    </>
}

export default OnboardCountToolbar