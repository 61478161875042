import { compareStrings } from "utils/compare";
import { ROAD_CATEGORIES } from "../utils/constants";





function SegmentPart ({
    id,
    stationCode,
    startJunctionCode,
    endJunctionCode,
    roadName,
    startSection,
    endSection,
    motorway,
    highway,
    mainRoad,
    onward,
    backward,
    roadCategory,
    ...props
}) {
    const segmentPart = {
        id: id || props?.['RoadSegments_FID'],
        stationCode: stationCode || `${props?.['SegmentStations_ASZ']}`,
        startJunctionCode: startJunctionCode || props?.['RoadSegments_KKOD'],
        endJunctionCode: endJunctionCode || props?.['RoadSegments_VKOD'],
        roadName: roadName || props?.['RoadSegments_KSZAM'],
        startSection: startSection || props?.['RoadSegments_KSZELV'],
        endSection: endSection || props?.['RoadSegments_VSZELV'],
        motorway: motorway || props?.['SegmentStations_KUTKA'] === '1',
        highway: highway || props?.['SegmentStations_KUTKA'] === '2',
        mainRoad: mainRoad || ['3', '4'].includes(props?.['SegmentStations_KUTKA']),
        onward: onward || [0, 1].includes(props?.['RoadSegments_PKOD']),
        backward: backward || [0, 2].includes(props?.['RoadSegments_PKOD']),
        direction: props?.['RoadSegments_PKOD'],
        roadCategory: roadCategory || ROAD_CATEGORIES?.[props?.['SegmentStations_KUTKA']],
        ...props,
    };

    return segmentPart;
};


export function compareSegmentParts (a, b) {
    return compareStrings(a?.KUTKA, b?.KUTKA) || 
        compareStrings(a.roadName, b.roadName) || 
        compareStrings(a.startSection, b.startSection)
};

export default SegmentPart;
