import 'assets/css/form.css';

import React from 'react';
import { Button, ButtonToolbar, Checkbox, Form, Input } from 'rsuite';
import { t } from 'i18next';
import { FormGroup } from 'components/forms';
import { noop } from 'utils/constants';


const ConfirmForm = ({
    email,
    passwordNeeded,
    loading,
    layout,
    onSubmit = noop,
}) => {
    const [password, setPassword] = React.useState();
    const [password2, setPassword2] = React.useState();
    const [ ula, setUla ] = React.useState(false);
    const [ privacyPolicy, setPrivacyPolicy ] = React.useState(false);

    const submittable = ula && privacyPolicy && (
        !passwordNeeded || (
            password &&
            password === password2
        )
    )

    return (
        <Form layout={layout}>
            <FormGroup>
                <h4>{ t('Security/ConfirmEmail') }</h4>
            </FormGroup>
            <FormGroup
                name="email"
                label={t('Email')}
                accepter={Input}
                type="email"
                autoComplete="off"
                value={email}
                disabled
            />
            { passwordNeeded && <>
                <FormGroup
                    name="password"
                    label={t('Password')}
                    accepter={Input}
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={setPassword}
                />
                <FormGroup
                    name="password2"
                    label={t('Security/ConfirmPassword')}
                    accepter={Input}
                    type="password"
                    autoComplete="off"
                    error={password !== password2 ? t('Security/PasswordsDontMatch') : undefined}
                    value={password2}
                    onChange={setPassword2}
                />
            </>}
            <FormGroup 
                name="ula"
                label={t('AcceptUserLicenseAgreement')}
                link="/docs/ula.html"
                linkTitle={t('UserAgreement')}
                accepter={Checkbox}
                value={ula}
                onChange={setUla}
            />
            <FormGroup 
                name="privacy_policy"
                label={t('AcceptPrivacyPolicy')}
                link="/docs/privacy_policy.html"
                linkTitle={t('PrivacyPolicy')}
                accepter={Checkbox}
                value={privacyPolicy}
                onChange={setPrivacyPolicy}
            />
            <Form.Group>
                <ButtonToolbar>
                    <Button 
                        appearance="primary" 
                        loading={loading}
                        disabled={!submittable}
                        onClick={() => onSubmit({password})}
                    >
                        { t('Security/ConfirmEmail') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );   
};

export default ConfirmForm;