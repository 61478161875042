import { t } from "i18next";

const PlaceholderList = ({
    data = [],
    renderItem = item => item?.name || item,
    pageSize = 10,
    itemHeight = 56,
    emptyMessage = t('EmptyList')
}) => {

    const placeholderHeight = Math.max(0, pageSize - data.length) * itemHeight;
    
    if (!data.length) {
        return emptyMessage
    }

    return <>
        {data.map((item, index) => (
            renderItem(item, index) 
        ))}
        <div style={{ height: placeholderHeight }} />
    </>
};


export default PlaceholderList;
