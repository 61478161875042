import React from "react";
import { List, Modal, Panel } from "rsuite";
import { t } from "i18next";

import { AvatarCard } from "components/cards";
import { ESRIIcon } from "components/icons";
import { noop } from "utils/constants";

import ConfigurationItemSettings from "./ConfigurationItemSettings";
import ConfigurationItemForm from "./forms/ConfigurationItemForm";
import ConfigurationForm from "./forms/ConfigurationForm";


const ConfigurationSettings = ({
    setSortable,
    configuration,
    webMap,
    onChange = noop,
    onItemChange = noop,
}) => {

    const [itemsVisible, setItemsVisible] = React.useState(false);

    const [addItemVisible, setAddItemVisible] = React.useState(false);
    const [editorVisible, setEditorVisible] = React.useState(false);

    const onParamChange = params => onChange({
        id: configuration.id,
        params
    });

    React.useEffect(() => {
        setSortable(!itemsVisible)
    }, [itemsVisible])

    return (
        <List.Item key={configuration?.id} index={configuration?.orderNumber}>
            <AvatarCard 
                title={configuration?.name}
                actions={[{
                    appearance: itemsVisible ? 'primary' : 'subtle',
                    icon: <ESRIIcon name={itemsVisible ? 'up-arrow' : 'down-arrow'}  />,
                    title: t('Map/ConfigItems'),
                    onClick: () => setItemsVisible(!itemsVisible)
                }, {
                    appearance: 'primary',
                    icon: <ESRIIcon name="plus" />,
                    title: t('Map/AddConfigItem'),
                    onClick: () => setAddItemVisible(!addItemVisible)
                }, {
                    appearance: "primary",
                    color: "yellow",
                    icon: <ESRIIcon name="edit" />,
                    title: t('Edit'),
                    onClick: () => setEditorVisible(true)
                }, {
                    appearance: "primary",
                    color: "red",
                    icon: <ESRIIcon name="trash" />,
                    title: t('Remove'),
                    onClick: () => onParamChange({delete: true})
                }]}/>

            
            { !itemsVisible ? <></> : (
                <Panel style={{marginBottom: 20}}>
                    <List 
                        sortable 
                        onSort={({oldIndex, newIndex}) => {
                            const itemIds = configuration?.items.map( item => item.id );
                            const moveId = itemIds.splice(oldIndex, 1)[0];
                            itemIds.splice(newIndex, 0, moveId);
                            onParamChange({itemOrder: itemIds});
                        }}
                    >
                        { configuration.items.map( configurationItem => <ConfigurationItemSettings 
                            key={configurationItem?.id}
                            configurationItem={configurationItem}
                            webMap={webMap}
                            onChange={onItemChange} /> )}
                    </List>

                </Panel>
            )}

            <Modal 
                size="xs"
                open={addItemVisible} 
                onClose={() => setAddItemVisible(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Map/AddConfigItem')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConfigurationItemForm
                        className="compactForm"
                        wevMap={webMap}
                        onSubmit={value => {
                            setAddItemVisible(false);
                            onItemChange({params: {
                                webMapConfigId: configuration?.id,
                                ...value
                            }});
                        }}
                        onCancel={() => setAddItemVisible(false)} />
                </Modal.Body>
            </Modal>
            <Modal 
                size="xs"
                open={editorVisible} 
                onClose={() => setEditorVisible(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Map/EditConfig')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConfigurationForm
                        fluid
                        configuration={{
                            name: configuration?.name
                        }}
                        onSubmit={value => {
                            setEditorVisible(false);
                            onParamChange(value);
                        }}
                        onCancel={() => setEditorVisible(false)} />
                </Modal.Body>
            </Modal>
        </List.Item>
    );
};


export default ConfigurationSettings;
