import "assets/css/input.css";

import React from 'react';
import { Button, ButtonToolbar, Form, Message, Popover, Whisper } from 'rsuite';
import { t } from 'i18next';
import { noop } from 'utils/constants';


const FormInput = React.forwardRef(({
    children,
    value,
    renderValue,
    autocomplete: autoComplete = false,
    cleanable = false,
    popoverWidth = 240,
    onSubmit = noop,
    onClear = noop,
    validationErrors = [],
    placement = 'bottomStart',
    submitLabel = t('ApplyFilter'),
    clearLabel = t('ClearFilter'),
    footer,
    ...props
}, ref) => {

    const triggerRef = React.useRef();
    const [isOpen, setIsOpen] = React.useState(false);

    function open () {
        setIsOpen(true);
        triggerRef.current.open();
    };

    function close () {
        setIsOpen(false);
        triggerRef.current.close();
    };

    function toggle() {
        isOpen ? close() : open()
    };

    const speaker = (props, ref) => {
        const { title, className, style } = props;
        return (
            <Popover ref={ref} title={title} className={`formInputPopover ${className}`} style={{...style, width: popoverWidth}}>
                <Form autocomplete={autoComplete ? 'on' : 'off'}>
                    {children}

                    { validationErrors.map( (e, i) => 
                        <Message 
                            key={i}
                            className="validationError" 
                            type="error"
                        >
                            {e}
                        </Message>
                    ) }

                    <Form.Group className="submitButtons">
                        <ButtonToolbar>
                            <Button 
                                appearance="primary"
                                block 
                                disabled={validationErrors.length ? true : false}
                                title={validationErrors[0]}
                                onClick={() => {
                                    onSubmit();
                                    close();
                                }}
                            >
                                { submitLabel }
                            </Button>
                            { cleanable && <>
                                <Button 
                                    appearance="ghost" 
                                    color="red" 
                                    block 
                                    onClick={() => {
                                        onClear();
                                        close();
                                    }}
                                >
                                    { clearLabel }
                                </Button>
                            </>}
                            <Button appearance="subtle" block onClick={close}>
                                {t('Cancel')}
                            </Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
                { footer ? <div className='footer'>
                    { footer }
                </div>: <></>}
            </Popover>
        )
    }

    return <>
        <Whisper
            ref={triggerRef}
            trigger="none"
            placement={placement}
            speaker={speaker}
        >
            <Button
                className={`formInput rs-picker rs-picker-select rs-picker-default rs-picker-toggle-wrapper rs-picker-cleanable${value ? ' selected' : ''}${props?.disabled ? ' rs-picker-disabled' : ''}`}
                {...props}
                ref={ref}
                onClick={toggle}
            >
                {typeof renderValue === 'function' ? renderValue(value) || t('NotSelected') : (value || t('NotSelected'))}
            </Button>
        </Whisper>
    </>
});


export default FormInput;
