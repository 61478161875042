export function zfill(num, maxLength) {
    return num.toString().padStart(maxLength, '0');
};


export function formatDate(value, {
    dateSeparator='-',
    timeSeparator=':',
    dateTimeSeparator=' ',
    date=true, 
    time=false, 
    seconds=false, 
    milliseconds=false
} = {}) {
    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const day = value.getDate();

    const h = value.getHours();
    const m = value.getMinutes();
    const s = value.getSeconds();
    const ms = value.getMilliseconds()

    const dateStr = zfill(year, 4) + dateSeparator + 
        zfill(month, 2) + dateSeparator + 
        zfill(day, 2);

    if (!time) {
        return dateStr
    }

    let timeStr = zfill(h, 2) + timeSeparator +
        zfill(m, 2);

    if (seconds) {
        timeStr += timeSeparator + zfill(s, 2);
    }

    if (milliseconds) {
        timeStr += '.' + zfill(ms, 3)
    }

    if (date) {
        return dateStr + dateTimeSeparator + timeStr;
    } else {
        return timeStr;
    }

}


export function formatString (str, context = {}) {
    Object.entries(context).forEach(([attr, value]) => {
        var rgx = new RegExp('\\${\s?' + attr + '\s?}','g');
        str = str.replace(rgx, value);
    })
    return str;
};


export function numStringSuffix(text) {
    if(!text) {
        return ''
    }
    if (text.endsWith('000'))
        return '-es';
    if (text.endsWith('00'))
        return '-as';
    if (text.endsWith('0'))
        switch  (text.substr(text.length - 2)) {
            case '10':
                return '-es'
            case '20':
                return '-as'
            case '30':
                return '-as'
            case '40':
                return '-es'
            case '50':
                return '-es'
            case '60':
                return '-as'
            case '70':
                return '-es'
            case '80':
                return '-as'
            case '90':
                return '-es'
    }
    switch (text.substr(text.length - 1)) {
        case '1':
            return '-es'
        case '2':
            return '-es'
        case '3':
            return '-as'
        case '4':
            return '-es'
        case '5':
            return '-ös'
        case '6':
            return '-os'
        case '7':
            return '-es'
        case '8':
            return '-as'
        case '9':
            return '-es'
        default:
            return ''
    }
}