import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { Message, Nav, toaster } from "rsuite";
import { t } from "i18next";

const RequireAuth = ({ children }) => {
    const { user, isAnonymous } = React.useContext(AuthContext);
    const navigate = useNavigate();
    React.useEffect(() => {

        if (isAnonymous) {
        
            toaster.push(<Message showIcon type="error" >
                {t(`Security/AuthRequired`)}
            </Message>, {duration: 6000})

            const path = encodeURI(window.location.pathname)
    
            navigate(path === '/login' ? '/login' : `/login?to=${path}`);
        }
    
    }, [user])

    return  children;
};

export default RequireAuth;