import React from 'react'
import StationCard from './StationCard'
import SegmentPartCard from './SegmentPartCard'
import SegmentPart from 'features/crossSectionRoadCount/elements/SegmentPart'
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext'


const ElementCard = ({
    item,
    ...props
}) => {
    const module = React.useContext(CrossSectionRoadCountContext);

    switch( item?.elementType ) {

        case 'station':

            const featureData = {
                code: item?.stationCode,
                roadName: item?.roadName,
                roadCategory: item?.roadCategory,
                startSection: item?.startSection,
                endSection:item?.endSection
            }

            const stationData = Object.values(module?.elements?.selectedStations || {}).find(s => s?.code === item?.elementId);

            return <StationCard {...featureData} {...stationData} {...props} />
        case 'segmentPart':
            return <SegmentPartCard {...SegmentPart(item)} {...props} />
        default:
            return `${item?.elementType}-${item?.elementId}`
    }
}

export default ElementCard