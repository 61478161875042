import Color from "utils/color";

export const onboardCountColors = {
    black: Color([0, 0, 0]),
    white: Color([255, 255, 255]),
    transparent: Color([0, 0, 0, 0.0]),
    alight: Color('#2020ff'),
    board: Color('#ff4020'),
    onward: Color('#d0d068'),
    onwardStart: Color('#80c080'),
    onwardEnd: Color('#ff0000'),
    stopPlace: Color('#1675e0'),
    primary: Color('#1675e0'),
    secondary: Color('#f7f7fa'),
    area: Color([160, 175, 200]),
}