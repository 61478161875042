import React from 'react'
import { logEvent } from 'features/logging';

export default function useFilters() {

    const [filters, setFilters] = React.useState({});

    function applyFilters(values = {}) {
        console.log(values)
        logEvent('applyFilters', values)
        setFilters(prev => ({
            ...prev,
            ...values,
        }));
    };

    return {
        filters,
        applyFilters
    };
};
