import 'assets/css/webmapModule.css';

import React from 'react';
import { Message } from 'rsuite';


const ModuleStatus = ({ children }) => {
  return (
    <Message className="moduleStatus" type="info" closable>
        { children }
    </Message>
  )
}


export default ModuleStatus;
