import 'assets/css/button.css';

import React from "react";
import { Button, IconButton } from "rsuite";


export const LabeledIconButton = ({
    label,
    showLabel,
    icon,
    ...props
}) => {
    return showLabel ? (
        <Button className='labeledIconButton' {...props} >
            <div className='content'>
                { icon }
                { label }
            </div>
        </Button>
    ) : (
        <IconButton icon={icon} title={label} {...props} />
    )
}