import React from "react";
import { useMediaQuery } from "rsuite";


const MediaQueryContext = React.createContext({
    isMobile: false,
    isDark: false,
    isLandscape: true,
});


export const MediaContextProvider = ({
    children
}) => {
    const [isMobile, isDark, isLandscape] = useMediaQuery([
        '(max-width: 700px)',
        '(prefers-color-scheme: dark)',
        '(orientation:landscape)'
    ]);

    return (
        <MediaQueryContext.Provider value={{ isMobile, isDark, isLandscape }}>
            { children }
        </MediaQueryContext.Provider>
    );
};


export default MediaQueryContext;