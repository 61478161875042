import { Icon } from '@rsuite/icons';
import { faAngleLeft, faAngleRight, faArrowDown, faArrowRight, faArrowUp, faAt, faBezierCurve, faCalendar, faClock, faClose, faCogs, faEdit, faEnvelope, faExternalLinkAlt, faFileAlt, faFilter, faHome, faInfoCircle, faLink, faMapMarkerAlt, faPause, faPhone, faPlus, faProjectDiagram, faSave, faSignOut, faTachometerAltFast, faTools, faTrash, faTrashAlt, faUser, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FaIcon = ({ icon,  ...props }) => {
    return (
        <Icon as={FontAwesomeIcon} icon={icon} {...props} />
    );
};

export const ESRIIcon = ({name, withLabel, ...props}) => <i 
    {...(withLabel ? {style: {marginRight: 4}} : {})} 
    {...props} 
    className={`esri-icon-${name}`} />

export default FaIcon;

export const UserIcon = ({ ...props }) => <FaIcon icon={faUser} {...props} />

export const CloseIcon = ({ ...props }) => <FaIcon icon={faClose} {...props} />

export const AngleLeft = ({ ...props }) => <FaIcon icon={faAngleLeft} {...props} />

export const AngleRight = ({ ...props }) => <FaIcon icon={faAngleRight} {...props} />

export const ArrowDownIcon = ({ ...props }) => <FaIcon icon={faArrowDown} {...props} />

export const ArrowUpIcon = ({ ...props }) => <FaIcon icon={faArrowUp} {...props} />

export const ArrowRightIcon = ({ ...props }) => <FaIcon icon={faArrowRight} {...props} />

export const ResetIcon =({ ...props }) => <ESRIIcon name="undo" {...props} />

export const InfoIcon = ({ ...props }) => <FaIcon icon={faInfoCircle} {...props} />

export const StartTimeIcon = ({ ...props }) => <FaIcon icon={faClock} {...props} />

export const StopIcon = ({ ...props }) => <FaIcon icon={faPause} {...props} />

export const StopPlaceIcon = ({ ...props }) => <FaIcon icon={faMapMarkerAlt} {...props} />

export const AreaIcon = ({ ...props }) => <ESRIIcon name="lasso" {...props} />

export const ServicePatternIcon = ({ ...props }) => <FaIcon icon={faProjectDiagram} {...props} />

export const ServicePatternGroupIcon = ({ ...props }) => <FaIcon icon={faBezierCurve} {...props} />

export const NetworkPerformanceIcon = ({ ...props }) => <FaIcon icon={faTachometerAltFast} {...props} />

export const ArrivalIcon = ({ ...props }) => <ESRIIcon name="overview-arrow-bottom-right" {...props}/>

export const DepartureIcon = ({ ...props }) => <ESRIIcon name="overview-arrow-top-right" {...props} />

export const AlightIcon = ({ ...props }) => <ArrowDownIcon {...props} />

export const BoardIcon = ({ ...props }) => <ArrowUpIcon {...props} />

export const OnwardIcon = ({ ...props }) => <ArrowRightIcon {...props} />

export const FilterIcon = ({ ...props }) => <ESRIIcon name="filter" {...props} />

export const MapIcon = ({ ...props }) => <ESRIIcon name="maps" {...props} />

export const TableIcon =({ ...props }) =>  <ESRIIcon name="table" {...props} />

export const BarChartIcon =({ ...props }) =>  <ESRIIcon name="chart" {...props} />

export const PieChartIcon =({ ...props }) =>  <ESRIIcon name="pie-chart" {...props} />

export const CalendarIcon =({ ...props }) => <FaIcon icon={faCalendar} {...props} />

export const SaveIcon = ({ ...props }) => <FaIcon icon={faSave} {...props} />

export const EditIcon = ({ ...props }) => <FaIcon icon={faEdit} {...props} />

export const DeleteIcon = ({ ...props }) => <FaIcon icon={faTrashAlt} {...props} />

export const PlusIcon = ({ ...props }) => <FaIcon icon={faPlus} {...props} />

export const AdminIcon = ({ ...props }) => <FaIcon icon={faUserCog} {...props} />

export const BlogIcon = ({ ...props }) => <FaIcon icon={faCogs} {...props} />

export const LogoutIcon = ({ ...props }) => <FaIcon icon={faSignOut} {...props} />

export const SubscriptionIcon = ({ ...props }) => <FaIcon icon={faFileAlt} {...props} />

export const EmailIcon = ({ ...props }) => <FaIcon icon={faAt} {...props} />

export const HomeIcon = ({ ...props }) => <FaIcon icon={faHome} {...props} />

export const AddressIcon = ({ ...props }) => <FaIcon icon={faEnvelope} {...props} />

export const PhoneIcon = ({ ...props }) => <FaIcon icon={faPhone} {...props} />

export const HelpIcon = ({ ...props }) => <ESRIIcon name="question" {...props} />

export const BorderCrossingIcon = ({ ...props }) => <ESRIIcon name="minus-circled" {...props} />

export const RoadSectionIcon = ({ ...props }) => <ESRIIcon name="polyline" {...props} />

export const LinkIcon = ({ ...props }) => <FaIcon icon={faExternalLinkAlt} {...props} />

export const MaintenanceIcon = ({ ...props }) => <FaIcon icon={faTools} {...props} />
