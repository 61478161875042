import React from "react";
import VehicleClass from "../elements/VehicleClass";
import DayType from "../elements/DayType";
import { getMonthlyStationCounts, getStations, getYearlyStationCounts } from "../crossSectionRoadCountAPI";
import Station from "../elements/Station";
import { useModule } from "features/webmapModule";
import { noop } from "utils/constants";
import { AADT_VIEW, BORDER_CROSSING_LAYER, MADT_VIEW, ROADS_VIEW, ROAD_SECTION_LAYER, STATION_TRAFFIC, TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH, VEHICLE_CLASS_CONVERSIONS, YEARLY_TRAFFIC_BY_YEAR } from "../utils/constants";
import useCrossSectionRoadCountJoins from "./useCrossSectionRoadCountJoins";
import SegmentPart from "../elements/SegmentPart";
import ElementCard from "../compononets/cards/ElementCard";
import useCrossSectionRoadCountMenu from "./useCrossSectionRoadCountMenu";
import { t } from "i18next";
import { BorderCrossingIcon, RoadSectionIcon } from "components/icons";


const ELEMENT_TYPE_ORDER = [
    'station',
    'segmentPart'
];

function processAPIResponse(Element) {
    return ({data, responseTime}) => {
        if (Array.isArray(data)){
            const elements = {};
            data.forEach(row => {
                elements[row?.id] = Element(row);
            })
            return {
                rows: Object.values(elements).sortBy(),
                responseTime
            };
        } else {
            return {
                rows: [data],
                responseTime
            }
        }
    }
};

function useCrossSectionRoadCount({
    webmap,
    arcGISMap,
    setJoins = noop,
}) {
    const {
        id,
        elements,
        dayTypes: defaultDayTypes,
        vehicleClasses: defaultVehicleClasses,
        firstYear,
        lastYear,
    } = webmap?.modules?.crossSectionRoadCount || {};

    async function getDayTypes () {
        return {
            rows: Object.values(defaultDayTypes || {})
                .map(DayType)
                .sortBy(),
            responseTime: 0,
        }
    }

    async function getVehicleClasses () {
        return {
            rows: Object.values(defaultVehicleClasses || {})
                .map(VehicleClass)
                .sortBy(),
            responseTime: 0,
        }
    }

    const queryFunctions = {
        dayTypes: getDayTypes,

        vehicleClasses: getVehicleClasses,

        stations: (filters, signal) => getStations( id, {
            year: filters.year,
            month: filters.month,
            dayTypeId: filters.dayTypeId,
            direction: filters.direction,
            vehicleClassCodes: expandVehicleClassCodes(filters.vehicleClassCodes)
        }, signal )
            .then(processAPIResponse(Station)),

        selectedStations: (filters, signal) => getStations( id, {
            year: filters.stationYear,
            month: filters?.stationIntervalType === MADT_VIEW ? filters.stationMonth : undefined,
            dayTypeId: filters?.stationIntervalType === MADT_VIEW ? filters.stationDayTypeId : undefined,
            stationCode: filters.stationCode,
        }, signal )
            .then(processAPIResponse(Station)),

        yearlyStationCounts: (filters, signal) => getYearlyStationCounts( id, filters.stationCode, signal)
            .then(processAPIResponse(Station)),

        monthlyStationCounts: (filters, signal) => getMonthlyStationCounts( id, filters.stationCode, {
            month: filters?.stationIntervalType === MADT_VIEW ? filters.stationMonth : undefined,
            dayTypeId: filters?.stationIntervalType === MADT_VIEW ? filters.stationDayTypeId : undefined,
        }, signal)
            .then(processAPIResponse(Station)),

        monthlyStationCountsForYear: (filters, signal) => getMonthlyStationCounts( id, filters.stationCode, {
            year: filters?.stationYear,
        }, signal)
            .then(processAPIResponse(Station)),
        
    };


    function getExportName (filters, elements) {
        const {
            stationCode
        } = filters || {};

        const selectedStation = Object.values(elements?.selectedStations || {})[0];
        let exportName = '' + 
            `${selectedStation?.roadName} ` +
            `${selectedStation?.startSection} -  ` +
            `${selectedStation?.endSection} ` +
            `(${stationCode})`

        return exportName
    }

    const module = useModule({
        moduleName: 'crossSectionRoadCount',
        tutorialSteps: 4,
        map: arcGISMap,
        webmap,
        moduleParameters: {
            id,
            elements,
            firstYear,
            lastYear,
        },
        menuHook: useCrossSectionRoadCountMenu,
        joinsHook: useCrossSectionRoadCountJoins,
        setJoins,
        elementTypeOrder: ELEMENT_TYPE_ORDER,
        queryFunctions,
        getExportName,
    });

    const {
        selection = [],
        selectionIndex,
    } = arcGISMap || {};

    const {
        dayTypes = {},
        vehicleClasses = {},
        selectedStations = {},
    } = module?.elements;
        
    function getYearlyVehicleClasses(year) {
        return Object.fromEntries(Object.entries(vehicleClasses).filter(([k, v]) => v?.years?.includes(year)))
    }

    function expandVehicleClassCodes(codes) {
        if (!codes) {
            return
        }
        const result = [];
        codes.forEach(code => {
            const expandedCodes = VEHICLE_CLASS_CONVERSIONS?.[code] || [code];
            result.push(...expandedCodes)
        })
        return result
    }

    const {
        viewType,
        year,
        month,
        dayTypeId,
        stationCode,
        stationIntervalType,
        stationYear,
        stationMonth,
        stationDayTypeId,
        vehicleClassCodes = [],
        roadCategoryCodes,
        visibleLayers = [],
        selectedSection,
        settlement,
    } = module?.filters;

    const selectedFeature = selection?.[selectionIndex]?.attributes;
    const selectedSegmentPart = selectedFeature?.elementType === 'segmentPart' ? SegmentPart(selectedFeature) : undefined;
    const selectedStationId = Object.values(selectedStations)[0]?.id;
    const selectedStation = selectedStationId 
        ? Station({
            ...selectedStations[selectedStationId],
            yearlyTrafficByYear: module.elements?.yearlyStationCounts?.[selectedStationId]?.traffic,
            monthlyTrafficByYear: module.elements?.monthlyStationCounts?.[selectedStationId]?.traffic,
            monthlyTraffic: module.elements?.monthlyStationCountsForYear?.[selectedStationId]?.traffic,
            vehicleClasses,
        })
        : undefined

    const selectedElements = {
        feature: selectedFeature,
        station: selectedStation,
        segmentPart: selectedSegmentPart,
        dayType: dayTypes?.[dayTypeId],
        vehicleClasses: vehicleClassCodes.map(code => vehicleClasses?.[code]),
    };

    [BORDER_CROSSING_LAYER, ROAD_SECTION_LAYER].forEach(layerName => {    
        if (arcGISMap?.joinLayers?.[layerName]) {
            arcGISMap.joinLayers[layerName].visible = visibleLayers.includes(layerName)
        }
    })

    const mapToolbarButtons = [{
        label: t('OKKF/BorderCrossings'),
        icon: <BorderCrossingIcon />,
        appearance: visibleLayers.includes(BORDER_CROSSING_LAYER) ? 'primary' : 'default',
        onClick: () => module.applyFilters({ 
            visibleLayers: visibleLayers.includes(BORDER_CROSSING_LAYER) 
                ? visibleLayers.filter(name => name !== BORDER_CROSSING_LAYER) 
                : [...visibleLayers, BORDER_CROSSING_LAYER]}),
    }, {
        label: t('OKKF/RoadSections'),
        icon: <RoadSectionIcon />,
        appearance: visibleLayers.includes(ROAD_SECTION_LAYER) ? 'primary' : 'default',
        onClick: () => module.applyFilters({ 
            visibleLayers: visibleLayers.includes(ROAD_SECTION_LAYER) 
                ? visibleLayers.filter(name => name !== ROAD_SECTION_LAYER) 
                : [...visibleLayers, ROAD_SECTION_LAYER]}),
    }]

    React.useEffect(() => {
        if (!id) return;
        // Update dayTypes and vehicleClasses

        module.updateElements('dayTypes', 'vehicleClasses')
        module.applyFilters({
            viewType: ROADS_VIEW,
            year: lastYear,
            stationIntervalType: AADT_VIEW
        })
    }, [id]);

    React.useEffect(() => {
        if (!id || !year) return;
        // Update stations on filter change

        if (viewType === ROADS_VIEW) {
            module.clearElements('stations')
        } else {
            module.updateElements('stations')
        }
    }, [year, month, dayTypeId, `${vehicleClassCodes}`, `${roadCategoryCodes}`, viewType]);

    React.useEffect(() => {
        if (!id) return;
        switch (viewType) {
            case AADT_VIEW:
                module.applyFilters({
                    year: year || lastYear,
                    month: undefined,
                    dayTypeId: undefined,
                    stationIntervalType: AADT_VIEW,
                });
                break;
            case MADT_VIEW:
                module.applyFilters({
                    year: year || lastYear,
                    month: month || stationMonth || 1,
                    dayTypeId: dayTypeId || stationDayTypeId || 1,
                    stationIntervalType: MADT_VIEW,
                });
                break;
            default:
                module.applyFilters({
                    year: year || lastYear,
                    month: undefined,
                    dayTypeId: undefined,
                });
        }
    }, [viewType])

    React.useEffect(() => {
        if (!id) return;
        switch (stationIntervalType) {
            case AADT_VIEW:
                module.applyFilters({
                    stationMonth: undefined,
                    stationDayTypeId: undefined,
                });
                break;
            case MADT_VIEW:
                module.applyFilters({
                    stationMonth: stationMonth || month || 1,
                    stationDayTypeId: stationDayTypeId || dayTypeId || 1,
                });
                break;
            default:
                break;
        }
    }, [stationIntervalType])

    React.useEffect(() => {
        if (!id) return;
        
        // Update selected station filters on filter change
        
        module.applyFilters({
            stationYear: year,
            stationMonth: month,
            stationDayTypeId: dayTypeId,
        });
    }, [year, month, dayTypeId]);

    React.useEffect(() => {
        if (!id) return;
        
        // Update selected station data
        
        console.log(`Station code changed to ${stationCode}`)
        if (!stationCode) {
            module.clearElements('selectedStations');
            module.applyFilters({
                stationYear: year,
                stationMonth: month,
                stationDayTypeId: dayTypeId,
            });
        } else {
            module.updateElements(
                'selectedStations', 
                'yearlyStationCounts',
                'monthlyStationCountsForYear',
            );
            if (stationMonth) {
                module.updateElements(    
                    'monthlyStationCounts',
                )
            } else {
                if ([TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH].includes(module.tableMenu.values[1])) {
                    module.tableMenu.setValues([STATION_TRAFFIC, YEARLY_TRAFFIC_BY_YEAR])
                }
            }
        }
    }, [stationCode, stationYear, stationMonth, stationDayTypeId]);

    React.useEffect(() => {
        if (!id || !arcGISMap) return;

        if (stationCode) {
            arcGISMap.selectFeature({
                layer: 'segment',
                stationCode,
                zoomTo: false,
                panTo: settlement ? false : true
            })
            if (settlement) {
                module.applyFilters({
                    settlement: undefined
                })
            }
        } else {
            arcGISMap.selectFeature();
        }
    }, [stationCode])

    React.useEffect(() => {
        if (!id) return;

        if (selectedSection) {
            const {section, roadName} = selectedSection;

            const [km, ] = section.split('+')

            arcGISMap.selectFeature({
                layer: 'segment',
                where: `roadName='${roadName}' AND startKm <= ${km} AND endKm >= ${km}`,
                zoomTo: true,
            })
        }

    }, [`${selectedSection?.roadName}-${selectedSection?.section}`])

    React.useEffect(() => {
        if (!id) return;

        if (settlement) {
            // arcGISMap.selectIntersectingFeatures({
            //     layer: 'segment',
            //     baseLayer: 'settlement',
            //     where: `Name = '${settlement}'`,
            //     orderByFields: ['roadCategory', 'roadName'],
            //     panTo: false,
            //     zoomTo: false
            // })

            arcGISMap.selectFeature({
                layer: 'settlement',
                where: `Name = '${settlement}'`
            })
        }
    }, [settlement])


    React.useEffect(() => {
        if (!id) return;

        switch (selectedFeature?.elementType) {
            case 'station':
                if (selectedFeature?.elementId !== stationCode) {
                    module.applyFilters({
                        stationCode: selectedFeature?.elementId
                    })
                }
                break;
            default:
                module.applyFilters({
                    stationCode: undefined
                })
        }
    }, [selectedFeature])

    if (!id) {
        return
    }

    return {
        ...module,
        countId: id,
        selectedElements,
        mapToolbarButtons,
        getYearlyVehicleClasses,
        getLabel: item => <ElementCard item={item} size='xs' />,
    }
}

export default useCrossSectionRoadCount;