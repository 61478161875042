import React from 'react'
import { Button, Form, Toggle } from 'rsuite'
import { t } from 'i18next'

import { FormGroup } from 'components/forms'
import WebmapContext from '../context/WebmapContext'
import { OnboardCountContext } from 'features/onboardCount'
import OnboardCountViewPreferencesFormFields from 'features/onboardCount/components/forms/OnboardCountViewPreferencesForm'
import { getElementCacheID } from '../utils/element'
import localforage from 'localforage'

const ViewPreferences = () => {
        
    const {
        webmap
    } = React.useContext(WebmapContext);

    const onboardCount = React.useContext(OnboardCountContext);

    const {
        labelToolbarButtons = true
    } = webmap?.userConfig || {};


    function clearCache() {
        console.log('Clearing webmap cache...')
        webmap?.elements?.forEach(element => {
            const cacheID = getElementCacheID(element);
            console.log(`Clearing cache for ${cacheID}`);
            localforage.removeItem(cacheID);
        })
    }

    return (
        <Form layout='horizontal' className='wide' >
                
            <FormGroup
                name="labelToolbarButtons"
                label={t('Map/LabelToolbarButtons')}
                labelWidth={300}
                accepter={Toggle}
                value={labelToolbarButtons}
                onChange={value => webmap?.updateUserConfig({labelToolbarButtons: value})}
            />

            { onboardCount?.id && (
                <OnboardCountViewPreferencesFormFields labelWidth={300} />
            )}
            <div style={{textAlign: 'right'}}>
                <Button onClick={() => clearCache()}>
                    { t('Map/ClearCache') }
                </Button>
            </div>


        </Form>
    )
}

export default ViewPreferences