import { getAreaJoins } from "./areaJoins";
import { getSectionJoins } from "./sectionJoins";
import { getStopPlaceJoins } from "./stopPlaceJoins";

function getJoins({
    stopPlaceElementId, 
    sectionElementId,
    elements,
    minTrafficSize = 5,
    maxTrafficSize = 100,
    labelStopPlaces = true,
    labelSections = true,
}) {
    
    const {
        areas = {},
        stopPlaces = {},
        sections = {},
    } = elements;

    return {
        [sectionElementId]: getSectionJoins(Object.values(sections), {
            labelSections,
        }),
        [stopPlaceElementId]: [
            ...getStopPlaceJoins(Object.values(stopPlaces), {
                labelStopPlaces,
            }),
            ...getAreaJoins(Object.values(areas), {
                minSize: minTrafficSize,
                maxSize: maxTrafficSize,
                labelStopPlaces,
            }),
        ],
    }
};


export default getJoins;
