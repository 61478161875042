import { postAPI } from "utils/api";
import { LOG_LEVELS } from "./utils";


export const logEvent = (event, data, level="debug") => postAPI('log', {
    level: LOG_LEVELS?.[level?.toLowerCase()],
    path: window.location.pathname,
    parameters: window.location.search,
    event,
    data,
    timestamp: new Date().toISOString(),
})
