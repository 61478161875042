import React from "react";
import { IconButton, Panel } from "rsuite";

import { PanelHeader } from "components/panels";

import usePagination from "../hooks/usePagination";
import PaginationNav from "./PaginationNav";
import PageSizeForm from "./forms/PageSizeForm";
import { PlusIcon } from "components/icons";
import { t } from "i18next";


const PaginatedPanel = ({ 
    title,
    icon,
    onCreate,
    getItems,
    renderItems,
    defaultPage = 1,
    defaultPageSize = 10,
    pageSizeOptions,
    createModalClass,
    createValues,
    ...props 
}) => {
    const pagination = usePagination( getItems, defaultPage, defaultPageSize );
    const [createModalVisible, setCreateModalVisible] = React.useState(false);

    const CreateModal = createModalClass;

    return <>
        <Panel 
            className="paginatedPanel"
            bordered 
            shaded 
            header={ <PanelHeader 
                title={<>
                    {title}
                    { createModalClass && (
                        <IconButton 
                            style={{marginLeft: 10}}
                            onClick={() => setCreateModalVisible(true)} 
                            icon={<PlusIcon />} 
                            size="xs" 
                            appearance="primary" 
                            color="green"
                            title={t('CreateOption')}
                        />
                    )}
                </> }
                icon={icon}
                toolbar={<PageSizeForm 
                    pageSize={pagination.pageSize}
                    setPageSize={pagination.setPageSize}
                    options={pageSizeOptions}
                />}
            />}
            {...props}
        >

            { renderItems( pagination ) }            

            <div className="paginationContainer" >
                <PaginationNav
                    page={pagination.page}
                    setPage={pagination.setPage}
                    pageSize={pagination.pageSize}
                    numberOfPages={pagination.numberOfPages} 
                />
            </div>

        </Panel>
        { createModalClass && <CreateModal open={createModalVisible} setOpen={setCreateModalVisible} item={createValues} />}
        
    </>;
};


export default PaginatedPanel;
