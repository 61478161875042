import 'assets/css/onboardCount.css'

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import CountInterval, { getAvailableCountIntervalIds } from 'features/onboardCount/elements/CountInterval';
import React from 'react'
import { Slider } from 'rsuite';

const CountIntervalSlider = () => {

    const count = React.useContext(OnboardCountContext);
    const {
        elements: {
            countIntervals = {},
            dayTypes = {},
        },
        filters = {},
    } = count;


    const intervals = getAvailableCountIntervalIds(countIntervals, filters);
    const currentIndex = intervals.indexOf(count?.filters?.countInterval);
    
    const [intervalIndex, setIntervalIndex] = React.useState(currentIndex);


    const currentId = intervals[intervalIndex];
    const currentInterval = CountInterval(currentId);
    const currentDayType = dayTypes?.[currentInterval?.dayTypeId]?.name;

    React.useEffect(() => {
        if (currentIndex !== intervalIndex) {
            setIntervalIndex(currentIndex);
        }
    }, [currentIndex])

    if ((intervals?.length || 0) < 2) {
        return
    }

    return <div className="countIntervalSlider">
        <div className="marker">
            { CountInterval(intervals[0]).label }
        </div>
        <Slider
            value={intervalIndex}
            onChange={setIntervalIndex}
            onChangeCommitted={index => {
                count.applyFilters({
                    countInterval: intervals[index]
                })
            }}
            min={0}
            max={intervals.length-1}
            graduated
            progress
            tooltip={false}
            handleTitle={!currentId ? undefined : <span style={{whiteSpace: 'nowrap'}}>
                {currentInterval.label } ({ currentDayType })
            </span>}
        />
        <div className="marker">
            { CountInterval(intervals[intervals.length - 1]).label }
        </div>
    </div>
}

export default CountIntervalSlider