import React from 'react'
import { Table } from 'rsuite';
import { t } from 'i18next';

import { noop } from 'utils/constants';

import RowHeaderCell from './cells/RowHeaderCell';
import TypeFormattedCell from './cells/TypeFormattedCell';
import HeaderCell from './cells/HeaderCell';
import ColumnSelectorForm from './forms/ColumnSelectorForm';


const DataTable = React.forwardRef(({
    data = [],
    dataAttribute,
    columnTypes = [],
    defaultVisibleColumns = {},
    visibleColumns,
    title,
    exportName,
    
    renderRowHeader = noop,
    rowHeaderKey,
    csvExtraColumns = [],
    sorted = true,
    
    maxRows,
    rowHeight,
    headerHeight = 40,
    headerWidth,
    columnWidth,
    minColumnWidth,

    totalTop,
    totalBottom,

    fontSize = 12,

    getChildren = noop,
    rowKey='rowKey',
    getRowKey = ({ elementType, elementId }) => `${elementType}-${elementId}`,

    multiselect,
    selectedRows = [],
    onSelectRow = noop,

    selectedColumns = [],
    onSelectColumn = noop,

    defaultExpandedRowKeys = [],
    CellClass = TypeFormattedCell,

    ...props
}, ref) => {
    
    const tableRef = React.useRef();

    const [sortColumn, setSortColumn] = React.useState(rowHeaderKey);
    const [sortType, setSortType] = React.useState('asc');
    const [visibleCols, setVisibleCols] = React.useState(defaultVisibleColumns);

    const [expandedRowKeys, setExpandedRowKeys] = React.useState(defaultExpandedRowKeys);
    function onExpandChange (expanded, rowData) {
        const rowKey = getRowKey(rowData)
        if (!expanded && expandedRowKeys.includes(rowKey)) {
            setExpandedRowKeys(expandedRowKeys.filter(key => key !== rowKey));
        } else if (expanded && !expandedRowKeys.includes(rowKey)) {
            setExpandedRowKeys([...expandedRowKeys, rowKey]);
        }
    }

    React.useEffect(() => {
        if (visibleColumns) {
            setVisibleCols(visibleColumns);
        }
    }, [visibleColumns]);


    const subColumns = columnTypes.slice(1).reduce((prev, columnType) => prev * filterColumnTypeValues(columnType).values.length, 1);
    const colWidth = Math.max(minColumnWidth || 0, (columnWidth / subColumns) || 0) || undefined;
    const subHeaders = Math.max(columnTypes.filter(columnType => columnType?.values?.length > 1).length, 1);

    const canSetVisibleColumns = columnTypes.some(columnType => columnType.hideable);

    function handleSort(column, type) {
        setSortColumn(column);
        setSortType(column !== sortColumn ? 'asc' : type)
    };

    function isRowSelected(row, parentSelected = false) {
        const rowKey = getRowKey(row);
        const selected = selectedRows.includes(rowKey);

        const children = getChildren(row) || [];
        const allChildrenSelected = children.length > 1 && children.every(child => isRowSelected(child, false));

        return parentSelected || selected || allChildrenSelected;
    };

    function flattenAttributes(row, attributeList) {

        return Object.fromEntries(attributeList.map(attrs => {
            const key = attrs.join('-');
            let value = row;
            attrs.forEach(attr => {
                value = value?.[attr]
            })
            return [key, value]
        }))
    };

    function getAttributeList(row) {
        let attributeList = [dataAttribute ? [dataAttribute] : []];
        columnTypes.forEach((columnType, i) => {
            const newAttributeList = []
            let values = [...columnType.values];
            
            if (columnType?.total) { 
                values.push({key: 'total'});

                if (row) {
                    let prevAttributes = [dataAttribute ? [dataAttribute] : []];
                    const currentAttributes = columnType?.values.map(({key}) => key)
                    let nextAttributes = [];
                    columnTypes.forEach((columnType, j) => {
                        let values = [...columnType.values];
                        if (j < i) {
                            const newPrevAttributes = [];
        
                            values.forEach(({ key }) => {
                                if (prevAttributes.length) {
                                    prevAttributes.forEach(attr =>
                                        newPrevAttributes.push([...attr, key])
                                    )
                                } else {
                                    newPrevAttributes.push([key]);
                                }
                            }
                            );
                            prevAttributes = newPrevAttributes;
                        } 
                        else if (j > i) {
                            const newNextAttributes = [];
                            values.forEach(({ key }) => {
                                if (nextAttributes.length) {
                                    nextAttributes.forEach(attr =>
                                        newNextAttributes.push([...attr, key])
                                    )
                                } else {
                                    newNextAttributes.push([key]);
                                }
                            });
                            nextAttributes = newNextAttributes;
                        }
                    });
                    prevAttributes.forEach(attrs => {
                        let container = row;
                        attrs.forEach(attr => {
                            container = container?.[attr]
                        })

                        container.total = {}

                        currentAttributes.forEach(attr => {

                            nextAttributes.forEach(nextAttrs => {
                                let sourceItem = container[attr];
                                let destinationItem = container.total;
                                nextAttrs.forEach(nextAttr => {
                                    sourceItem = sourceItem?.[nextAttr]
                                    if (destinationItem?.[nextAttr] === undefined) {
                                        switch (typeof( sourceItem )) {
                                            case 'object':
                                                destinationItem[nextAttr] = {};
                                                break;
                                            case 'number':
                                                destinationItem[nextAttr] = 0;
                                                break;
                                            case 'string':
                                                destinationItem[nextAttr] = '';
                                                break;
                                            case 'undefined': 
                                                destinationItem = {};
                                                return
                                        }
                                    };

                                    switch (typeof( sourceItem )) {
                                        case 'number':
                                            destinationItem[nextAttr] += sourceItem;
                                            break;
                                        case 'string':
                                            break;
                                        default:
                                            destinationItem = destinationItem[nextAttr]
                                    }
                                })
                            })
                        })
                    })

                }
            }

            values.forEach(({ key }) => {
                if (attributeList.length) {
                    attributeList.forEach(attr =>
                        newAttributeList.push([...attr, key])
                    )
                } else {
                    attributeList.push([key]);
                }
            });
            attributeList = newAttributeList;
        });

        return attributeList
    }

    function getTableRows(rows = data, parent) {

        const totalsRow = {
            isTotal: true,
            disabled: true,
            ...flattenAttributes({}, getAttributeList())
        };

        const newData = rows.map(row => {
            const rowKey = getRowKey(row);

            const attributeList = getAttributeList(row);
            const flatAttributes = flattenAttributes(row, attributeList);
            if ((totalTop || totalBottom) && !parent) {
                Object.entries(flatAttributes).forEach(([key, value]) => {
                    if (typeof value === 'number') {
                        totalsRow[key] = (totalsRow[key] || 0) + value
                    }
                })
            }

            const children = getChildren(row);
            const selected = isRowSelected(row, parent?.selected);

            const rowData = {
                ...row,
                ...flatAttributes,
                rowKey,
                selected
            }

            if (parent) {
                rowData.parent = parent;
            }

            if (children) {
                rowData.children = getTableRows(children, rowData);
                if (!selected) {
                    rowData.childSelected = children.length > 1 && children.some(child => isRowSelected(child))
                }
            };

            if (dataAttribute) {
                delete rowData[dataAttribute];
            }

            return rowData;
        })

        const result = sorted ? newData.sortBy(sortColumn || rowHeaderKey, sortType === 'desc') : newData;
        
        if (!parent && totalTop) {
            result.splice(0, 0, {
                ...totalsRow,
                rowKey: 'totalTop',
            });
        }

        if (!parent && totalBottom) {
            result.push({
                ...totalsRow,
                rowKey: 'totalBottom',
            });
        }

        return result;

    };

    function filterColumnTypeValues(columnType) {
        return {
            ...columnType,
            values: columnType.values.filter(({ key }) => {
                if (visibleCols?.[columnType.name]?.length) {
                    const visibleKeys = visibleCols?.[columnType.name] || [];
                    return visibleKeys.includes(key)
                } else {
                    return true;
                }

            })
        }
    };

    function getColumns({ data = columnTypes, prefix = dataAttribute, parentCellProps = {}, headerPlaceholder, csv } = {},) {

        const columnType = filterColumnTypeValues(data[0]);
        const nextColumnType = data[1];
        const nextData = data.slice(1);

        const values = columnType?.values;

        const renderHeader = values?.length > 1;
        const isGroup = nextData.some(columnType => filterColumnTypeValues(columnType)?.values?.length > 1);

        const selectable = columnType?.selectable;

        function processValue ({ key, label, csvLabel, abbrev, sortable, cellProps = {} }) {

            const header = abbrev || (csv ? (csvLabel || label) : label) || key;
            const dataKey = prefix ? `${prefix}-${key}` : key;

            if (nextColumnType) {
                return {
                    header: (values.length > 1) || csv ? header : undefined,
                    isGroup,
                    sortable,
                    selectable,
                    dataKey: key,
                    cellProps: {...cellProps, ...parentCellProps},
                    children: getColumns({
                        data: nextData,
                        prefix: dataKey,
                        headerPlaceholder: header,
                        csv,
                        parentCellProps: {...cellProps, ...parentCellProps},
                    })
                }
            } else {
                return {
                    sortable,
                    selectable,
                    cellProps: {...cellProps, ...parentCellProps},
                    header: renderHeader || csv ? header : headerPlaceholder,
                    dataKey
                }
            }
        }

        const result = values.map(processValue)

        if (columnType?.total) {
            const totalsColumn = processValue({
                key: 'total',
                label: t('Total'),
                abbrev: t('Total'),
            })
            switch (columnType.total) {
                case 'left':
                    result.splice(0, 0, totalsColumn);
                    break;
                case 'right':
                    result.push(totalsColumn);
                    break;
                case 'both':
                    result.splice(0, 0, totalsColumn);
                    result.push(totalsColumn);
                    break;
            }
        }

        return result
    };

    function handleSelectRow(row) {
        if (!multiselect) {
            onSelectRow([row?.rowKey]);
            return;
        }

        const newRows = [...selectedRows];
        if (row?.children?.length) {
            row.children.forEach(child => {
                deselectRow({ ...child, parent: undefined }, newRows);
            })
        }
        if (!newRows.includes(row.rowKey)) {
            newRows.push(row.rowKey);
        }
        onSelectRow(newRows);
    };

    function deselectRow(row, result) {
        const rowKey = getRowKey(row);
        const newRows = result || [...selectedRows];
        if (newRows.includes(row.rowKey)) {
            newRows.splice(newRows.indexOf(row.rowKey), 1);
        }
        if (row?.children) {
            row.children.forEach(child => {
                const childRow = { ...child, parent: undefined };
                deselectRow(childRow, newRows);
            })
        }
        if (row?.parent) {
            if (row?.parent?.selected) {
                row?.parent?.children.forEach(sibling => {
                    const siblingKey = getRowKey(sibling);
                    if (siblingKey !== rowKey && !newRows.includes(siblingKey)) {
                        newRows.push(siblingKey);
                    }
                })
            }
            const parentRow = { ...row.parent, children: [] };
            deselectRow(parentRow, newRows);
        }
        return newRows;
    };

    function handleDeselectRow(row) {
        if (!multiselect) {
            onSelectRow([]);
            return;
        }

        onSelectRow(deselectRow(row));
    };

    function renderColumn({ 
        header, 
        selected, 
        selectable, 
        isGroup, 
        sortable, 
        dataKey, 
        children, 
        level = 0, 
        cellProps={} 
    }) {
        const isSelected = selected || selectedColumns.includes(dataKey);

        if (children) {
            if ((isGroup && header) || (!level && selectable && header)) {
                return (
                    <Table.ColumnGroup
                        key={dataKey}
                        header={<HeaderCell
                            value={dataKey}
                            selected={isSelected}
                            selectable={selectable}
                            selectedColumns={selectedColumns}
                            onChange={onSelectColumn}
                            {...cellProps}
                        >
                            {header}
                        </HeaderCell>}
                    >
                        {children.map(child => renderColumn({
                            level: level + 1,
                            header: children.length > 1 ? child.header : header,
                            selected: isSelected,
                            ...child
                        }))}
                    </Table.ColumnGroup>
                )
            } else {
                return children.map(child => renderColumn({
                    level: level + 1,
                    header: children.length > 1 ? child.header : header,
                    ...child,
                    selected: isSelected,
                }))
            }
        } else if (dataKey) {

            return <Table.Column key={dataKey} sortable={sortable} width={cellProps?.width || colWidth}>
                <Table.HeaderCell 
                    className={isSelected ? 'selected' : ''}    
                    {...cellProps}
                >
                    {header}
                </Table.HeaderCell>
                <CellClass dataKey={dataKey} fontSize={fontSize} selected={isSelected} {...cellProps} />
            </Table.Column>
        }
    };

    const tableRows = getTableRows();
    let numberOfRows = tableRows.length;

    tableRows.filter(row => expandedRowKeys.includes(getRowKey(row))).forEach(row => {
        numberOfRows += (row?.children?.length) || 0;
    })
    

    const columns = getColumns();

    const height = maxRows ? 
        headerHeight + Math.min(numberOfRows, maxRows) * (rowHeight || 40) : 
        props?.height;

    function getCSVHeaders ({headers = [], subheaders = [], column}) {
        if (!column?.children?.length) {
            headers.push({
                subheaders: [...subheaders, column?.header],
                dataKey: column?.dataKey,
            })
        } else {
            column.children.forEach(child => {
                getCSVHeaders({
                    headers,
                    subheaders: [...subheaders, column?.header],
                    column: child
                })
            })
        }

        return headers
    };

    function generateCSV () {
        const csvColumns = getColumns({csv: true})
        const csvRows = [];

        const headers = [];
        csvColumns.forEach(column => headers.push(...getCSVHeaders({column})));

        csvRows.push(...columnTypes.map( ({name, label}, i) => [
            ...csvExtraColumns.map(({label}) => i ? '' : label),
            ...(renderRowHeader === noop ? [] : [label || name]),
            ...headers.map(({subheaders}) => subheaders[i])
        ]))

        function addRows(parentRow, rows=[]) {
            const rowHeader = renderRowHeader(parentRow, true);
            rows.push([
                ...csvExtraColumns.map(({getValue}) => rowHeader ? getValue(parentRow) : ''),
                ...(renderRowHeader === noop ? [] : [rowHeader || t('Total')]),
                ...headers.map(({dataKey}) => parentRow?.[dataKey])
            ]);
            (parentRow?.children || []).forEach(child => addRows(child, rows));
            return rows
        }
        
        tableRows.forEach(tableRow => {
            csvRows.push(...addRows(tableRow));
        })

        return 'data:text/csv;charset=utf8,\ufeff"' + csvRows.map(row => row.join('";"')).join('"\r\n"') + '"'; 
    };

    
    React.useImperativeHandle(ref, () => ({
        ...(tableRef?.current || {}),
        saveAsCSV( filename ) {
            console.log('Saving CSV...')
            var a = document.createElement("a");
            a.href = generateCSV();
            a.download = filename + (exportName ? ` - ${exportName}` : '') + '.csv';
            a.click();
        }
    }));

    return <>

        {canSetVisibleColumns && (
            <ColumnSelectorForm
                columnTypes={columnTypes}
                visibleColumns={visibleCols}
                setVisibleColumns={setVisibleCols} />
        )}

        <Table
            ref={tableRef}
            data={tableRows}
            loading={!tableRows?.length}
            locale={{
                emptyMessage: t('RenderingData')
            }}
            rowKey={rowKey}


            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSort}

            shouldUpdateScroll={false}

            rowHeight={rowHeight}
            headerHeight={subHeaders > 1 ? 2 * headerHeight : headerHeight}

            {...props}
            height={height}

            expandedRowKeys={expandedRowKeys}
            onExpandChange={onExpandChange}
        >

            {renderRowHeader !== noop && (
                <Table.Column
                    key={rowHeaderKey}
                    resizable={headerWidth ? true : false}
                    sortable={sorted}
                    fixed
                    width={headerWidth}
                    flexGrow={headerWidth ? 0 : 1}>
                    <Table.HeaderCell>{title}</Table.HeaderCell>
                    <RowHeaderCell
                        selectable={onSelectRow !== noop}
                        selectedRows={selectedRows}
                        onSelect={handleSelectRow}
                        onDeselect={handleDeselectRow}
                        renderRowHeader={renderRowHeader} />
                </Table.Column>
            )}
            {columns.map(renderColumn)}
            <Table.Column style={{border: '0px'}} width={12}>
                <Table.HeaderCell style={{border: '0px'}}></Table.HeaderCell>
                <Table.Cell style={{border: '0px'}}></Table.Cell>
            </Table.Column>

        </Table>
    </>
});

export default DataTable