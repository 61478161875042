import { getAPI } from "utils/api"

export const getOrganizations = (signal) => getAPI(`ft_organizations`, signal)

export const getFormData = (countId, signal) => getAPI(`onboardcount/${countId}/form_data`, signal)

export const getSections = (countId, params, signal) => getAPI(`onboardcount/${countId}/sections`, params, signal )

export const getStopPlaces = (countId, params, signal) => getAPI(`onboardcount/${countId}/stop_places`, params, signal )

export const getAreas = (countId, params, signal) => getAPI(`onboardcount/${countId}/areas`, params, signal )

export const getServicePatterns = (countId, params, signal) => getAPI(`onboardcount/${countId}/service_patterns`, params, signal )

export const getTransportLines = (countId, params, signal) => getAPI(`onboardcount/${countId}/transport_lines`, params, signal )

export const getJourneys = (countId, params, signal) => getAPI(`onboardcount/${countId}/journeys`, params, signal )

export const getJourneyStops = (countId, params, signal) => getAPI(`onboardcount/${countId}/journey_stops`, params, signal )
