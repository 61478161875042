import React from "react";
import { ButtonGroup, ButtonToolbar, SelectPicker } from "rsuite";
import { t } from "i18next";

import { ESRIIcon } from "components/icons";
import { SimpleModal } from "components/modals";
import { AuthContext } from "features/auth";

import MapSettings from "./MapSettings";
import WebmapContext from "../context/WebmapContext";
import { LabeledIconButton } from "components/button";
import ViewPreferences from "./ViewPreferences";
import { CrossSectionRoadCountContext } from "features/crossSectionRoadCount";


const MapToolbar = () => {
    const {user} = React.useContext(AuthContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);
    
    const {
        webmap, 
        arcGISMap
    } = React.useContext(WebmapContext);

    const {
        labelToolbarButtons = true
    } = webmap?.userConfig || {};

    const [mapSettingsVisible, setMapSettingsVisible] = React.useState(false);
    const [viewPreferencesVisible, setViewPreferencesVisible] = React.useState(false);
    const [legendVisible, setLegendVisible] = React.useState(arcGISMap?.legend?.visible);

    function toggleLegend() {
        const newValue = !legendVisible;
        setLegendVisible(newValue);
        if (arcGISMap?.legend) {
            arcGISMap.legend.visible = newValue;
        }
    };

    React.useEffect(() => {
        setLegendVisible(arcGISMap?.legend?.visible);
    }, [arcGISMap?.legend?.visible])

    React.useEffect(() => {
        const configData = {};
        (webmap?.configurations || []).forEach( ({id, items}) => {
            configData[id] = items[0]?.id;
        });
        arcGISMap?.setConfigurations(configData);
    }, [webmap, arcGISMap]);

    return <>
        <ButtonToolbar>
            { webmap?.configurations.map( ({id, items}) => (
                <SelectPicker
                    key={id}
                    placement="bottomEnd"
                    searchable={false}
                    cleanable={false}
                    value={arcGISMap?.configurations?.[id]}
                    onChange={value => arcGISMap?.setConfigurations({
                        ...arcGISMap?.configurations,
                        [id]: value
                    })}
                    data={ items.map(({id, name}) => ({
                        key: id,
                        label: name,
                        value: id
                    }))} />
            )) }
            <ButtonGroup size="xs">

                <LabeledIconButton 
                    label={t('Map/Legend')}
                    title={legendVisible ? t('Map/HideLegend') : t('Map/ShowLegend')}
                    showLabel={labelToolbarButtons}
                    icon={<ESRIIcon name="legend" />}
                    appearance={legendVisible ? 'primary' : 'default'}
                    onClick={toggleLegend} />

                { crossSectionRoadCount?.moduleParameters?.id && crossSectionRoadCount?.mapToolbarButtons?.map((props, i) => (
                    <LabeledIconButton key={i} showLabel={labelToolbarButtons} {...props} />
                )) }

                <LabeledIconButton
                    label={t('Map/ShowExtent')}
                    title={t('Map/ZoomToExtent')}
                    showLabel={labelToolbarButtons}
                    icon={<ESRIIcon name="zoom-out-fixed" />}
                    appearance='default'
                    onClick={arcGISMap?.zoomToExtent} />

                <LabeledIconButton
                    disabled={ !arcGISMap?.selection?.length }
                    label={t('Map/Selection')}
                    title={t('Map/ZoomToSelection')}
                    showLabel={labelToolbarButtons}
                    icon={<ESRIIcon name="zoom-to-object" />}
                    appearance='default'
                    onClick={arcGISMap?.zoomToSelection} />

                <LabeledIconButton
                    label={t('Save')}
                    title={t('Map/SaveAsImage') + (webmap?.isDemo ? `\n${t('Map/UnavailableInDemo')}` : '')}
                    showLabel={labelToolbarButtons}
                    icon={<ESRIIcon name="save" />}
                    appearance='default'
                    disabled={webmap?.isDemo}
                    onClick={arcGISMap?.exportImage} />

                <LabeledIconButton
                    label={t('Map/View')}
                    title={t('Map/ViewPreferences')}
                    showLabel={labelToolbarButtons}
                    icon={<ESRIIcon name="sliders-horizontal" />}
                    appearance='default' 
                    onClick={ () => setViewPreferencesVisible(true) }/>

                { !user?.roles?.includes('admin') ? <></> : (
                    <LabeledIconButton
                        label={t('Map/Administration')}
                        showLabel={labelToolbarButtons}
                        icon={<ESRIIcon name="settings" />}
                        appearance={mapSettingsVisible ? 'primary' : 'default'}
                        onClick={() => setMapSettingsVisible(!mapSettingsVisible)} />
                )}
            </ButtonGroup>
        </ButtonToolbar>
        <SimpleModal title={t('Map/ViewPreferences')} open={viewPreferencesVisible} setOpen={setViewPreferencesVisible}>
            <ViewPreferences />
        </SimpleModal>
        <SimpleModal title={t('Map/Settings')} open={mapSettingsVisible} setOpen={setMapSettingsVisible}>
            <MapSettings />
        </SimpleModal>
        
    </>
};

export default MapToolbar;