import { t } from "i18next";



export const commonLocale = {
    loading: t('Loading'),
    emptyMessage: t('NoDataFound'),
};

export const plaintextLocale = {
    unfilled: t('Unfilled'),
    notSelected: t('NotSelected'),
    notUploaded: t('NotUploaded'),
};


export const paginationLocale = {
    more: t('More'),
    prev: t('Previous'),
    next: t('Next'),
    first: t('First'),
    last: t('Last'),
    limit: t('PageLimit'),
    total: t('TotalRows'),
    skip: t('GoTo')
};

export const calendarLocale = {
    monday: t('MondayShort'),
    tuesday: t('TuesdayShort'),
    wednesday: t('WednesdayShrot'),
    thursday: t('ThursdayShort'),
    friday: t('FridayShort'),
    saturday: t('SaturdayShort'),
    sunday: t('SundayShort'),
    ok: t('OK'),
    today: t('Today'),
    yesterday: t('Yesterday'),
    hours: t('Hours'),
    minutes: t('Minutes'),
    seconds: t('Seconds'),
    formattedMonthPattern: 'yyyy. MMM',
    formattedDayPattern: 'yyyy. MMM dd.'
};


export const pickerLocale = {
    noResultsText: t('NoResults'),
    placeholder: t('NotSelected'),
    searchPlaceholder: t('Search'),

    // for CheckTreePicker, MultiCascader
    checkAll: t('CheckAll'),

    // for InputPicker
    newItem: t('NewItem'),
    createOption: t('CreateOption'),
};

export const uploaderLocale = {
    inited: t('Initial'),
    progress: t('Uploading'),
    error: t('Error'),
    complete: t('Complete'),
    emptyFile: t('Empty'),
    upload: t('Upload')
}

const RSuiteLocale = {
    common: commonLocale,
    Plaintext: plaintextLocale,
    Pagination: paginationLocale,
    Calendar: calendarLocale,
    DatePicker: calendarLocale,
    DateRangePicker: {
        ...calendarLocale,
        last7Days: t('Last7Days')
    },
    Picker: pickerLocale,
    InputPicker: pickerLocale,
    Uploader: uploaderLocale,
    CloseButton: {
        closeLabel: t('Close'),
    },
    Breadcrumb: {
        expandText: t('ShowPath')
    },
    Toggle: {
        on: t('Open'),
        off: t('Close')
    }
};

export default RSuiteLocale;