import React from "react";
import { Panel } from 'rsuite';

import CenteredPage from "./CenteredPage";
import { FloatingNavbar } from "components/pages";
import { t } from "i18next";


const CenteredPanelPage = ({
    navbar,
    title,
    children,
    ...props
}) => {
    return (
        <CenteredPage {...props}>
            { navbar ? <FloatingNavbar /> : <></> }
            <Panel style={{ display: 'inline-block' }} bordered>
                <h3>
                    {title || `${t('Navigation/Host')} ${t('Navigation/Brand')}`}
                </h3>
                {children}
            </Panel>
        </CenteredPage>
    );
};


export default CenteredPanelPage;
