import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import React from 'react'
import { Col, Row } from 'rsuite';
import JourneyTrafficTable from './JourneyTrafficTable';
import TransportLineTrafficTable from './TransportLineTrafficTable';
import { t } from 'i18next';

const SectionTrafficPanelContent = React.forwardRef(({
    height = 500,
    ...props
}, ref) => {
    
    const transportLinesRef = React.useRef();
    const journeysRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
        tables: [{
            label: t('FT/TransportLineTraffic'),
            ref: transportLinesRef
        }, {
            label: t('FT/JourneyTraffic'),
            ref: journeysRef
        }]
    }))

    return (
        <Row>
            <Col xs={24} sm={12}>
                
                <TransportLineTrafficTable
                    ref={transportLinesRef}
                    selectedIntervalOnly
                    virtualized
                    height={height} 
                    {...props} 
                />
                
            </Col>
            <Col xs={24} sm={12}>

                <JourneyTrafficTable
                    ref={journeysRef}
                    virtualized
                    height={height}
                    {...props} 
                />
            </Col>
        </Row>
    )
});

export default SectionTrafficPanelContent