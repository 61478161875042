import React from "react";

import { useModule } from "features/webmapModule";
import useOnboardCountJoins from "./useOnboardCountJoins";
import { getSections, getStopPlaces, getAreas, getTransportLines, getServicePatterns, getJourneys, getJourneyStops } from "../onboardCountAPI";
import CountInterval from "../elements/CountInterval";
import DayType from "../elements/DayType";
import Section from "../elements/Section";
import StopPlace from "../elements/StopPlace";
import Area from "../elements/Area";
import TransportLine from "../elements/TransportLine";
import ServicePattern from "../elements/ServicePattern";
import Journey from "../elements/Journey";
import JourneyStop from "../elements/JourneyStop";
import { noop } from "utils/constants";
import useOnboardCountMenu from "./useOnboardCountMenu";
import { ALIGHT_BOARD_COUNT, BOARD_COUNT, COUNT_INTERVAL_TYPE_SHORT_LABELS, CROSS_SECTION_COUNT, LINE_TRAFFIC_DISTRIBUTION, SECTION_TRAFFIC, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from "../utils/constants";
import ElementCard from "../components/cards/ElementCard";
import { dateToStr } from "utils/date";
import { secondsToString } from "../utils/time";
import { t } from "i18next";
import { SectionCard } from "../components/cards";


const ELEMENT_TYPE_ORDER = [
    'stopPlace',
    'section',
    'area'
];


function processAPIResponse(Element) {
    return ({data, responseTime}) => {
        if (Array.isArray(data)){
            const elements = {};
            data.forEach(row => {
                if (elements?.[row?.id]?.traffic && row?.traffic && row?.countInterval) {
                    elements[row.id].traffic[row.countInterval] = row.traffic[row.countInterval];
                } else {
                    elements[row?.id] = Element(row);
                };
            })
            return {
                rows: Object.values(elements).sortBy(),
                responseTime
            };
        } else {
            return {
                rows: [],
                responseTime,
            }
        }
    }
};


function cacheElements(cacheRef) {
    return ({rows, responseTime}) => {
        rows.map(element => {
            if (!cacheRef.current?.[element.id]) {
                cacheRef.current[element.id] = Object.fromEntries(Object.entries(element).filter(([k, v]) => k != 'traffic'));
            }
        })
        return {rows, responseTime}
    }
}


function useOnboardCount({
    webmap, 
    arcGISMap,
    setJoins = noop,
}) {

    const defaultMinTrafficSize = webmap?.modules?.onboardCount?.minTrafficSize || 1;
    const defaultMaxTrafficSize = webmap?.modules?.onboardCount?.maxTrafficSize || 50;

    const {
        minTrafficSize = defaultMinTrafficSize,
        maxTrafficSize = defaultMaxTrafficSize,
        labelStopPlaces = true,
        labelSections = false
    } = webmap?.userConfig || {};

    const viewPreferences = {
        minTrafficSize,
        maxTrafficSize,
        labelStopPlaces,
        labelSections,
    };

    const transportLineCache = React.useRef({});
    const sectionCache = React.useRef({});

    const {
        id,
        stopPlaceElementId,
        sectionElementId,
    } = webmap?.modules?.onboardCount || {};

    const noSelectionParams = {
        areaId: undefined,
        stopPlaceId: undefined,
        sectionId: undefined,
        sectionDirection: undefined,
    };

    const noTransportLineParams = {
        transportLineIds: undefined,
        servicePatternIds: undefined,
    }

    const noJourneyParams = {
        journeyId: undefined,
    }

    async function getDayTypes () {
        return {
            rows: !webmap?.modules?.onboardCount?.dayTypes?.length ? [] : (webmap?.modules?.onboardCount?.dayTypes)
                .map(DayType)
                .sortBy()
        }
    }

    async function getCountIntervals () {
        return {
            rows: !webmap?.modules?.onboardCount?.countIntervals?.length ? undefined : (webmap?.modules?.onboardCount?.countIntervals)
                .map(CountInterval)
                .sortBy(),
        }
    }

    const queryFunctions = {
        dayTypes: getDayTypes,

        countIntervals: getCountIntervals,

        sections: (filters, signal) => getSections( id, {...filters, ...noSelectionParams}, signal )
            .then(processAPIResponse(Section))
            .then(cacheElements(sectionCache)),

        stopPlaces: (filters, signal) => getStopPlaces( id, {...filters, ...noSelectionParams}, signal )
            .then(processAPIResponse(StopPlace)),

        areas: (filters, signal) => getAreas( id, {...filters, ...noSelectionParams}, signal )
            .then(processAPIResponse(Area)),

        transportLines: (filters, signal) => getTransportLines( id, {...filters, ...noTransportLineParams, countInterval: undefined}, signal )
            .then(processAPIResponse(TransportLine))
            .then(cacheElements(transportLineCache)),

        servicePatterns: (filters, signal) => getServicePatterns( id, {...filters, ...noTransportLineParams, countInterval: undefined}, signal )
            .then(processAPIResponse(ServicePattern)),

        journeys: (filters, signal) => getJourneys( id, {...filters, journeyId: undefined}, signal )
            .then(processAPIResponse(Journey)),

        journeyStops: (filters, signal) => getJourneyStops( id, {
            ...filters, 
            ...(filters?.servicePatternIds?.length === 1 ? noSelectionParams : {}),
            ...noJourneyParams,
        }, signal )
            .then(processAPIResponse(JourneyStop)),
    };

    function getExportName (filters, elements) {
        try {
            
            let name = `` +
            `${t('FT/OnboardCount')} - `;

            if (filters?.countDate) {
                name += dateToStr(filters.countDate);
            } else {
                name += `${dateToStr(filters?.startDate)}-${dateToStr(filters?.endDate)} ` +
                `(${filters?.dayTypeIds.map(id => elements?.dayTypes?.[id]?.name).join(', ')}) - ` +
                `${ COUNT_INTERVAL_TYPE_SHORT_LABELS[filters?.countIntervalType] }`
            }
            
            if (filters?.startTime || filters?.endTime) {
                name += `` + 
                    ` - ${secondsToString( filters?.startTime || 7200)}` + 
                    `-${secondsToString( filters?.endTime || 7199)}`
            }

            if (filters?.transportLineIds?.length) {
                name += ` - ${filters?.transportLineIds.map(id => elements?.transportLines?.[id]?.name).join(', ')}`
            }

            if (filters?.servicePatternIds?.length) {
                name += ` - ${filters?.servicePatternIds.map(id => elements?.servicePatterns?.[id]?.shortLabel).join(', ')}`
            }

            if (filters?.areaId) {
                name += ` - ${elements?.areas?.[filters?.areaId]?.name}`
            }

            if (filters?.stopPlaceId) {
                name += ` - ${elements?.stopPlaces?.[filters?.stopPlaceId]?.name}`
            }

            if (filters?.sectionId) {
                name += ` - ${elements?.sections?.[filters?.sectionId]?.name}`
            }

            return name
        } catch (e) {
            return ''
        }

    }

    const module = useModule({
        moduleName: 'onboardCount',
        map: arcGISMap,
        webmap,
        moduleParameters: {
            id,
            stopPlaceElementId,
            sectionElementId
        },
        menuHook: useOnboardCountMenu,
        joinsHook: useOnboardCountJoins,
        setJoins,
        getExportName,
        elementTypeOrder: ELEMENT_TYPE_ORDER,
        queryFunctions,
        viewPreferences
    });

    const {
        selection = [],
        selectionIndex,
    } = arcGISMap || {};

    // Count data

    const {
        dayTypes = {},
        countIntervals = {},
        sections = {},
        areas = {},
        stopPlaces = {},
        transportLines = {},
        servicePatterns = {},
        journeys = {},
        journeyStops = {},
    } = module?.elements;

    const {
        countDate,
        countInterval,
        dayTypeIds = [],
        countIntervalType,
        startDate,
        endDate,
        startTime,
        endTime,
        transportLineIds = [],
        servicePatternIds = [],
        journeyId,
        areaId,
        stopPlaceId,
        sectionId,
        sectionDirection,
    } = module?.filters

    const countDateStr = dateToStr(countDate);
    const dayTypeIdsStr = `${dayTypeIds}`;
    const filtersSet = countDate || (countIntervalType && dayTypeIds.length && startDate && endDate);

    // Filter elements

    const selectedFeature = selection?.[selectionIndex]?.attributes;

    const selectedDayTypes = dayTypeIds.map(id => dayTypes?.[id]);
    
    const timeSeriesIntervalIds = [];
    Object.values(servicePatterns).map(servicePattern => {
        Object.keys(servicePattern.traffic).forEach(key => {
            if (!timeSeriesIntervalIds.includes(key)) {
                timeSeriesIntervalIds.push(key)
            }
        })
    })
    timeSeriesIntervalIds.sort();

    const selectedCountDate = countDate && CountInterval({date: countDate});
    const selectedInterval = CountInterval(selectedCountDate || countInterval || timeSeriesIntervalIds[0]);

    const selectedTransportLines = transportLineIds.map(id => transportLines?.[id]);
    const selectedServicePatterns = servicePatternIds.map(id => servicePatterns?.[id]);
    const selectedJourney = journeys?.[journeyId]

    const selectedArea = areas?.[areaId];
    const selectedStopPlace = stopPlaces?.[stopPlaceId];
    const selectedSection = sections?.[sectionId];

    const selectedElements = {
        feature: selectedFeature,
        dayTypes: selectedDayTypes,
        countInterval: selectedInterval,

        transportLines: selectedTransportLines,
        servicePatterns: selectedServicePatterns,
        journey: selectedJourney,

        area: selectedArea,
        stopPlace: selectedStopPlace,
        section: selectedSection ? {
            ...selectedSection,
            direction: sectionDirection
        } : undefined,
        element: selectedArea || selectedStopPlace || (selectedSection ? {...selectedSection, direction: sectionDirection} : undefined),

        timeSeriesIntervalIds,
    };

    const cachedElements = {
        transportLines: transportLineCache.current,
        sections: sectionCache.current,
    };

    const exportName = getExportName(module?.filters, module?.elements);


    function getLabel (item) {
        switch (item?.elementType) {
            case 'section':
                const section = sectionCache.current?.[item?.elementId];
                return <SectionCard {...section} direction={item?.direction} size="xs" />
            default:
                return <ElementCard item={item} size='xs' />
        }
    }


    React.useEffect(() => {
        if (!id) return;

        arcGISMap.setExportName(exportName);
    }, [exportName])

    React.useEffect(() => {
        if (!id) return;
        console.log('Updating day types and count intervals...')

        module.updateElements(
            'dayTypes',
            'countIntervals',
        );
    }, [id]);

    React.useEffect(() => {
        console.log('Count Date changed', countDate);

    }, [countDate])

   
    React.useEffect(() => {
        if (!filtersSet) return;

        console.log('Querying sections and stop places...')

        module.updateElements(
            'sections',
            'stopPlaces',
            'areas'
        );

    }, [countDateStr, dayTypeIdsStr, countIntervalType, startDate, endDate, startTime, endTime, countInterval,
        `${transportLineIds}`, `${servicePatternIds}`, journeyId]);


    React.useEffect(() => {
        if (!filtersSet) return;

        console.log('Querying service patterns...')

        module.updateElements(
            'servicePatterns',
            'transportLines'
        )

    }, [countDateStr, dayTypeIdsStr, countIntervalType, startDate, endDate, startTime, endTime, 
        areaId, stopPlaceId, sectionId, sectionDirection]);

   
    React.useEffect(() => {
        if (!filtersSet) return;

        console.log('Querying journeys and journeyStops...')

        module.updateElements(
            'journeys',
            'journeyStops'
        )

    }, [countDateStr, dayTypeIdsStr, countIntervalType, startDate, endDate, startTime, endTime, countInterval,
        `${transportLineIds}`, `${servicePatternIds}`, areaId, stopPlaceId, sectionId, sectionDirection]);

    React.useEffect(() => {
        if (!filtersSet) return;
        
        console.log('Updating filters on transportLineIds change')
        if ( transportLineIds.length ) {
            const newFilters = {};

            const transportLineServicePatterns = Object.values(servicePatterns).filter(servicePattern => servicePattern.transportLine.id === transportLineIds[0])
            if (transportLineIds.length === 1 && transportLineServicePatterns.length === 1) {
                console.log('Selected transport line has only one service pattern.')
                newFilters.transportLineIds = []
                newFilters.servicePatternIds = [transportLineServicePatterns[0].id]
            } else {
                console.log('Clearing service pattern filter because transport line is selected...');
                newFilters.servicePatternIds = []
            }


            if (selectedJourney && !transportLineIds.includes(selectedJourney?.servicePattern?.transportLine?.id)) {
                console.log(`Clearing journey because different transport lines are selected.`)
                newFilters.journeyId = undefined;
            }

            module.applyFilters(newFilters);
        } else if (!servicePatternIds.length) {
            module.applyFilters({
                journeyId: undefined
            });
        }

    }, [`${transportLineIds}`]);

    React.useEffect(() => {
        if (!filtersSet) return;

        const newFilters = {};
        
        console.log('Updating filters on servicePatternIds change')

        if (!servicePatternIds?.length && !transportLineIds?.length) {
            console.log('Clearing journey filter because service pattern or transportLine is not selected...');
            newFilters.journeyId = undefined
        } 

        if (servicePatternIds?.length !== 1 && [ALIGHT_BOARD_COUNT, CROSS_SECTION_COUNT].includes(module?.tableMenu?.values[1]) ) {
            module.tableMenu.setValues([TRANSPORT_LINE_TRAFFIC, BOARD_COUNT]);
        }

        if (servicePatternIds?.length !== 1 && module?.chartMenu?.values[1] === LINE_TRAFFIC_DISTRIBUTION) {
            module.chartMenu.setValues([TRANSPORT_LINE_TRAFFIC, BOARD_COUNT]);
        }

        if (servicePatternIds.length) {
            console.log('Clearing transport line filter...');
            newFilters.transportLineIds = [];

            if ( selectedJourney && !servicePatternIds.includes(selectedJourney?.servicePattern?.id) ) {
                console.log('Clearing journey filter because other service pattern is selected...');
                newFilters.journeyId = undefined;
            };
        }

        module.applyFilters(newFilters);

    }, [`${servicePatternIds}`]);

    React.useEffect(() => {
        if (!filtersSet) return;
        
        if (journeyId) {
            const servicePatternId = selectedJourney?.servicePattern?.id;
            const transportLineId = selectedJourney?.servicePattern?.transportLine?.id;

            if (!servicePatternIds.includes(servicePatternId) && !transportLineIds.includes(transportLineId)) {
                console.log('Changing servicePatternId to journey.servicePatternId...')
                module.applyFilters({
                    servicePatternIds: [journeys?.[journeyId]?.servicePattern?.id],
                });
            }

        }
    }, [journeyId]);


    React.useEffect(() => {
        if (!id) return;
        console.log('Selected feature changed...')

        const newFilters = {
            areaId: undefined,
            stopPlaceId: undefined,
            sectionId: undefined,
            sectionDirection: undefined,
        }

        switch(selectedFeature?.elementType) {
            case 'area':
                newFilters.areaId = selectedFeature?.elementId;
                break;
            case 'stopPlace':
                newFilters.stopPlaceId = selectedFeature?.elementId;
                break;
            case 'section':
                newFilters.sectionId = selectedFeature?.elementId;
                newFilters.sectionDirection = selectedFeature?.direction;
                break;
        }
        
        module?.applyFilters(newFilters);

    }, [`${selectedFeature?.elementType}-${selectedFeature?.elementId}-${selectedFeature?.direction}`])


    React.useEffect(() => {
        if (areaId) {
            arcGISMap.selectFeature({
                layer: 'area',
                elementId: areaId,
                zoomTo: false,
            });
        }
    }, [areaId])


    React.useEffect(() => {
        if (stopPlaceId) {
            arcGISMap.selectFeature({
                layer: 'stopPlace',
                elementId: stopPlaceId,
                zoomTo: false,
            });
        }
    }, [stopPlaceId])


    React.useEffect(() => {
        if (!id) return;
        if (!sectionId) {
            if (module?.tableMenu?.values[0] === SECTION_TRAFFIC) {
                module.tableMenu.setValues([STOP_PLACE_TRAFFIC]);
                module.chartMenu.setValues([STOP_PLACE_TRAFFIC]);
            }
        } else {

            if (module?.tableMenu?.values[0] === STOP_PLACE_TRAFFIC) {
                module.tableMenu.setValues([SECTION_TRAFFIC]);
                module.chartMenu.setValues([SECTION_TRAFFIC]);
            }
            arcGISMap.selectFeature({
                layer: sectionDirection === 1 ? 'sectionForward' : 'sectionBackward',
                elementId: sectionId,
                zoomTo: false,
            });
        }

    }, [sectionId, sectionDirection])

    React.useEffect(() => {
        if (!id) return;
        if (!areaId && !stopPlaceId && !sectionId) {
            arcGISMap.selectFeature();
        }
    }, [areaId, stopPlaceId, sectionId, sectionDirection])

    React.useEffect(() => {
        if (!id) return;
        if (arcGISMap?.legend?.container) {
            arcGISMap.legend.container.classList.add('onboardCountLegend')
        }

    }, [arcGISMap?.legend?.container])

    if (!id) return;

    return {
        ...module,
        countId: id,
        id,
        selectedElements,
        cachedElements,
        getLabel
    }

};


export default useOnboardCount;
