import "assets/css/mapViewer.css"

import React from "react";
import { Button, List } from "rsuite";
import { t } from "i18next";

import SimpleModal from "components/modals/SimpleModal";
import { noop } from "utils/constants";

import MapElementSettings from "./MapElementSettings";
import MapElementForm from "./forms/MapElementForm";


const MapElementList = ({
    webMap,
    onChange = noop,
    onSort = noop,
    ...props
}) => {
    const [editorVisible, setEditorVisible] = React.useState(false);

    return <>
        <List 
            sortable 
            onSort={({oldIndex, newIndex}) => {
                const itemIds = webMap?.elements.map( item => item.id );
                const moveId = itemIds.splice(oldIndex, 1)[0];
                itemIds.splice(newIndex, 0, moveId);
                onSort(itemIds);
            }} 
            {...props} 
        >
            { webMap?.elements.map( (element, i) => <MapElementSettings 
                key={i}
                element={element}
                onChange={onChange} />) }
        </List>
        <Button 
            appearance="primary" 
            className="addMapElementButton"
            onClick={() => setEditorVisible(true)}
        >
            {t('Map/AddElement')}
        </Button>

        <SimpleModal title={t('Map/AddElement')} open={editorVisible} setOpen={setEditorVisible} level={1} >
            <MapElementForm
                fluid
                onSubmit={value => {
                    setEditorVisible(false);
                    onChange({params: value});
                }}
                onCancel={() => setEditorVisible(false)} />
        </SimpleModal>

    </>
}


export default MapElementList;
