import React from 'react';
import { getBlogEntryData } from '../blogAPI';

function useBlogEntry(id) {

    const [blogEntry, setBlogEntry] = React.useState();

    React.useEffect(() => {
        getBlogEntryData(id)
            .then(({data}) => setBlogEntry(data));
    }, [id]);

    return blogEntry

}

export default useBlogEntry;
