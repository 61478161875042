import { compareStrings } from 'utils/compare';
import { noop } from 'utils/constants';


function Section({
    id,
    name,
    transportLineIds,
    traffic,
    transportLines = {},
    Name
}) {
    const section = {
        elementType: 'section',
        elementId: Number(id),
        id: Number(id),
        name: name || Name || '',
        transportLines: Object.values(transportLines).filter(transportLine => transportLineIds.includes(transportLine.id)),
        traffic,
        compare: noop,
    };

    section.compare = other => compareSection(section, other);

    return section
}


export function compareSection(a, b) {
    return compareStrings(a.name, b.name) || 
        a.id - b.id;
};


export default Section;
