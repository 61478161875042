import { getAPI, postAPI } from "utils/api"

export const getProfileData = () => getAPI('profile/')


export const postProfileData = ({
    username,
    password,
    currentPassword,
}) => postAPI('profile/', {
    username,
    password,
    currentPassword,
})