import React from 'react';
import { useNavigate } from "react-router-dom";

import { CoverCard } from "components/cards";
import { noop } from "utils/constants";
import { DeleteIcon, EditIcon } from 'components/icons';
import { t } from 'i18next';


const PageTile = ({
    endpoint,
    item,
    height,
    imgHeight,
    children,
    editModalClass,
    deleteModalClass,
    onModalClose = noop,
}) => {
    const navigate = useNavigate();
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

    const EditModal = editModalClass;
    const DeleteModal = deleteModalClass;

    const actions = item?.actions || []

    if (editModalClass) {
        actions.push({
            appearance: "primary",
            color: "yellow",
            icon: <EditIcon />,
            title: t('Edit'),
            onClick: () => setEditModalVisible(true),
        })
    }
    if (deleteModalClass) {
        actions.push({
            appearance: "primary",
            color: "red",
            icon: <DeleteIcon />,
            title: t('Delete'),
            onClick: () => setDeleteModalVisible(true),
        })
    }


    return <>
        <CoverCard 
            title={item.name}
            height={height}
            imageURL={item.coverUrl || item.thumbnailUrl}
            imageHeight={imgHeight}
            actions={actions}
            onClick={() => navigate(`/${endpoint}/${item.id}`)}
            children={children}
        />
        { editModalClass && <EditModal open={editModalVisible} setOpen={setEditModalVisible} item={item} onClose={onModalClose} /> }
        { deleteModalClass && <DeleteModal open={deleteModalVisible} setOpen={setDeleteModalVisible} item={item} onClose={onModalClose} /> }
    </>
};


export default PageTile;
