import React from 'react';
import { t } from "i18next";

import { PageList } from "components/lists";
import { AuthContext } from 'features/auth';
import { PaginatedPanel } from "features/pagination";

import { getWebMapList } from "../webmapAPI";
import WebMapModal from "./WebMapModal";
import WebMapListItem from './WebmapListItem';


const WebMapListPanel = ({
    organizationId,
    collectionId,
    defaultPageSize,
    pageSizeOptions,
    ...props
}) => {

    const { adminVisible } = React.useContext(AuthContext);
    const [update, setUpdate] = React.useState(false);

    function forceUpdate() {
        setUpdate(update => !update);
    }

    return (
        <PaginatedPanel
            title={t('WebMaps')}
            createModalClass={adminVisible && WebMapModal }
            createValues={{
                organizationId,
                collections: collectionId ? [{id: collectionId}] : []
            }}
            getItems={({page, pageSize}) => getWebMapList({
                organizationId,
                collectionId,
                page,
                pageSize,
            })}
            renderItems={({data, pageSize}) => <PageList
                data={data}
                endpoint="webmaps"
                pageSize={pageSize}
                renderItem={(item, index) => (
                    <WebMapListItem
                        key={index} 
                        item={item}
                        editable={adminVisible}
                        deletable={adminVisible}
                        onModalClose={forceUpdate}
                    />
                )}
            />}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            {...props}
        />
    )
};


export default WebMapListPanel;
