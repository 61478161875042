import FormGroup from 'components/forms/FormGroup';
import { compareRoadNames } from 'features/crossSectionRoadCount/utils/compare';
import { ROAD_SECTION_SEARCH_TYPE, SEARCH_TYPE_OPTIONS, SETTLEMENT_SEARCH_TYPE, STATION_CODE_SEARCH_TYPE } from 'features/crossSectionRoadCount/utils/constants';
import { getSectionValue } from 'features/crossSectionRoadCount/utils/convert';
import { t } from 'i18next';
import React from 'react'
import { AutoComplete, Input, InputNumber, SelectPicker } from 'rsuite';
import { compareNumStrings, compareStrings, compareStringsAsInt } from 'utils/compare';
import { noop } from 'utils/constants'
import { formatString } from 'utils/format';
import { RoadBadge } from '../badges';
import { getRoadCategoryFlags } from '../cards/StationCard';
import { Station } from 'features/crossSectionRoadCount/elements';
import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount/context';

const StationFormFields = ({
    onChange = noop,
}) => {

    const module = React.useContext(CrossSectionRoadCountContext);
    const station = module?.selectedElements?.station;

    const [stationCode, setStationCode] = React.useState(station?.code);
    const [roadName, setRoadName] = React.useState(station?.roadName);
    const [roadSection, setRoadSection] = React.useState((station?.section || '0').split('+')[0]);

    const [searchType, setSearchType] = React.useState(STATION_CODE_SEARCH_TYPE)

    const [stations, setStations] = React.useState([]);

    React.useEffect(() => {
        if (module && module?.map?.queryFeatures) {
            module.map.queryFeatures({ 
                layer: 'segment',
                where: "KUTKA NOT IN ('8', '9')"
            })
                .then(features => features.map(f => f.attributes))
                .then(items => items.map(Station))
                .then(stations => Object.fromEntries(stations.map(station => [station.code, station])))
                .then(setStations)
        }

    }, [])

    const roads = {};
    Object.values(stations).forEach(station => {
        if (!roads?.[station.roadName]) {
            roads[station.roadName] = {
                category: station.roadCategory,
                startSection: station.startSection,
                endSection: station.endSection,
            }
        }

        if (!roads[station.roadName].startSection || getSectionValue(roads[station.roadName].startSection) > getSectionValue(station.startSection)) {
            roads[station.roadName].startSection = station.startSection;
        }

        if (!roads[station.roadName].endSection || getSectionValue(roads[station.roadName].endSection) < getSectionValue(station.endSection)) {
            roads[station.roadName].endSection = station.endSection;
        }

        if (roads[station.roadName].category > station.roadCategory) {
            roads[station.roadName].category = station.roadCategory;
        }
    });

    const roadOptions = Object.entries(roads)
        .sort((a, b) => (compareStrings(a[1]?.category, b[1]?.category) || compareRoadNames(a[0], b[0])))
        .map(([name, road]) => ({
            value: name,
            label: name,
            badge: <RoadBadge size='xs' name={name} {...getRoadCategoryFlags(road.category)} />,
        }));

    const startSection = roads?.[roadName]?.startSection;
    const endSection = roads?.[roadName]?.endSection;

    function onStationCodeChange( value ) {
        setStationCode(value.replace(/\D/g,''));
    }

    React.useEffect(() => {
        let section = `${roadSection}`;
        if (section.indexOf('+') === -1) {
            section += '+000'
        };

        onChange({
            stationCode: searchType === STATION_CODE_SEARCH_TYPE ? stationCode : undefined,
            selectedSection: searchType === ROAD_SECTION_SEARCH_TYPE ? {
                roadName,
                section,
            } : undefined,
        })
    }, [stationCode, roadName, roadSection, searchType])

    return <>
        <FormGroup 
            name="searchType"
            label={t('OKKF/SearchType')}
            accepter={SelectPicker}
            data={SEARCH_TYPE_OPTIONS}
            searchable={false}
            cleanable={false}
            value={searchType}
            onChange={setSearchType}
        />

        { searchType === STATION_CODE_SEARCH_TYPE ? <>
            <FormGroup 
                name="stationCode"
                label={t('OKKF/StationCode')}
                accepter={AutoComplete}
                data={Object.values(stations).map(station => station.code)}
                filterBy={(value, item) => value?.length > 1 && item.value.indexOf(value) > -1}
                value={stationCode}
                onChange={onStationCodeChange}
            />
        </> : <></>}
        

        { searchType === ROAD_SECTION_SEARCH_TYPE ? <>
            <FormGroup 
                name="roadName"
                label={t('OKKF/RoadName')}
                accepter={SelectPicker}
                data={roadOptions}
                cleanable={false}
                renderMenuItem={(label, {badge} = {}) => badge}
                renderValue={(value, {badge} = {}) => value && badge}
                value={roadName}
                onChange={setRoadName}
            />

            <FormGroup 
                name="roadSection"
                label={t('OKKF/RoadSection')}
                accepter={Input}
                disabled={!roadName}
                value={roadSection}
                help={roadName ? formatString(t('OKKF/SectionInterval'), {startSection, endSection}) : undefined}
                useHelpTooltip={ false }
                onChange={setRoadSection}
            />
        </> : <></>}

    </>
}

export default StationFormFields