import 'assets/css/card.css';

import React from 'react'
import { Loader, Panel } from 'rsuite'

const StatCard = ({
    icon,
    name,
    title,
    value,
    unit,
    loading,
    ...props
}) => {
  return (
    <Panel bordered {...props}>
        <div className="statCard" title={title}>
            <div className="name">
                {icon} {name}
            </div>
            <div className="content" >
                <div className="value">
                    {loading ? <Loader /> : (isNaN(value) || value === undefined ? '-' : value)}
                </div>
                <div className="unit">
                    {unit}
                </div>
            </div>
        </div>
    </Panel>
  )
}

export default StatCard