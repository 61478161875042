import 'assets/css/table.css'
import { t } from 'i18next';

import React from 'react';
import { Table } from 'rsuite';


const TypeFormattedCell = ({
    rowData,
    dataKey,
    selected,
    defaultAlign = 'left',
    fontSize,
    nullValue = t('N/A'),
    zeroValue = '-',
    ...props
}) => {
    let align;

    const data = {...rowData};
    let value = data?.[dataKey];
    
    if (typeof value === 'undefined' || value === null) {
        data[dataKey] = nullValue;
        value = nullValue;
    }

    switch (typeof value) {
        case 'bigint':
        case 'number':
            align = 'right';
            break;
        default:
            align = defaultAlign;
    }

    if (value === 0) {
        data[dataKey] = zeroValue;
    }

    const classNames = [align];
    if (selected || rowData?.selected) {
        classNames.push('selected');
    }

    if (rowData?.isTotal) {
        classNames.push('total')
    }

    return (
        <Table.Cell 
            rowData={data} 
            dataKey={dataKey} 
            className={classNames.join(' ')}
            {...props}
            style={{
                ...(props?.style || {}),
                fontSize,
            }}
        />
    )
}

export default TypeFormattedCell;
