import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";
import { Message, toaster } from "rsuite";

import CenteredPanelPage from "layouts/CenteredPanelPage";

import AuthContext from "../context/AuthContext";
import RegisterForm from "../components/forms/RegisterForm";
import { registerUser } from "../authAPI";
import { getOrganizationList } from "features/organization";
import { getSubscriptionTypeList } from "features/subscription/subscriptionAPI";


const RegisterPage = ({registerOther}) => {
    const navigate = useNavigate();
    const { user, isAnonymous } = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(false);
    
    const [organizations, setOrganizations] = React.useState();
    const [subscriptionTypes, setSubscriptionTypes] = React.useState();

    React.useEffect(() => {
        getOrganizationList().then(({data}) => {
            if (!data) { return }
            setOrganizations(data);
        });
        getSubscriptionTypeList().then(({data}) => {
            if (!data) { return }
            setSubscriptionTypes(data);
        });
    }, [user]);

    React.useEffect(() => {

        if (registerOther) {
            if (user !== undefined && !user?.roles?.includes('admin')) {
                navigate('/login');
            }
        } else {
            toaster.push(<Message showIcon type="error" closable>
                {t(`Security/RegistrationClosed`)}
            </Message>)
            navigate('/login');
        }

    }, [])


    function submit(data) {
        setLoading(true)
        registerUser(data)
        .then(() => {
            setLoading(false);
            toaster.push(<Message showIcon closable>
                {t('Security/ConfirmInstructionsSent')}
            </Message>)
            navigate('/login');
        })
        .catch( err => {
            setLoading(false);
            toaster.push(<Message showIcon type="error" closable>
                {t(`API/${err.message}`)}
            </Message>)
        })
    }

    return (
        <CenteredPanelPage>
            <RegisterForm 
                registerOther={registerOther}
                loading={loading} 
                organizations={organizations}
                subscriptionTypes={subscriptionTypes}
                onSubmit={submit} />
        </CenteredPanelPage>
    );
};

export default RegisterPage;