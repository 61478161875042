import { AvatarCard } from 'components/cards';
import React from 'react'
import StationBadge from '../badges/StationBadge';
import SegmentPartCard from './SegmentPartCard';


export function getRoadCategoryFlags (roadCategory) {
    return {
        motorway: roadCategory === '1',
        highway: roadCategory === '2',
        mainRoad: ['3', '4'].includes(roadCategory),
    }
}


export function getTrackCodeFlags (trackCode) {
    return {
        onward: Boolean(trackCode && trackCode !== '2'),
        backward: Boolean(trackCode && trackCode !== '1'),
    }
}


const StationCard = ({
    size="md",
    code,
    roadName,
    roadCategory,
    startSection,
    endSection,
    roads={},
}) => {
    return (
        <AvatarCard size={size}
            icon={<StationBadge size={size} code={code} />}
            title={<>
                <SegmentPartCard 
                    size={size} 
                    roadName={roadName}
                    startSection={startSection}
                    endSection={endSection}
                    {...getRoadCategoryFlags(roadCategory)}
                    {...getTrackCodeFlags(roads?.[roadName]?.trackCode)} />
            </>}
            subtitle={<>
                {Object.values(roads).filter(r => r.name !== roadName).map(r => <div key={r.name}>
                    <div style={{height: 2}}></div>
                    <SegmentPartCard 
                        style={{marginTop: 2}}
                        size={size} 
                        roadName={r.name}
                        startSection={r.startSection}
                        endSection={r.endSection}
                        {...getRoadCategoryFlags(r.category)} 
                        {...getTrackCodeFlags(r.trackCode)}/>
                </div>)}
            </>}
        />
    )
};

export default StationCard