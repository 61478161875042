import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Divider, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import SimplePage from "layouts/SimplePage";

import useBlogEntry from "../hooks/useBlogEntry";
import { PageTitle } from "components/pages";
import { t } from "i18next";
import { getModuleTypeName } from "../utils";


function BlogEntryPage () {

    const { blogEntryId } = useParams();
    const navigate = useNavigate();
    const controlPanelEntry = useBlogEntry(blogEntryId);
    const subtitle =  controlPanelEntry?.tags.map(getModuleTypeName).join(', ');
    console.log(subtitle)

    return (
        <SimplePage>
                
            <Jumbotron backgroundUrl={controlPanelEntry?.coverUrl} >
                <PageTitle item={{...controlPanelEntry, title: t('Blog/Entry'), subtitle}} />
            </Jumbotron>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        <h3>{ controlPanelEntry?.title }</h3>
                        <h6>{ controlPanelEntry?.timestamp }</h6>
                        <div style={{marginBottom: 20}}></div>
                        <div dangerouslySetInnerHTML={{ __html: controlPanelEntry?.content }} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <div style={{ width: '100%', textAlign: 'right' }}>

                            <Button appearance="link" onClick={() => navigate(-1)}>{'< ' + t('Back') }</Button>
                        </div>
                    </Col>
                </Row>
            </Grid>  

        </SimplePage>
    )
};


export default BlogEntryPage;
