import React from 'react'
import FormModal from 'components/modals/FormModal'
import { t } from 'i18next'
import BlogEntryForm from '../forms/BlogEntryForm'
import { createBlogEntry, postBlogEntry } from '../blogAPI'
import { useMediaQuery } from 'rsuite'
import { noop } from 'utils/constants'

const BlogEntryModal = ({
    open,
    setOpen,
    onClose = noop,
    item,
}) => {
    
    const [isMobile] = useMediaQuery('xs');

    if (open) {
        console.log(item)
    }
   
    return (
        <FormModal
            title={item ? t('EditBlogEntry') : t('CreateBlogEntry')}
            size="lg"
            layout={isMobile ? "vertical" : "horizontal"}
            open={open}
            setOpen={setOpen}
            FormClass={BlogEntryForm}
            formData={item}
            onSubmit={data => {
                item
                    ? postBlogEntry(item.id, data)
                    : createBlogEntry(data)
            }}
            onClose={onClose}
        />
    )
}

export default BlogEntryModal