import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Form, Input, TagPicker } from "rsuite";
import { t } from "i18next";

import { FormGroup } from 'components/forms';
import { noop } from "utils/constants";
import { getOrganizationList } from 'features/organization';
import { HTMLInput, ImageURLInput } from 'components/inputs';
import { AuthContext } from 'features/auth';


const CollectionForm = ({ 
    formData,
    onSubmit = noop,
    ...props 
}) => {
    const { user } = React.useContext(AuthContext);

    const [ organizations, setOrganizations ] = React.useState([]);

    const [ name, setName ] = React.useState(formData?.name || '');
    const [ description, setDescription ] = React.useState(formData?.description || '');
    const [ thumbnailUrl, setThumbnailUrl ] = React.useState(formData?.thumbnailUrl || '');
    const [ coverUrl, setCoverUrl ] = React.useState(formData?.coverUrl || '');
    const [ organizationIds, setOrganizationIds ] = React.useState(formData?.organizations?.map(item => item.id) || []);

    const submitData = {
        name,
        description,
        thumbnailUrl,
        coverUrl,
        organizationIds
    };

    React.useEffect(() => {
        getOrganizationList().then(({ data }) => {
            if (data?.length) {
                setOrganizations(data);
            }
        });
    }, [user])

    return (
        <Form {...props}>
            <FormGroup
                name='name'
                label={ t('Name') }
                accepter={Input}
                value={name}
                onChange={setName}
            />
            <FormGroup 
                name='description'
                label={ t('Description') }
                accepter={HTMLInput}
                value={description}
                onChange={setDescription}
            />
            <FormGroup 
                name='thumbnailURL'
                label={ t('ThumbnailURL') }
                accepter={ImageURLInput}
                value={thumbnailUrl}
                onChange={setThumbnailUrl}
            />
            <FormGroup 
                name='coverURL'
                label={ t('CoverURL') }
                accepter={ImageURLInput}
                value={coverUrl}
                onChange={setCoverUrl}
            />
            <FormGroup
                name='organizations'
                label={ t('Organizations') }
                accepter={TagPicker}
                data={organizations.map(organization => ({
                    value: organization.id,
                    label: organization.name
                }))}
                value={organizationIds}
                onChange={setOrganizationIds}
            />
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit(submitData)}>
                        { formData ? t('Save') : t('CreateCollection') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default CollectionForm;
