import React from 'react';
import { Input } from 'rsuite';
import { noop } from 'utils/constants';

import 'assets/css/form.css';

const ImageURLInput = ({ ...props }) => {

    const ref = React.useRef();
    const [value, setValue] = React.useState('');
    const onChange = props?.onChange || noop;

    React.useEffect(() => {
        if (props?.value) {
            setValue(props?.value);
        }
    }, [props?.value])

    async function getClipboardImage () {
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
            const imageType = clipboardItem.types.find(type => type.startsWith('image/'))

            return clipboardItem.getType(imageType);
        }
    }

    function handlePaste () {
        getClipboardImage().then(file => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                onChange(reader.result);
            };
        })
    };

    return <>
        <Input 
            ref={ref} 
            { ...props } 
            value={value} 
            onPaste={handlePaste}
            onChange={onChange}
        />
        { value && <img className="imageInputPreview" src={value} />}
    </>
}

export default ImageURLInput;