import React from 'react'
import { t } from 'i18next';
import useMenu from 'features/webmapModule/hooks/useMenu';
import { MonthIcon, StationIcon, WeekIcon, YearIcon } from '../compononets/icons';
import { MONTHLY_TRAFFIC, MONTHLY_TRAFFIC_BY_YEAR, STATION_TRAFFIC, TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH, YEARLY_TRAFFIC_BY_YEAR } from '../utils/constants';
import { formatString } from 'utils/format';

function useCrossSectionRoadCountMenu() {

    const mainMenuData = [{
        key: STATION_TRAFFIC, 
        icon: <StationIcon/>,
        label: t('OKKF/StationTraffic')
    }];

    const tableMenu = useMenu({
        data: mainMenuData.map(({key, icon, label, ...props}) => {
            let children;
            switch (key) {
                case STATION_TRAFFIC:
                    children = [{
                        key: YEARLY_TRAFFIC_BY_YEAR,
                        icon: <YearIcon />,
                        label: t('OKKF/AnnualAverageDailyTrafficTimeSeries'),
                    }, {
                        key: MONTHLY_TRAFFIC,
                        icon: <MonthIcon />,
                        label: module => formatString(t('OKKF/MonthlyAverageDailyTrafficForYear'), {year: module?.filters?.stationYear}),
                    },{
                        key: MONTHLY_TRAFFIC_BY_YEAR,
                        icon: <MonthIcon />,
                        label: t('OKKF/MonthlyAverageDailyTrafficTimeSeries'),
                    }];
                    break;
                default:
                    children = [];
            }

            return {
                key,
                icon,
                label,
                ...props,
                children
            }
        }),
        defaultValues: [STATION_TRAFFIC, YEARLY_TRAFFIC_BY_YEAR]
    });

    const chartMenu = useMenu({
        data: mainMenuData.map(({key, icon, label, ...props}) => {
            let children;
            switch (key) {
                case STATION_TRAFFIC:
                    children = [{
                        key: TRAFFIC_BY_MONTH,
                        icon: <YearIcon />,
                        label: t('OKKF/TrafficByMonth'),
                    }, {
                        key: TRAFFIC_BY_DAY_TYPE,
                        icon: <WeekIcon />,
                        label: t('OKKF/TrafficByDayType'),
                    }];
                    break;
                default:
                    children = [];
            }

            return {
                key,
                icon,
                label,
                ...props,
                children
            }
        }),
        defaultValues: [STATION_TRAFFIC, TRAFFIC_BY_MONTH]
    });

    React.useEffect(() => {

        if (tableMenu.values[0] !== chartMenu.values[0]) {
            chartMenu.setValues([tableMenu.values[0]]);
        }

    }, [tableMenu.values[0], tableMenu.values[1]]);

    React.useEffect(() => {

        if (tableMenu.values[0] !== chartMenu.values[0]) {
            tableMenu.setValues([chartMenu.values[0]]);
        }

    }, [chartMenu.values[0], chartMenu.values[1]]);

    return {
        tableMenu,
        chartMenu
    };
};

export default useCrossSectionRoadCountMenu