import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer.js";
import { crossSectionRoadCountColors } from "features/crossSectionRoadCount/utils/colors";

import { countCeilingRound, countNearestRound, interpolate } from "utils/math";


const TRAFFIC_SIZE_STOPS = [
    {
        value: 0, 
        size: 3,
        color: crossSectionRoadCountColors.traffic0,
    },
    {
        value: 1000, 
        size: 3.2,
        color: crossSectionRoadCountColors.traffic1,
    },
    {
        value: 2000, 
        size: 3.4,
        color: crossSectionRoadCountColors.traffic2,
    },
    {
        value: 4000, 
        size: 3.6,
        color: crossSectionRoadCountColors.traffic4,
    },
    {
        value: 6000, 
        size: 3.8,
        color: crossSectionRoadCountColors.traffic6,
    },
    {
        value: 8000, 
        size: 4,
        color: crossSectionRoadCountColors.traffic8,
    },
    {
        value: 10000, 
        size: 4.2,
        color: crossSectionRoadCountColors.traffic10,
    },
    {
        value: 15000, 
        size: 4.5,
        color: crossSectionRoadCountColors.traffic15,
    },
    {
        value: 20000, 
        size: 5,
        color: crossSectionRoadCountColors.traffic20,
    },
    {
        value: 25000, 
        size: 5.5,
        color: crossSectionRoadCountColors.traffic25,
    },
    {
        value: 30000, 
        size: 6,
        color: crossSectionRoadCountColors.traffic30,
    },
    {
        value: 40000, 
        size: 7,
        color: crossSectionRoadCountColors.traffic40,
    },
    {
        value: 50000, 
        size: 8,
        color: crossSectionRoadCountColors.traffic50,
    },
    {
        value: 60000, 
        size: 9,
        color: crossSectionRoadCountColors.traffic60,
    },
    {
        value: 80000, 
        size: 11,
        color: crossSectionRoadCountColors.traffic80,
    },
    {
        value: 100000, 
        size: 13,
        color: crossSectionRoadCountColors.traffic100,
    },
    {
        value: 150000, 
        size: 15,
        label: '150000+',
        color: crossSectionRoadCountColors.traffic150,
    }
]


const SIZE_STOP_MODIFIERS = {
    200000: 0.25,
    50000: 0.5,
    20000: 1,
    10000: 2,
    2000: 4
};


function getStops({ width = 6 } = {}) {
    return Object.entries(SIZE_STOP_MODIFIERS).map(([value, modifier]) => ({
        value,
        size: width * modifier
    })).sortBy('value');
}

function segmentTrafficRenderer({
    field,
    widthModifier = 1,
    vehicleClassCodes = [],
}) {

    const renderer = new ClassBreaksRenderer({
        field
    })

    const modifier = vehicleClassCodes?.length && !(vehicleClassCodes.includes('A1') || vehicleClassCodes.includes('A2') || vehicleClassCodes.includes('A')) 
        ? 0.1 
        : 1;

    TRAFFIC_SIZE_STOPS.forEach(({value, size, color}, index) => {

        const minValue = value * modifier;
        const maxValue = TRAFFIC_SIZE_STOPS.length > index + 1 ? (TRAFFIC_SIZE_STOPS[index+1].value * modifier) - 1 : undefined;

        renderer.addClassBreakInfo({
            minValue,
            maxValue: maxValue || minValue * 1000,
            label: !maxValue ? `${minValue}+` : undefined,
            symbol: {
                type: 'simple-line',
                color: color.array,
                width: size * widthModifier
            }
        })
    })
    return renderer
};


export default segmentTrafficRenderer;
