import { noop } from "utils/constants";

import PageTile from "./PageTile";
import TileGrid from "./TileGrid";


const PageTileGrid = ({
    data,
    endpoint,
    tileHeight,
    imgHeight = 160,
    contentHeight = 24,
    renderItemContent = noop,
    editModalClass,
    deleteModalClass,
}) => {
    return (
        <TileGrid
            data={data}
            renderItem={item => (
                <PageTile 
                    endpoint={endpoint}
                    item={item}
                    height={tileHeight}
                    imgHeight={imgHeight}            
                    editModalClass={editModalClass}
                    deleteModalClass={deleteModalClass}
                >
                    <div style={{margin: '-4px -8px -12px -8px', height: contentHeight}}>
                        {renderItemContent(item)}
                    </div>
                </PageTile>
            )}
        />
    );
};


export default PageTileGrid;
