import 'assets/css/table.css'

import React from 'react'
import { CheckPicker, Form } from 'rsuite'

import { FormGroup } from 'components/forms'
import { noop } from 'utils/constants'

const ColumnSelectorForm = ({
    columnTypes = [],
    visibleColumns = {},
    setVisibleColumns = noop,
}) => {
    return (
        <div className='columnSelector' >
            <Form layout='inline'>
                {columnTypes.filter(columnType => columnType.hideable).map(({name, label, values}) => (
                    <FormGroup
                        key={name}
                        name={name}
                        label={label || name}
                        accepter={CheckPicker}

                        cleanable={false}
                        searchable={false}
                    
                        data={values.map(({key, label, abbrev}) => ({
                            label: label || abbrev || key,
                            value: key,
                            abbrev: abbrev,
                        }))}
                        renderValue={(_, item) => item.length && item.map(({abbrev, label, value}) => abbrev || label || value).join(', ')}
                        value={visibleColumns?.[name] || []}
                        onChange={newValue => setVisibleColumns({...visibleColumns, [name]: newValue})}
                    />
                ))}
            </Form>
        </div>
    )
}


export default ColumnSelectorForm;
