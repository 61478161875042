import { Col,  Row } from "rsuite";

import { CoverCard } from "components/cards";

const TileGrid = ({
    data = [],
    renderItem = item => <CoverCard title={item.name} height={160} />,
    xs = 24,
    sm = 24,
    md = 12,
    lg = 8,
}) => {
    return (
        <Row>
            { data.map( (item, index) => (
                <Col key={index} xs={xs} sm={sm} md={md} lg={lg} style={{marginBottom: 10}}>
                    { renderItem(item) }
                </Col>
            )) }
        </Row>
    );
};


export default TileGrid;
