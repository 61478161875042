import { compareStrings } from "utils/compare";
import { sum } from "utils/math";


function convertVehicleClasses( row, vehicleClasses ) {
    const aggregatedVehicleClasses = Object.values(vehicleClasses)
        .filter(v => v.years.includes(row.year))
        .map(vehicleClass => {
            const aggregatedCodes = vehicleClass?.aggregations?.[`${row.year}`] || []
            const amount = sum(aggregatedCodes.map(c => row.vehicleClasses[c]?.amount || 0)) || row.vehicleClasses[vehicleClass.code]?.amount || 0
            return {
                amount,
                code: vehicleClass.code,
                name: vehicleClass?.name
            }
        })
    
        return Object.fromEntries(aggregatedVehicleClasses.map(v => [v.code, v]))
}


function Station ({
    code,
    stationType,
    areaType,
    counties,
    name,
    roadName,
    internationalRoadName,
    roadCategory,
    direction = 0,
    section,
    startSection,
    endSection,
    length,
    roads,
    averageUnits,
    amount,
    traffic,
    yearlyTrafficByYear,
    monthlyTrafficByYear,
    monthlyTraffic,
    vehicleClasses,
    ...props
}) {
    const station = {
        code: code || `${props?.ASZ}`,
        stationType,
        areaType,
        counties,
        name,
        roadName: roadName || props?.KSZAM,
        internationalRoadName: internationalRoadName || props?.NUTKA,
        roadCategory: roadCategory || props?.KUTKA,
        direction,
        section,
        startSection: startSection || props?.KSZELV?.replaceAll(' ', ''),
        endSection: endSection || props?.VSZELV?.replaceAll(' ', ''),
        length,
        roads,
        averageUnits,
        amount,
        traffic,
        yearlyTrafficByYear,
        monthlyTrafficByYear,
        monthlyTraffic,
        ...props
    };

    if (vehicleClasses) {
        Object.values(yearlyTrafficByYear || {}).forEach((row) => {
            row.vehicleClasses = convertVehicleClasses(row, vehicleClasses)
        })
        Object.values(monthlyTrafficByYear || {}).forEach((row) => {
            row.vehicleClasses = convertVehicleClasses(row, vehicleClasses)
        })
        Object.values(monthlyTraffic || {}).forEach((row) => {
            row.vehicleClasses = convertVehicleClasses(row, vehicleClasses)
        })
    }

    return station;
};


export function compareStations (a, b) {
    return compareStrings(a.code, b.code)
};

export default Station;
