import { onboardCountColors } from "features/onboardCount/utils/colors";


const DEFAULT_COLOR = onboardCountColors.black.array
const DEFAULT_LABEL_SCALE = 10000;

function sectionLabeling ({ 
    field = 'forward', 
    size = 8, 
    offset = 10,
    color = DEFAULT_COLOR,
    minScale = DEFAULT_LABEL_SCALE,
} = {}) {
    return {
        symbol: {
            type: 'text',
            color: onboardCountColors.white.array,
            haloColor: color,
            haloSize: 1.2,
            yoffset: offset,
            font: {
                family: "Avenir Next LT Pro Regular",
                style: "normal",
                weight: "bold",
                size
            }
        },
        labelPosition: 'parallel',
        labelPlacement: '',
        minScale,
        deconflictionStrategy: 'none', // 'static' or 'none'
        labelExpressionInfo: {
            expression: `$feature.${field}`
        },
    };
};


export default sectionLabeling;
