import { arrayStats } from "utils/math";
import { t } from "i18next";
import areaLabeling from "features/onboardCount/lib/arcGISLabels/areaLabeling";
import areaRenderer from "features/onboardCount/lib/arcGISRenderers/areaRenderer";
import stopPlaceTrafficRenderer from "features/onboardCount/lib/arcGISRenderers/stopPlaceTrafficRenderer";
import { onboardCountColors } from "../colors";
import { getTraffic } from "../traffic";
import { Join } from "features/webmap";


function getAreaFeatures(areas, countInterval) {
    if (!areas) return {};

    const areaValues = [];
    const records = areas.map(area => {
        const traffic = getTraffic(area, countInterval);
        areaValues.push(traffic.alight.avg, traffic.board.avg);
        return {
                elementId: area.id,
                elementType: 'area',
                id: area.id,
                geometry: area.geometry,
                name: area.name,
                alight: traffic.alight.avg,
                board: traffic.board.avg,
        };
    });

    
    const stats = arrayStats(areaValues);

    return {
        records,
        stats,
    };
};


function getAreaMarkerJoin({
    records,
    labelStopPlaces = true,
}) {
    return Join({
        name: 'area',
        index: 0,
        records,
        fieldTypes: {
            elementId: 'integer',
            elementType: 'string',
            name: 'string',
            alight: 'integer',
            board: 'integer',
        },
        geometryType: 'polygon',
        definitionExpression: 'alight > 0 OR board > 0',
        labelingInfo: labelStopPlaces && areaLabeling(),
        renderer: areaRenderer(),
        legendEnabled: false,
    })
};


function getAreaAlightJoin({
    records,
    stats,
    minSize = 5,
    maxSize = 100,
}) {
    return Join({
        records: records.map(record => ({
            ...record,
            geometry: {
                x: record.geometry.map(({x}) => x).avg(),
                y: record.geometry.map(({y}) => y).avg(),
            }
        })),
        index: 7,
        fieldTypes: {
            elementId: 'integer',
            alight: 'integer',
        },
        fieldLabels: {
            alight: t('FT/DailyAverageAlightCount'),
        },
        label: t('FT/Areas'),
        legendEnabled: true,
        geometryType: 'point',
        definitionExpression: 'alight > 0',
        renderer: stopPlaceTrafficRenderer({
            field: 'alight',
            minSize,
            maxSize,
            ...stats
        }),
    });
};


function getAreaBoardJoin({
    records,
    stats,
    minSize = 5,
    maxSize = 100,
}) {
    return Join({
        records: records.map(record => ({
            ...record,
            geometry: {
                x: record.geometry.map(({x}) => x).avg(),
                y: record.geometry.map(({y}) => y).avg(),
            }
        })),
        index: 7,
        fieldTypes: {
            elementId: 'integer',
            board: 'integer',
        },
        fieldLabels: {
            board: t('FT/DailyAverageBoardCount'),
        },
        label: t('FT/Areas'),
        legendEnabled: true,
        geometryType: 'point',
        definitionExpression: 'board > 0',
        renderer: stopPlaceTrafficRenderer({
            field: 'board',
            color: onboardCountColors.board,
            minSize,
            maxSize,
            ...stats
        }),
    });
};


export function getAreaJoins(areas, {
    minSize=5,
    maxSize=100,
    labelStopPlaces=true,
} = {}) {
    const { records, stats } = getAreaFeatures(areas);

    return [
        getAreaMarkerJoin({ records, labelStopPlaces }),
        getAreaAlightJoin({ records, stats, minSize, maxSize }),
        getAreaBoardJoin({ records, stats, minSize, maxSize }),
    ];
};

