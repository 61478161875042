import 'assets/css/onboardCount.css';

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import CountInterval from 'features/onboardCount/elements/CountInterval';
import React from 'react'
import { formatDate } from 'utils/format';




const CountIntervalCellContent = ({ value, dayTypes = {} }) => {

    const countInterval = CountInterval(value);
    return <div className='countIntervalCell'>
        { ( countInterval.week || countInterval.month ) && (
            <div class="label">{ countInterval.label }</div>
        )}
        <div>{ formatDate(countInterval.startDate, { dateSeparator: '.' }) } -</div>
        <div>{ formatDate(countInterval.endDate, { dateSeparator: '.' }) }</div>
        <div>{ dayTypes[countInterval.dayTypeId]?.name }</div>
    </div>;
};


export function countIntervalCellCSV  ({value, dayTypes = {}}) {

    const countInterval = CountInterval(value);

    let result = '';
    if ( countInterval?.week || countInterval?.month ) {
        result += `${countInterval.label}\r\n`
    }

    result += `${countInterval?.intervalStr}\r\n` 
        + `${dayTypes[countInterval?.dayTypeId]?.name}`

    return result;
}


export default CountIntervalCellContent;
