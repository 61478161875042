import React from 'react';
import SelectFeatureForm from '../forms/SelectFeatureForm';


const ModuleSidebar = React.forwardRef(({
    module,
    children,
}, ref) => {
    const {
        map,
        getLabel,
        renderItem,
    } = module;

    return (
        <div ref={ref} className="moduleSidebar">
            <SelectFeatureForm 
                map={map} 
                getLabel={getLabel} 
                renderItem={renderItem} 
            />
            {children}
        </div>
    );
});


export default ModuleSidebar;
