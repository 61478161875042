import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext'
import { ModuleToolbar } from 'features/webmapModule';
import React from 'react'
import SearchFilter from './SearchFilter';
import MapViewFilter from './MapViewFilter';
import IntervalFilter from './IntervalFilter';
import StationFilter from './StationFilter';
import { HelpIcon } from 'components/icons';
import { IconButton } from 'rsuite';
import { t } from 'i18next';

const CrossSectionRoadCountToolbar = () => {
    const module = React.useContext(CrossSectionRoadCountContext);

    if (!module?.countId) return;

    return <>
        <ModuleToolbar
            module={module}
            navigation
            applyInstantly
        >
            <MapViewFilter />
            <StationFilter />
            <IntervalFilter />
            <SearchFilter />

            <IconButton
                icon={<HelpIcon />}
                title={t('Map/StartTutorial')}
                onClick={() => module.setTutorialStep(1)}
            />

        </ModuleToolbar>
    </>
}

export default CrossSectionRoadCountToolbar