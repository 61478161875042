
import { t } from "i18next";

import { Join } from "features/webmap";
import { arrayStats } from "utils/math";

import segmentTrafficRenderer from "features/crossSectionRoadCount/lib/arcGISRenderers/segmentTrafficRenderer";
import { LAYER_ORDER, ROAD_SEGMENT_LAYER, STATION_SEGMENT_LAYER, STATION_TRAFFIC_LAYER } from "../constants";
import roadNetworkRenderer from "features/crossSectionRoadCount/lib/arcGISRenderers/roadNetworkRenderer";
import { noop } from "utils/constants";


function getSegmentFeatures(stations, vehicleClassCodes) {
    if (!stations) return {};

    const stationValues = [];
    const records = stations.map(station => {
        stationValues.push(vehicleClassCodes?.length ? station.amount: station.averageUnits); 
        const stationData = {
            stationCode: `${station.code}`,
            averageUnits: Math.round(station.averageUnits),
            amount: Math.round(station.amount),
            startSection: f => f?.['KSZELV'],
            startKm: f => parseInt(f?.['KSZELV']?.split('+')?.[0] || 0),
            startM: f => parseInt(f?.['KSZELV']?.split('+')?.[1] || 0),
            endSection: f => f?.['VSZELV'],
            endKm: f => parseInt(f?.['VSZELV']?.split('+')?.[0] || 0),
            endM: f => parseInt(f?.['VSZELV']?.split('+')?.[1] || 0),
            roadName: f => f?.['KSZAM'],
            roadCategory: f => f?.['KUTKA'],
        };
        
        return stationData
    });

    const stats = arrayStats(stationValues);

    return {
        records,
        stats,
    };
};


function getSegmentTrafficJoin({
    records,
    stats,
    vehicleClassCodes,
    roadCategoryCodes,
    widthModifier = 1,
    ...joinProps
}) {
    const field = vehicleClassCodes?.length ? 'amount' : 'averageUnits';
    const roadCategoryExpression = roadCategoryCodes ? ` AND roadCategory IN (${roadCategoryCodes.map(c => `'${c}'`).join(', ')})` : ''

    return Join({
        name: STATION_TRAFFIC_LAYER,
        index: LAYER_ORDER.indexOf(STATION_TRAFFIC_LAYER),
        records,
        visible: records?.length,
        baseField: 'ASZ',
        targetField: 'stationCode',
        orderBy: [{
            field: 'averageUnits',
            order: 'descending'
        }, {
            field: 'amount',
            order: 'descending'
        }],
        fieldTypes: {
            elementId: 'string',
            elementType: 'string',
            stationCode: 'string',
            startSection: 'string',
            endSection: 'string',
            startKm: 'integer',
            endKm: 'integer',
            startM: 'integer',
            endM: 'integer',
            roadName: 'string',
            roadCategory: 'string',
            averageUnits: 'integer',
            amount: 'integer',

        },
        fieldLabels: {
            stationCode: t('OKKF/StationCode'),
            averageUnits: t('OKKF/AverageUnits'),
            amount: t('OKKF/Amount'),
        },
        label: t('OKKF/DailyAverageRoadSectionCount'),
        geometryType: 'polyline',
        renderer: segmentTrafficRenderer({
            field,
            vehicleClassCodes,
            widthModifier,
            ...stats
        }),
        definitionExpression: `${field} > 0` + roadCategoryExpression,
        ...joinProps
    });
};



function getSegmentBaseJoin({
    setInitialized = noop,
    roadCategoryCodes,
    ...joinProps
}) {
    const roadCategoryExpression = roadCategoryCodes && `roadCategory IN (${roadCategoryCodes.map(c => `'${c}'`).join(', ')})`;

    return Join({
        name: STATION_SEGMENT_LAYER,
        index: LAYER_ORDER.indexOf(STATION_SEGMENT_LAYER),
        records: [],
        getJoinData: (f => ({
            elementId: `${f.attributes.ASZ}`,
            elementType: 'station',
            stationCode: `${f.attributes.ASZ}`,
            startSection: `${f.attributes.KSZELV}`,
            startKm: `${f.attributes.KSZELV?.split('+')[0] || 0}`,
            startM: `${f.attributes.KSZELV?.split('+')[1] || 0}`,
            endSection: `${f.attributes.VSZELV}`,
            endKm: `${f.attributes.VSZELV?.split('+')[0] || 0}`,
            endM: `${f.attributes.VSZELV?.split('+')[1] || 0}`,
            roadName: f.attributes.KSZAM,
            roadCategory: f.attributes.KUTKA,
        })),
        visible: true,
        definitionExpression: roadCategoryExpression,
        baseField: 'ASZ',
        targetField: 'stationCode',
        orderBy: [{
            field: 'KUTKA',
            order: 'ascending'
        }],
        fieldTypes: {
            elementId: 'string',
            elementType: 'string',
            stationCode: 'string',
            startSection: 'string',
            endSection: 'string',
            startKm: 'integer',
            endKm: 'integer',
            startM: 'integer',
            endM: 'integer',
            roadName: 'string',
            roadCategory: 'string',
        },
        fieldLabels: {
            stationCode: t('OKKF/StationCode'),
        },
        label: t('OKKF/RoadSegments'),
        geometryType: 'polyline',
        renderer: roadNetworkRenderer(),
        legendEnabled: false, 
        ...joinProps,
        onRendered: () => {
            if (typeof(joinProps?.onRendered) === 'function') {
                joinProps?.onRendered();
            }
            if (!joinProps?.name) {
                setInitialized(true);
            }
        },   
    });
};


export function getSegmentJoins(data) {

    const {
        stations = [],
        vehicleClassCodes = [],
        roadCategoryCodes,
        widthModifier,
        setInitialized = noop,
        ...joinProps
    } = data;

    
    

    const { records, stats } = getSegmentFeatures(stations, vehicleClassCodes);
    const joins = [
        getSegmentTrafficJoin({ 
            records, 
            stats, 
            widthModifier, 
            vehicleClassCodes, 
            roadCategoryCodes,
            ...joinProps }),
        getSegmentBaseJoin({
            setInitialized,
            roadCategoryCodes,
            widthModifier,
            ...joinProps
        }),
    ]

    console.log({data, joins});
    return joins;
};

