import React from 'react'
import { t } from 'i18next'
import DataTable from 'features/dataTable/components/DataTable'
import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount/context'
import { asRoman } from 'utils/math'
import { MONTHLY_COUNT_METHODS } from 'features/crossSectionRoadCount/utils/constants'
import HeaderCellContent from './HeaderCellContent'


function getColumnTypes (vehicleClasses) {
    return [{
        values: [{
            key: 'year',
            label: t('Year'),
        }, {
            key: 'month',
            label: t('Month'),
            cellProps: {
                defaultAlign: 'right',
            },
        }, {
            key: 'dayType',
            label: t('OKKF/DayType'),
        }, {
            key: 'calculationType',
            label: t('OKKF/CalculationType'),
            cellProps: {
                width: 100,
            }
        }, {
            key: 'averageUnits',
            label: <HeaderCellContent name={t('OKKF/MADT')} unit={t('OKKF/UnitVehiclePerDay')} hint={t('OKKF/MonthlyAverageDailyTraffic')} />,
            csvLabel: t('OKKF/MADT'),
            cellProps: {style: {fontWeight: 'bold'}}
        }, ...Object.values(vehicleClasses || {}).map(vehicleClass => ({
            key: vehicleClass.code,
            label: <HeaderCellContent name={vehicleClass.code} unit={t('OKKF/VehiclePerDay')} hint={vehicleClass.name} />,
            csvLabel: vehicleClass.code,
            cellProps: {
                nullValue: '-'
            },
        }))]
    }]
}


const MonthlyTrafficTable = React.forwardRef(({
    month,
    dayTypeId,
    ...props
}, ref) => {

    const count = React.useContext(CrossSectionRoadCountContext);
    
    const { station } = count?.selectedElements;
    const { vehicleClasses } = count?.elements;
    const  yearlyVehicleClasses = count?.getYearlyVehicleClasses(count?.filters?.stationYear);
    const traffic = month && dayTypeId ? station?.monthlyTrafficByYear : station?.monthlyTraffic

    let exportName = undefined;
    if ( month && dayTypeId ) {
        exportName = `${t('OKKF/MonthlyAverageDailyTrafficTimeSeries')} ${asRoman(month)}. ${count?.elements?.dayTypes[dayTypeId].name}`
    } else {
        exportName = `${t('OKKF/MADT')} ${count?.filters?.stationYear}`
    }

    const data = Object.values(traffic || {})
        .filter(row => (!month || row.month === month) && (!dayTypeId || row.dayType.id === dayTypeId))
        .sortBy(['-year', 'month', 'dayType.id'])
        .map(row => {
            const newRow = {
                year: row.year,
                month: `${asRoman(row.month)}.`,
                dayType: row.dayType?.id,
                amount: Math.round(row.amount),
                averageUnits: Math.round(row.averageUnits),
                calculationType: MONTHLY_COUNT_METHODS[row?.calculationType]
            }

            Object.values(row?.vehicleClasses || {}).forEach(vehicleClass => {
                newRow[vehicleClass.code] = Math.round(vehicleClass.amount || 0)
            });

            return newRow
        })

    const columnTypes = getColumnTypes(month && dayTypeId ? vehicleClasses : yearlyVehicleClasses);

    return (
        <DataTable
            ref={ref}
            title={t('OKKF/YearlyTrafficTableTitle')}
            exportName={exportName}

            hover
            bordered
            cellBordered
            columnWidth={64}
            sorted={false}

            data={data}
            columnTypes={columnTypes}
            getRowKey = {({year, month, dayType}) => `${year}-${month}-${dayType}`}

            rowHeight={40}
            headerHeight={40}
            height={500}
            {...props}
        />
    )
})

export default MonthlyTrafficTable