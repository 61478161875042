import React from "react";
import { List, Modal } from "rsuite";
import { t } from "i18next";

import { AvatarCard } from "components/cards";
import { ESRIIcon } from "components/icons";
import { noop } from "utils/constants";

import ConfigurationItemForm from "./forms/ConfigurationItemForm";


const ConfigurationItemSettings = ({
    configurationItem,
    webMap,
    onChange = noop,
}) => {
    const [editorVisible, setEditorVisible] = React.useState(false);

    const onParamChange = params => onChange({
        id: configurationItem.id,
        params
    });
    
    return <List.Item key={configurationItem?.id} index={configurationItem?.orderNumber}>
        <AvatarCard 
            title={configurationItem?.name}
            actions={[{
                appearance: "primary",
                color: "yellow",
                icon: <ESRIIcon name="edit" />,
                title: t('Edit'),
                onClick: () => setEditorVisible(true)
            }, {
                appearance: "primary",
                color: "red",
                icon: <ESRIIcon name="trash" />,
                title: t('Remove'),
                onClick: () => onParamChange({delete: true})
            }]}
        />

        <Modal 
            size="xs"
            open={editorVisible} 
            onClose={() => setEditorVisible(false)}
        >
            <Modal.Header>
                <Modal.Title>{t('Map/EditConfigItem')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ConfigurationItemForm
                    fluid
                    configurationItem={configurationItem}
                    webMap={webMap}
                    onSubmit={value => {
                        setEditorVisible(false);
                        onParamChange(value);
                    }}
                    onCancel={() => setEditorVisible(false)} />
            </Modal.Body>
        </Modal>
    </List.Item>
};


export default ConfigurationItemSettings;
