import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import { MONTHLY_COUNT_METHODS, ROAD_CATEGORIES, YEARLY_COUNT_METHODS } from 'features/crossSectionRoadCount/utils/constants';
import DataTable from 'features/dataTable/components/DataTable';
import { t } from 'i18next';
import React from 'react'
import { Loader } from 'rsuite';
import RoadBadge from '../badges/RoadBadge';
import { getRoadCategoryFlags } from '../cards/StationCard';
import { HighwayIcon, MotorwayIcon, StationIcon } from '../icons';

const SelectionTable = React.forwardRef(({
    ...props
}, ref) => {
    const count = React.useContext(CrossSectionRoadCountContext);

    const {
        station
    } = (count?.selectedElements || {});

    const {
        stationMonth,
    } = (count?.filters || {});

    const rows = [{
        label: t('OKKF/RoadName'), 
        value: station ? <RoadBadge size='xs' useIcon={false} name={station?.roadName} {...getRoadCategoryFlags(station?.roadCategory)} /> : <Loader />,
    }, {
        label: t('OKKF/RoadCategory'),
        value: station ? <>
            { station?.roadCategory === '1' && <HighwayIcon style={{ marginRight: 4 }} /> }
            { station?.roadCategory === '2' && <MotorwayIcon style={{ marginRight: 4 }} /> }
            { ROAD_CATEGORIES[station?.roadCategory] }
        </> : <Loader />,
    }, ...Object.values(station?.roads || {}).filter(road => road.name !== station?.roadName).map(road => [{
        label: t('OKKF/CommonRoadName'), 
        value: <RoadBadge size='xs' useIcon={false} name={road.name} {...getRoadCategoryFlags(road.category)} />
    }, {
        label: t('OKKF/CommonRoadCategory'),
        value: ROAD_CATEGORIES[road.category]
    }]).reduce((prev, current) => [...prev, ...current], []), {
        label: t('OKKF/StationCode'), 
        value: station ? <>
            <StationIcon style={{marginRight: 4}} />
            { station?.code }
        </> : <Loader />,
    }, {
        label: t('OKKF/StationName'), 
        value: station ? station?.name : <Loader />
    }, {
        label: t('OKKF/MeasuredSection'), 
        value: station ? station?.section : <Loader />
    }, {
        label: t('OKKF/CalculationType'), 
        value: station ? (stationMonth ? MONTHLY_COUNT_METHODS : YEARLY_COUNT_METHODS)?.[station?.calculationType] : <Loader />
    }, ...(stationMonth ? [] : [{
        label: t('OKKF/CalculationDays'), 
        value: station ? station?.calculationDays : <Loader />
    }])
    ]

    return (
        <DataTable 
            ref={ref}
            hover
            bordered
            cellBordered
            autoHeight

            rowHeight={32}
            style={{fontSize: 12}}
            showHeader={false}
            getRowKey={({label}) => label}

            
            data={rows}
            columnWidth={120}
            columnTypes={[{
                name: 'value',
                label: t('Value'),
                values: [{
                    key: 'value',
                    label: t('Value'),
                    cellProps: {
                        defaultAlign: 'right'
                    }
                }]
            }]}
            rowHeaderKey='label'
            sorted={false}    
            renderRowHeader={(rowData, csv) => rowData?.label}

        />
    )
});

export default SelectionTable