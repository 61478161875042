import DataChart, { Dataset } from 'features/chart/components/DataChart';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import { onboardCountColors } from 'features/onboardCount/utils/colors';
import { getChartLabels } from 'features/onboardCount/utils/labels';
import { secondsToString } from 'features/onboardCount/utils/time';
import { getEmptyTraffic, getTraffic } from 'features/onboardCount/utils/traffic';
import { t } from 'i18next';
import React from 'react'
import { formatString } from 'utils/format';

const DailyTrafficDistributionChart = React.forwardRef(({
    ticksPerHour = 1,
    rollingHourCount,
    dayStartHour = 2,
    dayEndHour = 27,
    ...props
}, ref) => {
    
    const count = React.useContext(OnboardCountContext);

    const {
        journeyStops = {},
    } = count?.elements || {};

    const {
        countInterval,
        section,
        area,
        stopPlace,
    } = count?.selectedElements || {};

    const intervalLength = 3600 / ticksPerHour;
    const countData = {};
    for ( let i = dayStartHour*ticksPerHour; i < dayEndHour*ticksPerHour; i++) {
        countData[`${i*intervalLength}`] = {
            time: i*intervalLength,
            traffic: getEmptyTraffic()
        };
    };

    Object.values(journeyStops).forEach(stop => {
        const alightTime = `${Math.floor((stop?.arrivalTime || stop?.departureTime || stop?.journey?.startTime) / intervalLength) * intervalLength}`;
        const boardTime = `${Math.floor((stop?.departureTime || stop?.arrivalTime || stop?.journey?.startTime) / intervalLength) * intervalLength}`;
        const trafficData = getTraffic(stop, countInterval.str);

        countData[alightTime].traffic.alight.avg -= trafficData?.alight?.avg || 0;
        countData[alightTime].traffic.alight.min -= trafficData?.alight?.min || 0;
        countData[alightTime].traffic.alight.max -= trafficData?.alight?.max || 0;

        countData[boardTime].traffic.board.avg += trafficData?.board?.avg || 0;
        countData[boardTime].traffic.board.min += trafficData?.board?.min || 0;
        countData[boardTime].traffic.board.max += trafficData?.board?.max || 0;

        countData[boardTime].traffic.onward.avg += trafficData?.onward?.avg || 0;
        countData[boardTime].traffic.onward.min += trafficData?.onward?.min || 0;
        countData[boardTime].traffic.onward.max += trafficData?.onward?.max || 0;
    })

    const data = Object.fromEntries(
        Object.entries(countData).map(([key, traffic], index) => {
            const item = {
                traffic: getEmptyTraffic(),
                time: traffic.time,
            };

            ['alight', 'board', 'onward'].forEach(attr => {
                ['avg', 'min', 'max'].forEach(stat => {
                    let value = 0
                    if (rollingHourCount) {
                        for (let i=0; i < ticksPerHour; i++) {
                            value += (Object.values(countData)[index+i]?.traffic?.[attr]?.[stat] || 0);
                        }
                        value = Math.round(value / ticksPerHour);
                    } else {
                        value = traffic.traffic?.[attr]?.[stat] || 0;
                    }
                    item.traffic[attr][stat] = value

                })
            })
            return [key, item];
        })
    );

    const datasets = [];

    if (section) {
        datasets.push(Dataset({
            name: 'onward',
            label: t('FT/DailyAverageCrossSectionCount'),
            getValue: item => item?.traffic?.onward?.avg,
            color: onboardCountColors.onward,
            borderWidth: 1,
        }));
    } else {
        if (area || stopPlace) {
            datasets.push(   
                Dataset({
                    name: 'alight',
                    label: t('FT/DailyAverageAlightCount'),
                    getValue: item => item?.traffic?.alight?.avg,
                    color: onboardCountColors.alight,
                    borderWidth: 1,
                }),
            )

        }
        datasets.push(
            Dataset({
                name: 'board',
                label: t('FT/DailyAverageBoardCount'),
                getValue: item => item?.traffic?.board?.avg,
                color: onboardCountColors.board,
                borderWidth: 1,
            })
        );
    }

    return (
        <DataChart 
            ref={ref}
            data={Object.values(data).sortBy('time')}
            sets={datasets}
            title={formatString(rollingHourCount ? t('FT/RollingDailyTrafficDistributionChartTitle') : t('FT/DailyTrafficDistributionTitle'), getChartLabels(count))}
            getLabel={item => secondsToString(item.time)}
            stackedX
            getTickLabelY={val => Math.abs(val)}
            barPercentage={1}
            categoryPercentage={1}
            animation={false}
            plugins={{
                tooltip: {
                    callbacks: {
                        title: ctx => {
                            return `${ctx[0].label}-${secondsToString(Object.values(data)[ctx[0].dataIndex < ctx[0].dataset.data.length - 1 ? ctx[0].dataIndex + 1 : 0].time)}`
                        },
                        label: ctx => {
                            return `${ctx.dataset.label}: ${Math.abs(ctx.dataset.data[ctx.dataIndex])}`
                        }
                    }
                }
            }}
            scales={{
                x: {
                    stacked: true,
                    offset: true,
                    grid: {
                        offset: true,
                        display: false,
                        drawOnChartArea: true,
    
                    },
                    ticks: {
                        align: 'center',
                        minRotation: 0,
                        maxRotation: 0,
                        callback: (val, index) => index % ticksPerHour === 0 ? secondsToString(Object.values(data)[index].time) : '',
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: section ? t('FT/CrossSectionTraffic') : t('FT/AlightBoardCount')
                    },
                    ticks: {
                        callback: (value) => {
                            return `${Math.abs(value)}`;
                        },
                    },

                }
            }}
            {...props}
        />
    )
});

export default DailyTrafficDistributionChart