import React from 'react';
import { t } from "i18next";

import { PageList, PageListItem } from "components/lists";
import { PaginatedPanel } from "features/pagination";

import { deleteBlogEntry, getBlogEntryList } from "../blogAPI";
import BlogEntryModal from "./BlogEntryModal";
import { AuthContext } from "features/auth";
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal';


const BlogEntryListPanel = ({
    tag,
    renderContent,
    defaultPageSize,
    pageSizeOptions,
    ...props
}) => {
    
    const { adminVisible } = React.useContext(AuthContext);
    const [update, setUpdate] = React.useState(false);

    function forceUpdate() {
        setUpdate(!update);
    }
    
    
    const DeleteModal = ({
        item,
        ...props
    }) => (
        <ConfirmDeleteModal
            onDelete={() => deleteBlogEntry(item?.id).then(forceUpdate)}
            itemLabel={t('BlogEntry')}
            {...props} />  
    )
    

    return (
        <PaginatedPanel 
            title={t('Blog/Entries')}
            createModalClass={adminVisible && BlogEntryModal}
            createValues={{
                tags: tag ? [tag] : []
            }}
            getItems={({page, pageSize}) => getBlogEntryList({
                tag,
                page,
                pageSize,
            })}
            renderItems={({data, pageSize}) => <PageList 
                data={data}
                pageSize={pageSize}
                renderItem={(item, index) => 
                    <PageListItem 
                        key={index}
                        endpoint={"blog"}
                        item={item}
                        usePlaceholder={false}
                        getTitle={item => item.title}
                        getSubtitle={item => item.publicFrom}
                        getChildren={item => renderContent 
                            ? <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                            : undefined}
                        editModalClass={adminVisible && BlogEntryModal}
                        deleteModalClass={adminVisible && DeleteModal}
                        onModalClose={forceUpdate}
                    />}
            />}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            {...props}
        />
    )
};


export default BlogEntryListPanel;
