import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Form, SelectPicker } from "rsuite";
import { t } from "i18next";

import { HTMLInput } from 'components/inputs';
import { noop } from "utils/constants";


const PopupForm = ({
    columns = [],
    title = '',
    content = '',
    onSubmit = noop,
    onCancel = noop,
    ...props
}) => {
    const titleRef = React.useRef(null);
    const contentRef = React.useRef(null);

    return (
        <Form {...props}>
            <Form.Group controlId="title">
                <Form.ControlLabel>{ t('Title') }</Form.ControlLabel>
                <Form.Control 
                    name="titleAttribute" 
                    placeholder={t('Map/TitleAttribute')}
                    accepter={SelectPicker}
                    cleanable={false}
                    data={columns}
                    value={null}
                    onChange={(value) => {
                        titleRef.current.insertContent(`{${value}}`)
                    }} />
                <Form.Control 
                    name="title"   
                    accepter={HTMLInput}
                    editorRef={titleRef}
                    value={title} />
            </Form.Group>
            
            <Form.Group controlId="content">
                <Form.ControlLabel>{ t('Content') }</Form.ControlLabel>
                <Form.Control 
                    name="contentAttribute" 
                    placeholder={ t('Map/ContentAttribute') }
                    accepter={SelectPicker}
                    cleanable={false}
                    data={columns}
                    value={null}
                    onChange={(value) => {
                        contentRef.current.insertContent(`{${value}}`)
                    }} />
                <Form.Control 
                    name="content"   
                    accepter={HTMLInput}
                    editorRef={contentRef}
                    value={content} />
            </Form.Group>

            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit({
                        title: titleRef.current.getContent(),
                        content: contentRef.current.getContent()
                    })}>
                        { t('Save') }
                    </Button>
                    <Button appearance="subtle" color="red" onClick={() => onSubmit({})}>
                        { t('Remove') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>

        </Form>
    );
};


export default PopupForm;
