import 'assets/css/form.css';

import React from 'react';
import { Button, ButtonToolbar, Form, Input } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormGroup } from 'components/forms';
import { noop } from 'utils/constants';


const ForgotPasswordForm = ({
    layout,
    loading, 
    onSubmit = noop
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState();

    return (
        <Form layout={layout}>
            <FormGroup>
                <h5>{ t('Security/ForgotPassword') }</h5>
            </FormGroup>
            <FormGroup
                name="email"
                label={t('E-mail')}
                accepter={Input}
                type="email"
                value={email}
                onChange={setEmail}
            />
            <FormGroup>
                <ButtonToolbar>
                    <Button appearance="primary" loading={loading} onClick={() => onSubmit({email})}>
                        { t('Security/SendEmail') }
                    </Button>
                    <Button appearance="subtle" onClick={() => navigate(-1)}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </FormGroup>
        </Form>
    );    
};

export default ForgotPasswordForm