import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";
import { Message, toaster } from "rsuite";

import CenteredPanelPage from "layouts/CenteredPanelPage";

import AuthContext from "../context/AuthContext";
import LoginForm from "../components/forms/LoginForm";


const LoginPage = () => {
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();
    const { user, login } = useContext(AuthContext);
    const {to} = useParams();

    React.useEffect(() => {
        if (user) {
            navigate(to || '/')
        }
    }, [user])

    function submit({ username, password }) {
        setLoading(true)
        login({ username, password })
        .then(() => {
            setLoading(false);
            navigate(to || '/');
        })
        .catch( err => {
            setLoading(false);
            toaster.push(<Message showIcon type="error">
                {t(`API/${err.message}`)}
            </Message>)
        })
    }

    return (
        <CenteredPanelPage>
            <LoginForm loading={loading} onSubmit={submit} />
        </CenteredPanelPage>
    );
};

export default LoginPage;