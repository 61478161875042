import { t } from "i18next"

export const ALL_MODULE_TYPES = 'all';
export const ONBOARD_COUNT_MODULE_TYPE = 'onboard_count';
export const CROSS_SECTION_ROAD_COUNT_MODULE_TYPE = 'cross_section_road_count';
export const MAP_VIEWER_MODULE_TYPE = 'map_viewer';
export const WEBSITE_MODULE_TYPE = 'website'

export function getModuleTypeLabels () {
    return {
        [ALL_MODULE_TYPES]: t('All'),
        [ONBOARD_COUNT_MODULE_TYPE]: t('Map/OnboardCount'),
        [CROSS_SECTION_ROAD_COUNT_MODULE_TYPE]: t('Map/CrossSectionRoadCount'),
        [MAP_VIEWER_MODULE_TYPE]: t('Map/MapViewer'),
        [WEBSITE_MODULE_TYPE]: t('Website')
    }
}

export function getModuleTypeName(name) {
    return getModuleTypeLabels()?.[name] || t('Generic')
}