import { deleteAPI, getAPI, postAPI } from "utils/api"

export function getBlogEntryData (id) {

    return getAPI(`blog_entries/${id}`);

};

export function createBlogEntry (data) {

    return postAPI(`blog_entries/create`, data)

};

export function postBlogEntry (id, data) {

    return postAPI(`blog_entries/${id}`, data)

};

export function deleteBlogEntry (id) {

    return deleteAPI(`blog_entries/${id}`)

};
    
export function getBlogEntryList ({
    tag,
    page,
    pageSize
} = {}) {
    return getAPI(`blog_entries`, {
        tag,
        page,
        pageSize
    });
};
