import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Grid, Nav, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";
import SimplePage from "layouts/SimplePage";
import { WebMapTilesPanel } from "features/webmap";

import useBlogEntry from "../hooks/useBlogEntry";
import { t } from "i18next";
import BlogEntryListPanel from "../components/BlogEntryListPanel";
import { ALL_MODULE_TYPES, getModuleTypeLabels } from "../utils";
import { AuthContext } from "features/auth";





function BlogPage () {
    
    const { user } = React.useContext(AuthContext);
    const [moduleType, setModuleType] = React.useState(ALL_MODULE_TYPES);

    if ( !user?.blogActive ) {
        return
    }

    return (
        <SimplePage>
            
            <Jumbotron>
                <PageTitle title={t('Blog')} />
            </Jumbotron>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        
                        <Nav appearance="subtle" activeKey={moduleType} onSelect={setModuleType}>
                            { Object.entries(getModuleTypeLabels()).filter(([name, label]) => user?.blogTags?.includes(name)).map(([name, label]) => (
                                <Nav.Item eventKey={name} >
                                    {label}
                                </Nav.Item>
                            )) }
                        </Nav>
                        <BlogEntryListPanel 
                            tag={moduleType === ALL_MODULE_TYPES ? undefined : moduleType}
                            renderContent
                            defaultPageSize={5}
                        />
                    </Col>
                </Row>
            </Grid>  

        </SimplePage>
    )
};


export default BlogPage;
