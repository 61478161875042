import React from 'react';
import { t } from 'i18next';

import Fullscreen from '@arcgis/core/widgets/Fullscreen';
import Legend from '@arcgis/core/widgets/Legend';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import * as watchUtils from '@arcgis/core/core/watchUtils';
import { logEvent } from 'features/logging';


function useArcGISMap({
    containerId, 
    webmap,
}) {
    const [ configurations, setConfigurations ] = React.useState();
    const [ exportName, setExportName ] = React.useState('');
    const [ selection, setSelection ] = React.useState([]);
    const [ selectionIndex, setSelectionIndex ] = React.useState();
    const [ tasks, setTasks ] = React.useState({});
    const elementTypeOrderRef = React.useRef([]);

    // ArcGIS JS refs

    const arcGISMapRef = React.useRef({
        configurations, 
        setConfigurations,
        exportName, 
        setExportName,
        selection, 
        selectionIndex,
        setSelectionIndex,
        elementTypeOrderRef,
        joinLayers: {},
    });

    const arcGISMap = arcGISMapRef.current;
    
    arcGISMap.configurations = configurations;
    arcGISMap.selection = selection;
    arcGISMap.selectionIndex = selectionIndex;
    arcGISMap.exportName = exportName;

    const mapRef = React.useRef();
    arcGISMap.map = mapRef.current;
    
    const viewRef = React.useRef();
    arcGISMap.view = viewRef.current;
    
    const fullscreenRef = React.useRef();
    arcGISMap.fullscreen = fullscreenRef.current;

    const legendRef = React.useRef();
    arcGISMap.legend = legendRef.current;

    const onClickRef = React.useRef(null);
    const highlightRef = React.useRef(null);

    // View methods
    
    arcGISMap.zoomToExtent = function zoomToExtent() {
        const fullExtent = arcGISMap.map.layers.at(0)?.fullExtent;
        if (fullExtent) {
            arcGISMap.view.goTo( fullExtent );
        }
    };

    arcGISMap.queryFeatures = async function queryFeatures({
        layer,
        ...params
    } = {}) {
        const l = typeof layer === 'string' ? arcGISMap.joinLayers?.[layer] : layer;
        if (!l) return [];

        const query = l.createQuery();
        query.where = params?.where || Object.entries(params)
            .map(([name, value]) => {
                switch (typeof value) {
                    case 'string':
                        return `${name}='${value}'`;
                    case 'function':
                        return value();
                    default:
                        return `${name}=${value}`
                }
            })
            .join(' AND ');

        query.orderByFields = params?.orderByFields;

        return l.queryFeatures( query )
            .then( result => result?.features );
    }
    
    async function getFeature(layer, params) {
        return arcGISMap.queryFeatures({
            layer,
            ...params
        }).then(features => features[0]);
    };

    function panToFeature(f) {
        arcGISMap.view.goTo(f?.geometry?.extent?.center || f?.geometry);
    };

    function zoomToFeature(f) {
        arcGISMap.view.goTo(f?.geometry?.extent);
    };

    function highlightFeature(f, clearPrev = true) {
        if ( !f && highlightRef.current ) {
            highlightRef.current.remove();
            highlightRef.current.feature = undefined
        }

        if (f) {
            const currentAttributes = highlightRef.current?.feature?.attributes || {};
            if (
                currentAttributes?.FID !== f?.attributes?.FID ||
                currentAttributes?.Id !== f?.attributes?.Id ||
                currentAttributes?.elementId !== f?.attributes?.elementId ||
                currentAttributes?.ID !== f?.attributes?.ID ||
                currentAttributes?.objectId !== f?.attributes?.objectId
            ) {
                arcGISMap.view.whenLayerView(f.layer).then(layerView => {
                    if (clearPrev && highlightRef.current) {
                        highlightRef.current.remove();
                    }
                    
                    if (typeof layerView?.highlight === 'function') {
                        highlightRef.current = layerView.highlight(f);
                        highlightRef.current.feature = f
                    }
                });
            }
           
        }

    };

    function setSelectionFromFeatures(features) {
        function getSelectionIndex(f) {
            return elementTypeOrderRef.current.indexOf(f?.attributes?.elementType)
        }

        const selection = features
            .filter(f => getSelectionIndex(f) > -1)
            .sort((a, b) => getSelectionIndex(a) - getSelectionIndex(b));
        
        setSelection(selection);
        return selection
    }

    arcGISMap.selectFeature = function selectFeature({
        layer, 
        zoomTo = true, 
        panTo = true, 
        ...params
    } = {}) {
        getFeature(layer, params).then(f => {
            if (!f) {
                console.log('Clearing map selection')
                setSelection([]);
            } else {
                const indexedSelection = selection.map((s, i) => ({key: `${s?.attributes?.elementType}-${s?.attributes?.elementId}`, index: i}));
                const filteredSelection = indexedSelection.filter(s =>  s.key === `${f?.attributes?.elementType}-${f?.attributes?.elementId}`);
                const newIndex = filteredSelection[0]?.index;
                if (newIndex !== undefined) {
                    console.log('Changing selection index')
                    setSelectionIndex(newIndex);
                } else {
                    console.log('Changing selection')
                    setSelection ([f]);
                    setSelectionIndex(0);
                }
            }

            highlightFeature(f);

            if (zoomTo && f) {
                zoomToFeature(f);
            } else if (panTo && f) {
                panToFeature(f);
            }
        });
    };

    arcGISMap.selectIntersectingFeatures = function selectIntersectingFeatures({
        layer,
        baseLayer,
        zoomTo = true, 
        panTo = true,
        ...params
    } = {}) {

        const l = typeof layer === 'string' ? arcGISMap.joinLayers?.[layer] : layer;
        const baseL = typeof baseLayer === 'string' ? arcGISMap.joinLayers?.[baseLayer] : baseLayer;
        if (!l || !baseL) {
            highlightFeature();
            return;
        };

        const orderByFields = params?.orderByFields;
        delete params.orderByFields;

        getFeature(baseL, params).then((feature = {}) => {
            const geometry = feature?.geometry;

            if (!geometry) {
                console.log('Clearing map selection')
                setSelection([]);
            } else {
                zoomToFeature(feature);
                l.queryFeatures({
                    spatialRelationship: "intersects",
                    geometry,
                    outFields: ['*'],
                    returnGeometry: true,
                    orderByFields,
                }).then(results => setSelectionFromFeatures(results.features));
            }
        })
        
    };

    arcGISMap.zoomToSelection = function zoomToSelection() {
        zoomToFeature(selection?.[selectionIndex]);
    };

    arcGISMap.exportImage = function exportImage() {
        arcGISMap.view.takeScreenshot({}).then(screenshot => {
            var a = document.createElement("a");
            a.href = screenshot.dataUrl;
            a.download = (exportName?.length ? `${exportName} - ` : '') + `${t('Map')}.png`;
            a.click();
        });
    };

    // Task management

    function finnishTasks(module) {
        if ( Object.values(tasks?.[module] || {}).length && !Object.values(tasks?.[module] || {}).filter(([d, t]) => t > d).length) {
            setTasks(prev => ({
                ...prev,
                [module]: {}
            }));
        }
    }

    function getNewTasks (prev, {
        taskName,
        done = 0,
        total = 0,
        module = 'webmap',
        extend = false
    }) {

        const [currentDone, currentTotal] = prev?.[module]?.[taskName] || [0, 0];

        const progress = extend ? [currentDone + done, currentTotal + total] : [done, total];

        const newTasks = {
            ...prev,
            [module]: {
                ...(prev?.[module] || {}),
                [taskName]: progress
            }
        };
        return newTasks;
    }


    arcGISMap.tasks = {...tasks};
    arcGISMap.setTaskProgress = function setTaskProgress(taskName, done=0, total=0, module='webmap') {
        setTasks(tasks => getNewTasks(tasks, {
            taskName,
            done,
            total,
            module,
            extend: false,
        }))
        
    }

    arcGISMap.addTaskProgress = function addTaskProgress(taskName, done = 0, total = 0, module = 'webmap') {
        setTasks(tasks => getNewTasks(tasks, {
            taskName,
            done,
            total,
            module,
            extend: true,
        }))
    }

    // Effects

    React.useEffect(() => {
        if (!webmap?.id) return;

        console.log('WebmapId changed...')
        
        mapRef.current = new Map();
        arcGISMap.map = mapRef.current;
        
        viewRef.current = new MapView({ map: arcGISMap.map });
        arcGISMap.view = viewRef.current;
        
        fullscreenRef.current = new Fullscreen({ 
            id: 'fullscreen',
            view: arcGISMap.view
        });
        arcGISMap.fullscreen = fullscreenRef.current;

        legendRef.current = new Legend({
            id: 'legend',
            label: t('Map/Legend'),
            view: arcGISMap.view
        });
        arcGISMap.legend = legendRef.current;
        
    }, [webmap?.id])

    React.useEffect(() => {
        if (!webmap?.id) return;

        if (webmap?.view) {
            console.log('Updating view...');

            const {long, lat, zoom, maxZoom} = webmap.view;
            arcGISMap.view.center = [long, lat];
            arcGISMap.view.constraints.minZoom = zoom;
            arcGISMap.view.constraints.maxZoom = maxZoom;
        }
    }, [webmap?.view]);

    React.useEffect(() => {
        if (!webmap?.id) return;

        console.log('Updating legend...');
        if (webmap?.legend) {
            const { label, position } = webmap.legend;
            arcGISMap.legend.label = label;

            arcGISMap.view.ui.remove(arcGISMap.legend);
            arcGISMap.view.ui.add(arcGISMap.legend, position);
        }
    }, [webmap?.legend]);

    React.useEffect(() => {
        if (!webmap?.id) return;

        console.log('Updating basemap...');
        arcGISMap.map.basemap = webmap?.basemap;

        if (arcGISMap.map?.basemap) {
            // TODO add parameter for removing reference layers
            watchUtils.when(arcGISMap.map.basemap, 'loaded', () => {
                arcGISMap.map.basemap.referenceLayers.items.forEach(refLayer => {
                    refLayer.visible = false;
                });
            });
        }
    }, [webmap?.basemap]);

    React.useEffect(() => {
        if (!webmap?.id) return;

        console.log('Setting up view container...')
        arcGISMap.view.container = containerId;
        arcGISMap.view.ui.add(arcGISMap.fullscreen, 'top-left');
    }, [containerId, arcGISMap.view]);

    React.useEffect(() => {
        if (!webmap?.id) return;

        if (onClickRef.current) {
            onClickRef.current.remove();
        }

        onClickRef.current = arcGISMap.view.on('click', evt => {
            logEvent('mapClick')
            arcGISMap.view.hitTest(evt.screenPoint).then(({results}) => {
                const features = results.map(({graphic}) => graphic);
                const selection = setSelectionFromFeatures(features);
                if (selection[0]) {
                    highlightFeature(selection[0])
                }
                logEvent('mapClickHit', selection[0])
            });
        });


    }, [arcGISMap.view]);

    React.useEffect(() => {
        if (!webmap?.id) return;

        setSelectionIndex(selection?.length ? 0 : undefined)
    }, [selection]);

    React.useEffect(() => {
        if (!webmap?.id) return;
        
        if (selectionIndex === undefined) {
            console.log('Clearing map selection')
            setSelection([]);
        } else if (
            selectionIndex < 0 || 
            selectionIndex >= selection?.length
        ) {
            setSelectionIndex(0);
        }
    }, [selectionIndex])

    React.useEffect(() => {
        if (!webmap?.id) return;
        const configData = {};
        (webmap?.configurations || []).forEach(({id, items}) => {
            configData[id] = items[0]?.id;
        });
        setConfigurations(configData);
    }, [webmap?.configurations])

    React.useEffect(() => {
        Object.keys(tasks).map(finnishTasks);
    }, [tasks])

    return webmap?.id && mapRef.current ? arcGISMapRef.current : undefined;
};


export default useArcGISMap;
