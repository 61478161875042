import React from 'react'
import { DownwardIcon, SectionIcon, UpwardIcon } from '../icons'
import { t } from 'i18next'
import { AvatarCard } from 'components/cards';


const SectionCard = ({
    size="md",
    id,
    name,
    direction = 0
}) => {
    return (
        <AvatarCard 
            size={size}
            icon={<SectionIcon />}
            title={<>
                { name || `${t('FT/Section')}${ id ? ` ${id}` : ''}` }
                {/* { direction !== 2 && <UpwardIcon /> }
                { direction !== 1 && <DownwardIcon /> } */}
            </>}
        /> 
    );
};


export default SectionCard;
