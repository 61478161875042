import { onboardCountColors } from "features/onboardCount/utils/colors"


const DEFAULT_COLOR = onboardCountColors.stopPlace.array;
const DEFAULT_SECONDARY_COLOR = onboardCountColors.secondary.array;


function stopPlaceMarkerRenderer ({ 
    color=DEFAULT_COLOR, 
    secondaryColor=DEFAULT_SECONDARY_COLOR, 
    size=9 
} = {}) {
    return {
        type: 'simple',
        symbol: {
            type: "simple-marker",
            size,
            color: secondaryColor,
            outline: {
                color: color,
                width: size / 4,
            },
        },
    };
};


export default stopPlaceMarkerRenderer;
