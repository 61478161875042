function VehicleClass ({
    code,
    name,
    ...props
}) {
    const vehicleClass = {
        id: code,
        code,
        name,
        label: `${code} - ${name}`,
        ...props
    };

    return vehicleClass;
};

export default VehicleClass;
