import { getAPI } from "utils/api"

export function getOrganizationData (id) {
    
    return getAPI(`organizations/${id}`);
};

export async function getOrganizationList ({
    name,
    page,
    pageSize
} = {}) {
    
    return getAPI('organizations', {
        name,
        page,
        pageSize,
    });
};

