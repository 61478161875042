import React from "react";
import getJoins from "../utils/joins/getJoins";
import { noop } from "utils/constants";
import { checkObjectValues } from "utils/compare";


function useOnboardCountJoins ({
    moduleParameters = {},
    viewPreferences = {},
    elements = {},
    runningQueries = [],
    setJoins = noop,
}) {
    const joinsRef = React.useRef();
    const prevElements = React.useRef({});

    const {
        stopPlaceElementId,
        sectionElementId
    } = moduleParameters;

    const {
        minTrafficSize = 1,
        maxTrafficSize = 100,
        labelStopPlaces,
        labelSections,
    } = viewPreferences;

    const prevViewPreferences = React.useRef(viewPreferences);

    const {
        areas = {},
        stopPlaces = {},
        sections = {},
    } = elements;

    const queryCheck = ['areas', 'stopPlaces', 'sections'].every(name => !runningQueries.includes(name));
    const dataCheck = !checkObjectValues(elements, prevElements.current);
    const viewCheck = !checkObjectValues(viewPreferences, prevViewPreferences.current);

    React.useEffect(() => {
        if ( (queryCheck && dataCheck) || viewCheck ) {
            console.log('Updating joins...');
            prevElements.current = elements;
            prevViewPreferences.current = viewPreferences

            joinsRef.current = getJoins({
                stopPlaceElementId,
                sectionElementId,
                elements,
                minTrafficSize,
                maxTrafficSize,
                labelStopPlaces,
                labelSections,
            });

            setJoins(joinsRef.current);
        };

    }, [areas, stopPlaces, sections, minTrafficSize, maxTrafficSize, labelStopPlaces]);

    return joinsRef.current;
};


export default useOnboardCountJoins;
