import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";
import borderCrossingSymbol from "features/crossSectionRoadCount/data/borderCrossingSymbol.json";

const borderCrossingRenderer = () => {
    return {
        type: 'simple',
        symbol: new CIMSymbol({
            data: {
                type: 'CIMSymbolReference',
                symbol: borderCrossingSymbol,
            }
        }),
        visualVariables: [{
            type: "size",
            valueExpression: "$view.scale",
            stops: [{
                value: 2000000,
                size: 6
            }, {
                value: 750000,
                size: 8
            }, {
                value: 150000,
                size: 12
            },{
                value: 25000,
                size: 16
            }],
        }]
    }
};

export default borderCrossingRenderer;