import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, DatePicker, Divider, Form, SelectPicker, TreePicker } from "rsuite";
import { t } from "i18next";

import { noop } from "utils/constants";
import { FormGroup } from 'components/forms';
import { PlusIcon } from 'components/icons';


const CrossSectionRoadCountModuleForm = ({ 
    webMap,
    onSubmit = noop,
    onCancel = noop,
    ...props 
}) => {

    const defaultDateStr = '1970-01-01';
    const defaultElement = {[defaultDateStr]: undefined};

    const [segments, setSegments] = React.useState(webMap?.modules?.crossSectionRoadCount?.elements?.segments || defaultElement);
    const [roadNetwork, setRoadNetwork] = React.useState(webMap?.modules?.crossSectionRoadCount?.elements?.roadNetwork || defaultElement);
    const [borderCrossings, setBorderCrossings] = React.useState(webMap?.modules?.crossSectionRoadCount?.elements?.borderCrossings || defaultElement);
    const [settlements, setSettlements] = React.useState(webMap?.modules?.crossSectionRoadCount?.elements?.settlements || defaultElement);
    const [roadSections, setRoadSections] = React.useState(webMap?.modules?.crossSectionRoadCount?.elements?.roadSections || defaultElement);

    const elementOptions = webMap?.elements.map(element => ({
        label: element.name,
        value: element.id
    }))

    const submitData = {
        segments,
        roadNetwork,
        borderCrossings,
        settlements,
        roadSections,
    }

    return (
        <Form  {...props}>

            { Object.entries(segments || {}).map(([dateStr, elementId]) => (
                <FormGroup 
                    key={`segments-${dateStr}`}
                    name={`segments-${dateStr}`}
                    label={`${t('OKKF/Segments')} (${dateStr})`}
                    accepter={SelectPicker}
                    data={elementOptions}
                    value={elementId}
                    cleanable={ dateStr !== defaultDateStr }
                    onClean={ () => {
                        delete segments?.[dateStr];
                        setSegments({...segments});
                    }}
                    onChange={ value => setSegments({
                        ...segments,
                        [dateStr]: value
                    })}
                />
            )) }

            <FormGroup 
                name='addSegments'
                label={ t('OKKF/AddSegmentsElement') }
                accepter={DatePicker}
                value={null}
                oneTap
                placeholder={ t('OKKF/SelectStartDate') }
                onChange={value => {
                    setSegments({
                        ...segments,
                        [value.toISOString().slice(0, 10)]: undefined
                    })
                }}
            />

            <Divider />

            { Object.entries(roadNetwork || {}).map(([dateStr, elementId]) => (
                <FormGroup 
                    key={`segments-${dateStr}`}
                    name={`roadNetwork-${dateStr}`}
                    label={`${t('OKKF/RoadNetwork')} (${dateStr})`}
                    accepter={SelectPicker}
                    data={elementOptions}
                    value={elementId}
                    cleanable={ dateStr !== defaultDateStr }
                    onClean={ () => {
                        delete roadNetwork?.[dateStr];
                        setRoadNetwork({...roadNetwork});
                    }}
                    onChange={ value => setRoadNetwork({
                        ...roadNetwork,
                        [dateStr]: value
                    })}
                />
            )) }

            <FormGroup 
                name='addRoadNetwork'
                label={ t('OKKF/AddRoadNetworkElement') }
                accepter={DatePicker}
                value={null}
                oneTap
                placeholder={ t('OKKF/SelectStartDate') }
                onChange={value => {
                    setRoadNetwork({
                        ...roadNetwork,
                        [value.toISOString().slice(0, 10)]: undefined
                    })
                }}
            />

            <Divider />

            { Object.entries(borderCrossings || {}).map(([dateStr, elementId]) => (
                <FormGroup 
                    key={`segments-${dateStr}`}
                    name={`borderCrossings-${dateStr}`}
                    label={`${t('OKKF/BorderCrossings')} (${dateStr})`}
                    accepter={SelectPicker}
                    data={elementOptions}
                    value={elementId}
                    cleanable={ dateStr !== defaultDateStr }
                    onClean={ () => {
                        delete borderCrossings?.[dateStr];
                        setBorderCrossings({...borderCrossings});
                    }}
                    onChange={ value => setBorderCrossings({
                        ...borderCrossings,
                        [dateStr]: value
                    })}
                />
            )) }

            <FormGroup 
                name='addBorderCrossings'
                label={ t('OKKF/AddBorderCrossingsElement') }
                accepter={DatePicker}
                value={null}
                oneTap
                placeholder={ t('OKKF/SelectStartDate') }
                onChange={value => {
                    setBorderCrossings({
                        ...borderCrossings,
                        [value.toISOString().slice(0, 10)]: undefined
                    })
                }}
            />

            <Divider />

            { Object.entries(settlements || {}).map(([dateStr, elementId]) => (
                <FormGroup 
                    key={`segments-${dateStr}`}
                    name={`settlements-${dateStr}`}
                    label={`${t('OKKF/Settlements')} (${dateStr})`}
                    accepter={SelectPicker}
                    data={elementOptions}
                    value={elementId}
                    cleanable={ dateStr !== defaultDateStr }
                    onClean={ () => {
                        delete settlements?.[dateStr];
                        setSettlements({...settlements});
                    }}
                    onChange={ value => setSettlements({
                        ...settlements,
                        [dateStr]: value
                    })}
                />
            )) }

            <FormGroup 
                name='addSettlements'
                label={ t('OKKF/AddSettlementsElement') }
                accepter={DatePicker}
                value={null}
                oneTap
                placeholder={ t('OKKF/SelectStartDate') }
                onChange={value => {
                    setSettlements({
                        ...settlements,
                        [value.toISOString().slice(0, 10)]: undefined
                    })
                }}
            />

            <Divider />

            { Object.entries(roadSections || {}).map(([dateStr, elementId]) => (
                <FormGroup 
                    key={`segments-${dateStr}`}
                    name={`roadSections-${dateStr}`}
                    label={`${t('OKKF/RoadSections')} (${dateStr})`}
                    accepter={SelectPicker}
                    data={elementOptions}
                    value={elementId}
                    cleanable={ dateStr !== defaultDateStr }
                    onClean={ () => {
                        delete roadSections?.[dateStr];
                        setRoadSections({...roadSections});
                    }}
                    onChange={ value => setRoadSections({
                        ...roadSections,
                        [dateStr]: value
                    })}
                />
            )) }

            <FormGroup 
                name='addRoadSections'
                label={ t('OKKF/AddRoadSectionsElement') }
                accepter={DatePicker}
                value={null}
                oneTap
                placeholder={ t('OKKF/SelectStartDate') }
                onChange={value => {
                    setRoadSections({
                        ...roadSections,
                        [value.toISOString().slice(0, 10)]: undefined
                    })
                }}
            />

            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit && onSubmit(submitData)}>
                        { t('Save') }
                    </Button>
                    <Button appearance="primary" color="red" onClick={() => onSubmit && onSubmit()}>
                        { t('Delete') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default CrossSectionRoadCountModuleForm;
