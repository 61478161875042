import DataChart, { Dataset } from 'features/chart/components/DataChart';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import CountInterval, { getAvailableCountIntervalIds } from 'features/onboardCount/elements/CountInterval';
import { onboardCountColors } from 'features/onboardCount/utils/colors';
import { MONTH_INTERVAL, TIMETABLE_INTERVAL, WEEK_INTERVAL } from 'features/onboardCount/utils/constants';
import { getChartLabels } from 'features/onboardCount/utils/labels';
import { t } from 'i18next';
import React from 'react'
import { formatString } from 'utils/format';

const TimeSeriesBarChart = React.forwardRef(({
    boardOnly,
    ...props
}, ref) => {
    
    const count = React.useContext(OnboardCountContext);
    const {
        elements: {
            transportLines = {},
            dayTypes = {},
        } = {},
        selectedElements: {
            section,
            timeSeriesIntervalIds
        } = {}
    } = count;

    const datasets = [];

    Object.values(transportLines).sortBy().forEach(transportLine => {
    
        if (section) {
            datasets.push(
                Dataset({
                    name: `onward-${transportLine?.name}`,
                    label: transportLine?.name,
                    getValue: countIntervalStr => transportLine?.traffic?.[countIntervalStr]?.onward?.avg || 0,
                    color: onboardCountColors.onward,
                })
            );
    
        } else {
            datasets.push(
                Dataset({
                    name: `board-${transportLine?.name}`,
                    label: `${transportLine?.name} - ${t('FT/BoardCount')}`,
                    getValue: countIntervalStr => transportLine?.traffic?.[countIntervalStr]?.board?.avg || 0,
                    color: onboardCountColors.board,
                })
            );
    
            if (!boardOnly) {
                datasets.push(Dataset({
                    name: `alight-${transportLine?.name}`,
                    label: `${transportLine?.name} - ${t('FT/AlightCount')}`,
                    getValue: countIntervalStr => 0 - transportLine?.traffic?.[countIntervalStr]?.alight?.avg || 0,
                    color: onboardCountColors.alight,
                }))
            }
    
        }

    })

    return (
        <DataChart 
            ref={ref}
            title={formatString(t('FT/DailyAverageTransportLineCountTimeSeriesChartTitle'), getChartLabels(count))}
            data={timeSeriesIntervalIds}
            sets={datasets}
            getLabel={item => {
                const interval = CountInterval(item);
                switch (interval.type) {
                    case WEEK_INTERVAL:
                        return `${interval.week}.`
                    default:
                        return interval.label
                }
            }}
            animation={false}
            scales={{
                x: {
                    title: {
                        display: true,
                        text: t('FT/CalendarWeekNumber'),
                    },
                    stacked: true,
                    ticks: {
                        align: 'center',
                        minRotation: 0,
                        maxRotation: 0,
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: t('FT/DailyAveragePassengerCount'),
                    },
                    stacked: true,
                    ticks: {
                        callback: val => Math.abs(val)
                    }
                }
            }}
            plugins={{
                legend: {
                    position: 'bottom',
                    align: 'left',
                },
                tooltip: {
                    callbacks: {
                        label: ctx => {
                            const i = ctx.parsed.x;
                            const di = ctx.datasetIndex;
                            return ` ${datasets[di].props.label}: ${Math.abs(datasets[di].getValue(timeSeriesIntervalIds[i]))}`;
                        },
                    },
                }
            }}
            {...props}
        />
    )
});

export default TimeSeriesBarChart