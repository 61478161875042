import { onboardCountColors } from "features/onboardCount/utils/colors";


const DEFAULT_COLOR = onboardCountColors.area


function areaLabeling ({ 
    field = 'name', 
    size = 9, 
    color = DEFAULT_COLOR,
} = {}) {

    const labeling = {
        symbol: {
            type: 'text',
            color: onboardCountColors.white.array,
            haloColor: color.array,
            haloSize: 1.2,
            font: {
                family: "Avenir Next LT Pro Regular",
                style: "normal",
                weight: "bold",
                size
            }
        },
        deconflictionStrategy: 'static', // 'static' or 'none'
        labelPlacement: "center-center",
        labelExpressionInfo: {
            expression: `$feature.${field}`
        },
    };

    return labeling;
};


export default areaLabeling;

