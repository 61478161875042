import './assets/css/App.css';
import './lib/i18n';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { CustomProvider } from 'rsuite';
import RSuiteLocale from './utils/rsuite_localization';
import { AuthContext, ForgotPasswordPage, LoginPage, RequireAuth, ResetPasswordPage, useAuth } from './features/auth';
import { OrganizationPage } from './features/organization';
import { CollectionPage } from './features/collection';
import { WebMapPage } from './features/webmap';
import { HomePage } from './features/home';
import { BlogEntryPage, BlogPage } from 'features/blog';
import huHU from 'rsuite/locales/hu_HU';
import RegisterPage from 'features/auth/pages/RegisterPage';
import ConfirmPage from 'features/auth/pages/ConfirmPage';
import SubscriptionPage from 'features/subscription/pages/SubscriptionPage';
import ProfilePage from 'features/profile/pages/ProfilePage';
import { MediaContextProvider } from 'context/MediaQueryContext';


function App() {
    return (
        <React.StrictMode>
            <CustomProvider locale={huHU} >
                <BrowserRouter>
                    <AuthContext.Provider value={useAuth()}>
                        <MediaContextProvider>
                            <Routes>
                                <Route exact path="/" element={
                                    <RequireAuth>
                                        <HomePage />
                                    </RequireAuth>
                                } />

                                <Route path="/profile" element={
                                    <RequireAuth>
                                        <ProfilePage />
                                    </RequireAuth>
                                } />

                                <Route path="/subscriptions" element={
                                    <RequireAuth>
                                        <SubscriptionPage />
                                    </RequireAuth>
                                } />

                                <Route path="/blog" element={
                                    <RequireAuth>
                                        <BlogPage />
                                    </RequireAuth>
                                } />

                                <Route path="/blog/:blogEntryId" element={
                                    <RequireAuth>
                                        <BlogEntryPage />
                                    </RequireAuth>
                                } />

                                <Route path="/organizations/:organizationId" element={
                                    <RequireAuth>
                                        <OrganizationPage />
                                    </RequireAuth>
                                } />

                                <Route path="/collections/:collectionId" element={
                                    <RequireAuth>
                                        <CollectionPage />
                                    </RequireAuth>
                                } />

                                <Route path="/webmaps/:webmapId" element={
                                    <RequireAuth>
                                        <WebMapPage />
                                    </RequireAuth>
                                } />

                                <Route path="/login" element={
                                    <LoginPage />
                                } />

                                <Route path="/register" element={
                                    <RegisterPage />
                                } />

                                <Route path="/register_other" element={
                                    <RequireAuth>
                                        <RegisterPage registerOther />
                                    </RequireAuth>
                                } />

                                <Route path="/forgot_password" element={
                                    <ForgotPasswordPage />
                                } />

                                <Route path="/reset/:token" element={
                                    <ResetPasswordPage />
                                } />

                                <Route path="/confirm/:token" element={
                                    <ConfirmPage />
                                } />
                            </Routes>
                        </MediaContextProvider>
                    </AuthContext.Provider>
                </BrowserRouter>
            </CustomProvider>
        </React.StrictMode>
    );
}

export default App;
