import 'assets/css/layout.css';

import React from "react";
import { Container, Header, Content, Breadcrumb, Nav } from 'rsuite';

import { NavBar } from 'components/pages';
import Footer from 'components/pages/Footer';


const SimplePage = ({
    floating,
    children,
    breadcrumbData,
    extraHeader,
    ...props
}) => {
    return <>
        <Container>
            <Header>
                <NavBar floating={!extraHeader} className={extraHeader ? "navbarWithExtra" : undefined}>
                    { !breadcrumbData ? <></> : (
                        <Breadcrumb separator='>' className="navbarBreadcrumb" as={Nav.Item}>
                            { breadcrumbData.map(({label, link, active}, i) => (
                                <Breadcrumb.Item key={i} href={link} active={active}>
                                    {label}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    )}
                </NavBar>
                {extraHeader}
            </Header>
            <Content {...props}>
                {children}
            </Content>
            <Footer>
            </Footer>
        </Container>
    </>;
};


export default SimplePage;
