import { asRoman } from "./math";

export const weekStr = ({year, week}) => {
    return `${year}. ${week}. hét`
}

export const weekDatesStr = ({year, week}) => {
    let startDate = new Date(parseInt(year), 0, 1, 12);
    const dayNumber = startDate.getDay() || 7;
    const weekNumber = parseInt(week) - (dayNumber > 4 ? 1 : 2)
    startDate.setDate(startDate.getDate() + 8 - dayNumber + (weekNumber * 7))
    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6)
    return `${startDate.toISOString().slice(5,10).replace('-', '.')}-${endDate.toISOString().slice(5,10).replace('-', '.')}`
}


export const weekStrWithLineBreak = ({year, week, dayType}) => {
    const result = [weekStr({year, week}), weekDatesStr({year, week})]
    if (dayType) {
        result.push(dayType)
    }
    return result
}

export const monthStr = ({year, month, dayType}) => {
    return monthStrWithLineBreak({year, month, dayType}).join(" ")
}

export const monthStrWithLineBreak = ({year, month, dayType}) => {
    const result = [`${year}. ${asRoman(month)}. hó`]
    if (dayType) {
        result.push(dayType);
    }
    return result
}
export const countIntervalFromStr = (value) => {

    const [intervalStr, ...dayTypeNameList] = value.split('-');;
    const dayType = dayTypeNameList.join('-');

    const year = parseInt(intervalStr.slice(0, 4));
    const month = parseInt(intervalStr.slice(4, 6));
    const week = parseInt(intervalStr.slice(6, 8));
    const startDate = intervalStr.length > 8 ? 
        `${intervalStr.slice(0, 4)}.${intervalStr.slice(4, 6)}.${intervalStr.slice(6, 8)}` :
        undefined;
    const endDate = intervalStr.length > 8 ? 
        `${intervalStr.slice(8, 12)}.${intervalStr.slice(12, 14)}.${intervalStr.slice(14, 16)}` :
        undefined;
    if ( isNaN(year) || isNaN(month) || isNaN(week)) {
        return undefined
    }
    return { year, month, week, startDate, endDate, dayType }
}

export const countIntervalToStr = (value) => {
    const {startDate, endDate} = value;
    return dateIntervalToStr([startDate, endDate]);
}

export const countIntervalStrWithLineBreak = ({year, month, week, startDate, endDate, dayType}) => {
    if ( startDate ) {
        return [`${startDate} -`, endDate, dayType]
    }
    if ( month ) {
        return monthStrWithLineBreak({year, month, dayType});
    }

    if ( week ) {
        return weekStrWithLineBreak({year, week, dayType});
    }
}

export const compareCountDateStr = (a, b) => {
    const [y0, w0] = a.split('.');
    const [y1, w1] = b.split('.');
    return parseInt(y0)*100 + parseInt(w0) - parseInt(y1)*100 - parseInt(w1)
}

export const compareCountIntervals = (a, b) => {
    if (a?.startDate) {
        return a.startDate - b.startDate || a.endDate - b.endDate;
    }
    return a.year * 10000 + (a.month || 0) * 100 + (a.week || 0) 
        - b.year * 10000 - (b.month || 0) * 100 - (b.week || 0)
}

export const dateToStr = (d) => {
    if (!d) return ''
    let year = `${d.getFullYear()}`;
    let month = `${d.getMonth() + 1}`.padStart(2, '0');
    let day = `${d.getDate()}`.padStart(2, '0');
    
    return [year, month, day].join('.')
}

export const dateIntervalToStr = (i) => {
    if (!i) {return}

    let [start, end] = i;

    if (typeof start === 'string') {
        start = new Date(start)
    }

    if (typeof end === 'string') {
        end = new Date(end)
    }

    return `${dateToStr(start)} - ${ dateToStr(end)}`;
}

export const strToDateInterval = s => {
    const [startStr, endStr] = s.split(' - ');
    return [
        new Date(startStr.replaceAll('.', '-')),
        new Date(endStr.replaceAll('.', '-'))
    ]
}