import { InfoIcon } from 'components/icons';
import { Layover } from 'components/layovers'
import React from 'react'
import { Button, ButtonGroup, Message } from 'rsuite'

const TutorialLayover = ({
    step,
    total,
    onChange,
    ...props
}) => {
    if (!step) return;

    return (
        <Layover {...props}>
            <Message style={{marginBottom: 20}}>
                <div style={{color: '#2196f3', marginBottom: 10}}>
                    <h6><InfoIcon/> Felhasználási ismertető {step}/{total}</h6>
                </div>
                <ButtonGroup>
                    <Button appearance="ghost" onClick={() => onChange(step - 1)} disabled={ step === 1 }>
                        Előző
                    </Button>
                    <Button appearance="primary" onClick={() => onChange(step === total ? 0 : step + 1)}>
                        { step === total ? 'Ismertető befejezése' : 'Következő' }
                    </Button>
                </ButtonGroup>
                { step !== total && (
                    <Button appearance="link" onClick={() => onChange(0)}>
                        Ismertető kihagyása
                    </Button>
                )}
            </Message>

        </Layover>
    )
}

export default TutorialLayover