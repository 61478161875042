import 'assets/css/layout.css';

import React from "react";


const CenteredPage = ({
    children,
    ...props
}) => {
    return (
        <div className='centeredContainer fullscreen' {...props}>
            {children}
        </div>
    )
}


export default CenteredPage;
