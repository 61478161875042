import React from "react";
import { Col, Grid, Message, Nav, Row, toaster } from "rsuite";
import { t } from "i18next";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";
import SimplePage from "layouts/SimplePage";
import ChangePasswordForm from "../components/forms/ChangePasswordForm";
import SubscriptionListPanel from "features/subscription/components/SubscriptionListPanel";
import { postProfileData } from "../profileAPI";


function ProfilePage () {
    const [ loading, setLoading ] = React.useState();
    const [ activeTab, setActiveTab ] = React.useState('subscriptions');

    function changePassword({currentPassword, password}) {
        setLoading(true);
        postProfileData({password, currentPassword})
        .then(() => {
            toaster.push(<Message showIcon>
                {t(`Security/ChangePasswordSuccesful`)}
            </Message>)
        })
        .catch( err => {
            toaster.push(<Message showIcon type="error" closable>
                {t(`API/${err.message}`)}
            </Message>)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <SimplePage>
            
            <Jumbotron >
                <PageTitle title={t('Profile/UserAccount')} />
            </Jumbotron>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        
                        <Nav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
                            <Nav.Item eventKey="subscriptions" >
                                {t('MySubscriptions')}
                            </Nav.Item>
                            <Nav.Item eventKey="changePassword" >
                                {t('Security/ChangePassword')}
                            </Nav.Item>
                            
                        </Nav>
                    </Col>

                    { activeTab === 'subscriptions' && <>

                        <Col xs={24} >
                            <SubscriptionListPanel valid defaultPageSize={5}/>
                        </Col>
                        <Col xs={24} >
                            <SubscriptionListPanel past defaultPageSize={5}/>
                        </Col>
                        <Col xs={24} >
                            <SubscriptionListPanel future defaultPageSize={5}/>
                        </Col>
                    
                    </> }

                    { activeTab === 'changePassword' && <>
                        <Col xs={24} >
                            <ChangePasswordForm layout="horizontal" loading={loading} onSubmit={changePassword}/>
                        </Col>
                    
                    </> }

                </Row>
            </Grid>  

        </SimplePage>
    )
};


export default ProfilePage;
