import React from "react";

import CenteredPanelPage from "layouts/CenteredPanelPage";

import { useNavigate, useParams } from "react-router-dom";
import { confirmUser, getUserFromToken } from "../authAPI";
import { Message, toaster } from "rsuite";
import { t } from "i18next";
import ConfirmForm from "../components/forms/ConfirmForm";



const ConfirmPage = () => {

    const { token } = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = React.useState();
    const [passwordNeeded, setPasswordNeeded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getUserFromToken(token)
        .then(({data}) => {
            if (data?.confirmed && data?.passordSet) {
                toaster.push(<Message showIcon>
                    {t(`Security/AlreadyConfirmed`)}
                </Message>);
                navigate('/');
            }
            if (data?.passwordSet) {
                submit();
            } else {
                setPasswordNeeded(true)
            }
            setEmail(data?.email)
        })
    }, [token])

    function submit({ password }) {
        if (loading) {
            return
        }
        setLoading(true);
        confirmUser({ token, password })
        .then(() => {
            toaster.push(<Message showIcon>
                {t(`Security/ConfirmSuccesful`)}
            </Message>)
            navigate('/login');
        })
        .catch( err => {
            toaster.push(<Message showIcon type="error" closable>
                {t(`API/${err.message}`)}
            </Message>)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <CenteredPanelPage>
            <ConfirmForm email={email} passwordNeeded={passwordNeeded} onSubmit={submit} loading={loading} />
        </CenteredPanelPage>
    );
};

export default ConfirmPage;