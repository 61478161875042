import { compareNumStrings } from 'utils/compare';
import {avg, med, percentile, sum} from 'utils/math';


Array.prototype.sum = function () {
    return sum(this);
}


Array.prototype.avg = function() {
    return avg(this);
}


Array.prototype.min = function() {
    return Math.min(...this);
}


Array.prototype.max = function() {
    return Math.max(...this);
}


Array.prototype.med = function() {
    return med(this);
}


Array.prototype.percentile = function(p) {
    return percentile(this, p);
}


function getCompareFunction(name, desc = false) {
    const attrs = name ? name.split('.').map(n => {
        if (n.startsWith('-')) {
            desc = true;
            return n.slice(1)
        } else {
            return n
        }
    }) : [];
    return (a, b) => {
        let valA = a, valB = b;

        attrs.forEach(attr => {
            valA = valA?.[attr];
            valB = valB?.[attr];
        });

        const params = desc ? [valB, valA] : [valA, valB];

        if (typeof valA === 'string' || typeof valB === 'string') {
            return compareNumStrings(...params); 
        } else if (typeof valA === 'object' && typeof valA?.compare === 'function') {
            return valA.compare(valB) * (desc ? -1 : 1);
        } else {
            return desc ? valB - valA : valA - valB;
        }
    }
}


Array.prototype.sortBy = function(name = undefined, desc=false) {
    const names = [];
    if (Array.isArray(name)) {
        names.push(...name);
    } else {
        names.push(name)
    } 
    return this.sort((a, b) => {
        for (let i = 0; i < names.length; i++) {
            const fn = getCompareFunction(names[i], desc);
            const value = fn(a, b)
            if (value) {
                return value
            }
        }
        return 0
    });
};


Array.prototype.removeDuplicates = function() {
    return this.filter((item, index) => this.indexOf(item) === index);
};
