import React from "react";

import { useArcGISLayer } from "../hooks";



const MapLayer = ({
    id,
    element,
    arcGISMap,
    join,
    onRenderStarted,
    onRendered,
}) => {

    useArcGISLayer({
        id,
        element,
        arcGISMap,
        join,
        onRenderStarted,
        onRendered
    })
    
    return <></>;
};


export default MapLayer;
