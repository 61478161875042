import 'assets/css/list.css'

import React from 'react'

import { PageListItem } from 'components/lists'
import { deleteWebMap } from '../webmapAPI'
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal'
import WebMapModal from './WebMapModal'
import { t } from 'i18next'


const WebMapListItem = ({
    item,
    editable,
    deletable,
    onModalClose,
}) => {

    const DeleteModal = ({
        item,
        ...props
    }) => (
        <ConfirmDeleteModal 
            onDelete={() => deleteWebMap(item?.id).then(onModalClose)}
            itemLabel={t('WebMap')} 
            {...props} />  
    )

    return (
        <PageListItem
            endpoint="webmaps"
            item={item}
            getTitle={item => item?.name}
            getSubtitle={item => item?.location}
            getImageURL={item => item?.thumbnailUrl}
            editModalClass={editable && WebMapModal}
            deleteModalClass={deletable && DeleteModal}
            onModalClose={onModalClose}
        />
    )
}

export default WebMapListItem