import React from 'react'
import { t } from 'i18next';

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import ModuleTablePanel from 'features/webmapModule/components/table/ModuleTablePanel';
import TransportLineTrafficTable from './TransportLineTrafficTable';
import StopPlaceTrafficPanelContent from './StopPlaceTrafficPanelContent';
import { ALIGHT_BOARD_COUNT, BOARD_COUNT, CROSS_SECTION_COUNT, SECTION_TRAFFIC, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from 'features/onboardCount/utils/constants';
import SectionTrafficPanelContent from './SectionTrafficPanelContent';
import JourneyStopTrafficTable from './JourneyStopTrafficTable';


const OnboardCountTablePanel = () => {

    const count = React.useContext(OnboardCountContext);

    if (!count?.elements?.transportLines) return;

    const menu = count?.tableMenu;
    const toolbarHeight = count?.toolbarRef?.current?.offsetHeight || 60;

    return (
        <ModuleTablePanel
            module={count}
            title={t('FT/TrafficPivots')}
        >
            { menu?.values[0] === TRANSPORT_LINE_TRAFFIC && <>

                { menu?.values[1] === BOARD_COUNT && <>
                    <TransportLineTrafficTable 
                        ref={count?.table}
                        autoHeight
                        defaultVisibleColumns={{
                            traffic: ['board']
                        }}
                        affixHeader={toolbarHeight}
                        totalTop
                        totalBottom
                        headerWidth={320}/>
                </> }

                { menu?.values[1] === ALIGHT_BOARD_COUNT && <>
                    <JourneyStopTrafficTable 
                        ref={count?.table}
                        renderBeginningCount
                        renderEndingCount
                        defaultVisibleColumns={{
                            traffic: ['alight', 'board'],
                            stat: ['avg']
                        }}
                        headerWidth={320}
                        height={500} />
                </> }

                { menu?.values[1] === CROSS_SECTION_COUNT && <>
                    <JourneyStopTrafficTable 
                        ref={count?.table}
                        defaultVisibleColumns={{
                            traffic: ['onward'],
                            stat: ['avg']
                        }}
                        headerWidth={320}
                        columnWidth={72}
                        minColumnWidth={72}
                        height={500} />
                </> }

            </> }

            { menu?.values[0] === STOP_PLACE_TRAFFIC && <>
                
                <StopPlaceTrafficPanelContent 
                    ref={count?.table}
                    defaultVisibleColumns={{
                        traffic: count?.filters?.stopPlaceId || count?.filters?.areaId ? ['alight', 'board'] : ['board']
                    }}
                    totalTop
                />

            </> }

            { menu?.values[0] === SECTION_TRAFFIC && <>
                
                <SectionTrafficPanelContent 
                    ref={count?.table}
                    totalTop
                />

            </> }

        </ModuleTablePanel>
    )
}

export default OnboardCountTablePanel