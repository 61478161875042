import React from 'react'
import { zfill } from 'utils/format';


export function isNextDay(value) {
    if (!value) return false;
    return value >= 86400;
}

export function secondsToDate(value) {
    if (value === undefined || value === null ) {
        return
    }
    let t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(value % 86400);
    return t;
};


export function secondsToString(value) {
    const d = secondsToDate(value)
    if (!d) return;

  return zfill(d.getHours(), 2) + ':' + zfill(d.getMinutes(), 2);
};


export function secondsFromDate(value, nextDay) {
    if (!value) return;
    return (nextDay ? 86400 : 0) + 
        value.getHours() * 3600 + 
        value.getMinutes() * 60 + 
        value.getSeconds();
}
