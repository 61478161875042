import { onboardCountColors } from "features/onboardCount/utils/colors";
import { countCeilingRound, countFloorRound, countNearestRound } from "utils/math";


function stopPlaceTrafficRenderer ({ field, min, med, max, count, minSize = 5, maxSize = 100 }) {

    if (!count) return;

    const stopThresholds = [1, 2, 5]
    const maxDataValue = countCeilingRound(max, stopThresholds);
    const minDataValue = Math.max(
        countFloorRound(min, stopThresholds), 
        countCeilingRound(med / 10 , stopThresholds)
    );

    const color = onboardCountColors.transparent.array;

    return {
        type: 'simple',
        symbol: {
            type: "simple-marker",
            size: 6,
            color,
            outline: {
                color: onboardCountColors?.[field]?.array,
                width: 1.5,
            },
        },
        visualVariables: [{
            type: 'size',
            field,
            minDataValue: minDataValue,
            maxDataValue: maxDataValue,
            minSize: {
                type: "size",
                valueExpression: "$view.scale",
                stops: [
                  { value: 200000, size: minSize },
                  { value: 50000, size: minSize },
                  { value: 10000, size: minSize },
                  { value: 2000, size: minSize }
                ],
            },
            maxSize: {
                type: "size",
                valueExpression: "$view.scale",
                stops: [
                  { value: 200000, size: maxSize },
                  { value: 50000, size: maxSize * 2 },
                  { value: 10000, size: maxSize * 3 },
                  { value: 2000, size: maxSize * 4 }
                ],
            },
        }],
    };
};


export default stopPlaceTrafficRenderer;
