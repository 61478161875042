import React from 'react';

import { getCollectionData } from '../collectionAPI';

function useCollection(id) {

    const [collection, setCollection] = React.useState();

    React.useEffect(() => {
        getCollectionData(id)
            .then(({data}) => setCollection(data));
    }, [id]);

    return collection

}

export default useCollection;
