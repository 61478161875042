import 'assets/css/form.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonToolbar, Checkbox, DatePicker, Form, Input, TagPicker } from 'rsuite';
import { t } from 'i18next';
import { FormGroup } from 'components/forms';
import { noop } from 'utils/constants';


const RegisterForm = ({
    layout,
    registerOther,
    loading,
    organizations,
    subscriptionTypes,
    onSubmit = noop
}) => {
    const navigate = useNavigate();

    const [username, setUsername] = React.useState();
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [password2, setPassword2] = React.useState();
    const [ula, setUla] = React.useState(false);
    const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
    const [organizationIds, setOrganizationIds] = React.useState([]);
    const [subscriptionTypeIds, setSubscriptionTypeIds] = React.useState([]);
    const [validFrom, setValidFrom] = React.useState();

    let submittable = true;
    if (!registerOther) {
        if (!(
            username &&
            email &&
            password &&
            password === password2 &&
            ula &&
            privacyPolicy
        )) {
            submittable = false
        }
    }


    return (
        <Form layout={layout}>
            <FormGroup>
                <h5>{t('Security/Register')}</h5>
            </FormGroup>
            <FormGroup
                name="username"
                label={t('Username')}
                accepter={Input}
                value={username}
                onChange={setUsername}
            />
            <FormGroup
                name="email"
                label={t('E-mail')}
                accepter={Input}
                type="email"
                value={email}
                onChange={setEmail}
            />
            {!registerOther && <>
                <FormGroup
                    name="password"
                    label={t('Password')}
                    accepter={Input}
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={setPassword}
                />
                <FormGroup
                    name="password2"
                    label={t('Security/ConfirmPassword')}
                    accepter={Input}
                    type="password"
                    autoComplete="off"
                    error={password !== password2 ? t('Security/PasswordsDontMatch') : undefined}
                    value={password2}
                    onChange={setPassword2}
                />
                <FormGroup
                    name="ula"
                    label={t('AcceptUserLicenseAgreement')}
                    link="/docs/ula.html"
                    linkTitle={t('UserAgreement')}
                    accepter={Checkbox}
                    value={ula}
                    onChange={setUla}
                />
                <FormGroup
                    name="privacy_policy"
                    label={t('AcceptPrivacyPolicy')}
                    link="/docs/privacy_policy.html"
                    linkTitle={t('PrivacyPolicy')}
                    accepter={Checkbox}
                    value={privacyPolicy}
                    onChange={setPrivacyPolicy}
                />
            </>}

            {registerOther && <>

                <FormGroup
                    name="organizations"
                    label={t('Security/OrganizationMemberships')}
                    accepter={TagPicker}
                    data={organizations}
                    labelKey="name"
                    valueKey="id"
                    placeholder={t('Security/SelectOrganizations')}
                    value={organizationIds}
                    onChange={setOrganizationIds}
                />

                <FormGroup
                    name="subscriptionTypes"
                    label={t('Security/Subscriptions')}
                    accepter={TagPicker}
                    data={subscriptionTypes}
                    labelKey="name"
                    valueKey="id"
                    placeholder={t('Security/SelectSubscriptionTypes')}
                    value={subscriptionTypeIds}
                    onChange={setSubscriptionTypeIds}
                />

            </>}

            {subscriptionTypeIds.length > 0 && <>

                <FormGroup
                    name="validFrom"
                    label={t('Security/ValidFrom')}
                    accepter={DatePicker}
                    format="yyyy.MM.dd"
                    placeholder={t('SelectDate')}
                    oneTap
                    value={validFrom}
                    onChange={setValidFrom}
                />
            </>}
            <FormGroup>
                <ButtonToolbar>
                    <Button
                        appearance="primary"
                        loading={loading}
                        disabled={!submittable}
                        onClick={() => onSubmit({
                            username,
                            email,
                            password,
                            organizationIds,
                            subscriptionTypeIds,
                            validFrom,
                        })}
                    >
                        {t('Security/Register')}
                    </Button>
                    <Button appearance="subtle" onClick={() => navigate('/login')}>
                        {t('Security/Login')}
                    </Button>
                    <Button appearance="subtle" onClick={() => navigate('/forgot_password')}>
                        {t('Security/ForgotPassword')}
                    </Button>
                </ButtonToolbar>
            </FormGroup>
        </Form>
    );
};

export default RegisterForm;