import sectionRenderer from "features/onboardCount/lib/arcGISRenderers/sectionRenderer";
import { t } from "i18next";

import { arrayStats } from "utils/math";
import { getTraffic } from "../traffic";
import { Join } from "features/webmap";
import sectionLabeling from "features/onboardCount/lib/arcGISLabels/sectionLabeling";


function getSectionFeatures(sections, countInterval) {
    if (!sections) return {};

    const sectionValues = [];
    const records = sections.map(section => {
        const traffic = getTraffic(section, countInterval);
        sectionValues.push(traffic.forward.avg, traffic.backward.avg); 
        return {
                elementId: section.id,
                elementType: 'section',
                id: section.id,
                name: section.name,
                forward: traffic.forward.avg,
                backward: traffic.backward.avg,
        };
    });

    const stats = arrayStats(sectionValues);

    return {
        records,
        stats,
    };
};


function getSectionForwardJoin({
    records,
    stats,
    labelSections = true,
}) {
    return Join({
        name: 'sectionForward',
        index: 2,
        records,
        baseField: 'Id',
        targetField: 'elementId',
        formatRecord: r => ({...r, direction: 1}),
        orderBy: [{
            field: 'forward',
            order: 'descending'
        }],
        fieldTypes: {
            elementId: 'integer',
            elementType: 'string',
            direction: 'integer',
            name: 'string',
            forward: 'integer',
        },
        fieldLabels: {
            forward: t('FT/DailyAverageCrossSectionCount')
        },
        label: t('FT/DailyAverageCrossSectionCount'),

        geometryType: 'polyline',
        renderer: sectionRenderer({
            field: 'forward',
            ...stats
        }),
        definitionExpression: 'forward > 0',
    });
};


function getSectionBackwardJoin({
    records,
    stats,
    labelSections
}) {
    return Join({
        name: 'sectionBackward',
        index: 1,
        records,
        baseField: 'Id',
        targetField: 'elementId',
        formatRecord: r => ({...r, direction: 2}),
        orderBy: [{
            field: 'backward',
            order: 'descending'
        }],
        fieldTypes: {
            elementId: 'integer',
            elementType: 'string',
            direction: 'integer',
            name: 'string',
            backward: 'integer',
        },
        fieldLabels: {
            backward: t('FT/DailyAverageCrossSectionCount')
        },
        label: t('FT/DailyAverageCrossSectionCount'),
        geometryType: 'polyline',
        renderer: sectionRenderer({
            field: 'backward',
            backward: true,
            ...stats
        }),
        legendEnabled: false,
        definitionExpression: 'backward > 0',
    });
};


export function getSectionJoins(sections, {
    labelSections
}) {
    const { records, stats } = getSectionFeatures(sections);

    return [
        getSectionForwardJoin({ records, stats, labelSections}),
        getSectionBackwardJoin({ records, stats, labelSections }),
    ]
};

