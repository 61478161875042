import React from 'react'
import { ButtonGroup, IconButton } from 'rsuite';
import { t } from 'i18next';

import { BarChartIcon, MapIcon, TableIcon } from 'components/icons';


const ModuleNavigation = ({
    map,
    table,
    chart
}) => {
    return (
        <ButtonGroup>
            <IconButton
                title={t("Map")}
                href='#mapPanel'
                icon={<MapIcon />}
                disabled={!map}
            />

            <IconButton
                title={t("FT/TrafficData")}
                href='#tablePanel'
                icon={<TableIcon />}
                disabled={!table}
            />

            <IconButton
                title={t("Charts")}
                href='#chartPanel'
                icon={<BarChartIcon />}
                disabled={!chart}
            />
        </ButtonGroup>
    );
};


export default ModuleNavigation;
