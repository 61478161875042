import FormGroup from 'components/forms/FormGroup';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext'
import { AADT_VIEW, MADT_VIEW, MAJOR_ROAD_CATEGORIES, MINOR_ROAD_CATEGORIES, ROADS_VIEW, VIEW_TYPE_OPTIONS } from 'features/crossSectionRoadCount/utils/constants';
import { t } from 'i18next';
import React from 'react'
import { CheckPicker, Checkbox, SelectPicker, Slider } from 'rsuite';
import { noop } from 'utils/constants'
import { asRoman } from 'utils/math';


const MapViewFormFields = ({
    includeRoadsView,
    includeVehiceClassCodes,
    values,
    onChange = noop,
}) => {
    const module = React.useContext(CrossSectionRoadCountContext);

    const {
        firstYear = 2021,
        lastYear = 2022,
    } = module?.moduleParameters || {};

    const years = [];
    for (let i = firstYear; i <= lastYear; i++) {
        years.push(i);
    };

    const {
        dayTypes = {},
    } = module?.elements;

    const widthModifierOptions = [{
        value: 0.6, label: t('XS'),
    }, {
        value: 0.8, label: t('SM'),
    }, {
        value: 1, label: t('MD'),
    }, {
        value: 1.5, label: t('LG'),
    }, {
        value: 2, label: t('XL')
    }]

    const [viewType, setViewType] = React.useState(values?.viewType || (includeRoadsView ? ROADS_VIEW : AADT_VIEW));
    const [year, setYear] = React.useState(values?.year || years[years.length - 1]);
    const [month, setMonth] = React.useState(values?.month);
    const [dayTypeId, setDayTypeId] = React.useState(values?.dayTypeId);
    const [vehicleClassCodes, setVehicleClassCodes] = React.useState(values?.vehicleClassCodes);
    const [majorRoads, setMajorRoads] = React.useState((values?.roadCategoryCodes && values?.roadCategoryCodes?.includes(MAJOR_ROAD_CATEGORIES[0])) || true);
    const [minorRoads, setMinorRoads] = React.useState((values?.roadCategoryCodes && values?.roadCategoryCodes?.includes(MINOR_ROAD_CATEGORIES[0])) || false);
    const [widthModifierIndex, setWidthModifierIndex] = React.useState(Object.values(widthModifierOptions).map(({value}) => value).indexOf(values?.widthModifier || 1));



    const vehicleClasses = module?.getYearlyVehicleClasses(year) || {};

    const viewTypeOptions = includeRoadsView
        ? VIEW_TYPE_OPTIONS
        : VIEW_TYPE_OPTIONS.filter(item => item.value !== ROADS_VIEW);

    const yearOptions = years.map(y => ({label: `${y}`, value: y}));

    const monthOptions = [];
    for (let i = 1; i <= 12; i++) {
        monthOptions.push({label: `${asRoman(i)}.`, value: i});
    };

    const dayTypeOptions = Object.values(dayTypes).map(dayType => ({
        label: dayType.label,
        value: dayType.id
    }))

    const vehicleClassOptions = Object.values(vehicleClasses).map(vehicleClass => ({
        label: vehicleClass.label,
        value: vehicleClass.code
    }))

    React.useEffect(() => {
        const roadCategoryCodes = [
            ...(majorRoads ? MAJOR_ROAD_CATEGORIES : []),
            ...(minorRoads ? MINOR_ROAD_CATEGORIES : []),
        ];
        onChange({
            viewType,
            year,
            month,
            dayTypeId,
            vehicleClassCodes,
            roadCategoryCodes,
            widthModifier: widthModifierOptions[widthModifierIndex]?.value,
        })
    }, [viewType, year, month, dayTypeId, `${vehicleClassCodes}`, majorRoads, minorRoads, widthModifierIndex])

    return <>
        <FormGroup
            name="viewType"
            label={includeRoadsView ? t('OKKF/View') : t('OKKF/CountIntervalType')}
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={viewTypeOptions}
            value={viewType}
            onChange={setViewType}
        />
        
        <FormGroup
            name="year"
            label={viewType === ROADS_VIEW ? t('Year') : t('OKKF/Year')}
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={yearOptions}
            value={year}
            onChange={setYear}
        />


        { viewType === MADT_VIEW ? <>
            <FormGroup
                name="month"
                label={t('OKKF/Month')}
                accepter={SelectPicker}
                searchable={false}
                cleanable={false}
                data={monthOptions}
                value={month}
                onChange={setMonth}
                placeholder={t('OKKF/SelectMonth')}
            />
            
            <FormGroup
                name="dayType"
                label={t('OKKF/DayType')}
                accepter={SelectPicker}
                searchable={false}
                cleanable={false}
                data={dayTypeOptions}
                value={dayTypeId}
                onChange={setDayTypeId}
                placeholder={t('OKKF/SelectDayType')}
            />
        </> : <></>}

        { includeRoadsView && <>
            <FormGroup
                name="majorRoads"
                label={t('OKKF/ShowMajorRoads')}
                accepter={Checkbox}
                value={majorRoads}
                onChange={setMajorRoads}
            />
            <FormGroup
                name="minorRoads"
                label={t('OKKF/ShowMinorRoads')}
                accepter={Checkbox}
                value={minorRoads}
                onChange={setMinorRoads}
            />
        </>}

        { includeVehiceClassCodes && [AADT_VIEW, MADT_VIEW].includes(viewType) ? <>
            
            <FormGroup
                name="vehicleClass"
                label={t('OKKF/VehicleClass')}
                accepter={CheckPicker}
                data={vehicleClassOptions}
                value={vehicleClassCodes}
                onChange={setVehicleClassCodes}
                placeholder={t('OKKF/AllVehicleClasses')}
            />
            
            <FormGroup
                name="widthModifier"
                label={t('OKKF/WidthModifier')}
                accepter={Slider}
                min={0}
                max={widthModifierOptions.length - 1}
                renderTooltip={index => widthModifierOptions[index]?.label}
                value={widthModifierIndex}
                onChange={setWidthModifierIndex}
            />
        
        </> : <></> }
  </>
}

export default MapViewFormFields