import React from 'react'
import { ButtonGroup, ButtonToolbar, Dropdown, IconButton, Popover, Whisper } from 'rsuite'
import { t } from 'i18next'

import { ESRIIcon } from 'components/icons'

const ModuleTableToolbar = ({module}) => {

    const tables = module?.table?.current?.tables || [{ref: module?.table}];
    
    function exportTable(index = 0) {
        const table = tables[index]?.ref?.current;
        table.saveAsCSV(module?.getExportName());
    }

    return (
        <ButtonToolbar>
            <ButtonGroup size='xs'>
                { tables.length > 1 ? <>
                    <Whisper
                        placement="bottomEnd"
                        trigger="click"
                        speaker={({ onClose, left, top, className }, ref) => {
                            const handleSelect = eventKey => {
                                onClose();
                                exportTable(eventKey);
                            };
                            return (
                                <Popover ref={ref} className={className} style={{ left, top }} full>
                                <Dropdown.Menu onSelect={handleSelect}>
                                    { tables.map(({label}, index) => (
                                    <Dropdown.Item key={index} eventKey={index}>
                                        {label}
                                    </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                                </Popover>
                            );
                        }}
                    >
                        <IconButton 
                            title={t('SaveAsCSV') + (module?.isDemo ? `\n${t('Map/UnavailableInDemo')}` : '')}
                            icon={<ESRIIcon name="save" />}
                            appearance='default'
                            disabled={module?.isDemo} />
                    </Whisper>
                </> : <>
                    <IconButton
                        title={t('SaveAsCSV') + (module?.isDemo ? `\n${t('Map/UnavailableInDemo')}` : '')}
                        icon={<ESRIIcon name="save" />}
                        appearance='default'
                        disabled={module?.isDemo}
                        onClick={() => exportTable()} />
                </> }

            </ButtonGroup>
        </ButtonToolbar>
    )
}

export default ModuleTableToolbar