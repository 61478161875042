import React from "react";

import CenteredPanelPage from "layouts/CenteredPanelPage";
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import { forgotPassword } from "../authAPI";
import { Message, toaster } from "rsuite";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";


const ForgotPasswordPage = () => {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    function submit ({ email }) {
        setLoading(true);
        forgotPassword({ email })
        .then(() => {
            toaster.push(<Message showIcon>
                {t(`Security/ResetPasswordInstructionsSent`)}
            </Message>);
            navigate('/');
        })
        .catch( err => {
            toaster.push(<Message showIcon type="error" closable>
                {t(`API/${err.message}`)}
            </Message>)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <CenteredPanelPage>
            <ForgotPasswordForm loading={loading} onSubmit={submit} />
        </CenteredPanelPage>
    );
};

export default ForgotPasswordPage;