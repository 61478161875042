import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, CheckTreePicker, Form } from "rsuite";
import { t } from "i18next";

import { noop } from "utils/constants";


const ConfigurationItemForm = ({ 
    configurationItem,
    webMap,
    onSubmit = noop,
    onCancel = noop,
    ...props 
}) => {

    const [name, setName] = React.useState(configurationItem?.name);
    const [elementIds, setElementIds] = React.useState(configurationItem?.elementIds || []);
    const [sublayerIds, setSublayerIds] = React.useState(configurationItem?.sublayerIds || []);

    const getSublayers = ({id, name, subLayers, type}) => {
        return {
            label: name,
            value: `${type ? 'element' : 'sublayer'}-${id}`,
            children: subLayers.length ? subLayers.map(getSublayers) : undefined
        }
    }

    const elements = webMap?.elements?.map(getSublayers);

    const setElements = (ids) => {
        const elements = ids.map(i => i.split('-'));

        setElementIds(elements
            .filter(([type, id]) => type === 'element')
            .map(([type, id]) => parseInt(id)));

        setSublayerIds(elements
            .filter(([type, id]) => type === 'sublayer')
            .map(([type, id]) => parseInt(id)));

    };

    const submitData = {
        name,
        elementIds,
        sublayerIds
    };

    return (
        <Form {...props}>
            <Form.Group controlId="name">
                <Form.ControlLabel>{ t('Name') }</Form.ControlLabel>
                <Form.Control 
                    name="name"
                    value={name}
                    onChange={setName} />
            </Form.Group>
            <Form.Group controlId="elements">
                <Form.ControlLabel>{ t('Map/MapElements') }</Form.ControlLabel>
                <Form.Control 
                    name="elements"
                    accepter={CheckTreePicker}
                    data={elements}
                    value={[
                        ...elementIds?.map(id => `element-${id}`),
                        ...sublayerIds?.map(id => `sublayer-${id}`)
                    ]}
                    onChange={setElements} />
            </Form.Group>
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit(submitData)}>
                        { t('Save') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default ConfigurationItemForm;
