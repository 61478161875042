import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import { ModuleFilter } from 'features/webmapModule';
import React from 'react'
import FormInput from 'components/inputs/FormInput';
import { t } from 'i18next';
import MapViewFormFields from '../forms/MapViewFormFields';
import { asRoman } from 'utils/math';

const IntervalFilter = () => {
    const module = React.useContext(CrossSectionRoadCountContext);

    const [values, setValues] = React.useState({});

    const {
        stationCode,
        stationYear,
        stationMonth,
        stationDayTypeId
    } = module?.filters;

    function applyValues () {
        if (!module?.applyFilters) return;

        module.applyFilters({
            stationIntervalType: values?.viewType,
            stationYear: values?.year,
            stationMonth: values?.month,
            stationDayTypeId: values?.dayTypeId,
        });
    }

    let label = undefined;
    if (stationYear) {
        label = `${stationYear}. év`;
    }
    if (stationMonth && stationDayTypeId) {
        label += ` ${asRoman(stationMonth)}. hó ${stationDayTypeId}. naptípus`
    }

    return (
        <ModuleFilter 
            name={t('OKKF/SelectedInterval')}
            acceptor={FormInput}
            disabled={!module.initialized || !stationCode}
            popoverWidth={320}
            instructionText={module?.tutorialStep === 3 && t('OKKF/IntervalFilterInstructions')}
            value={values}
            renderValue={() => {
                return label
            }}
            onSubmit={applyValues}
        >
            <MapViewFormFields 
                values={values}
                onChange={setValues} />

        </ModuleFilter>
    )
}

export default IntervalFilter