import React from 'react'
import { t } from 'i18next'
import { noop } from 'utils/constants'
import ConfirmModal from './ConfirmModal'
import { formatString } from 'utils/format'

const ConfirmDeleteModal = ({
    onDelete = noop,
    itemLabel = t('Item'),
    title,
    prompt = t('DeleteItemPrompt'),
    yesLabel = t('Delete'),
    noLabel = t('Cancel'),
    ...props
}) => {
    return (
        <ConfirmModal
            title={title ||formatString(t('DeleteItem'), {item: itemLabel})}
            onYes={onDelete}
            yesLabel={t('Delete')}
            noLabel={t('Cancel')}
            {...props} 
        >
            { prompt }
        </ConfirmModal>
    )
}

export default ConfirmDeleteModal