import React from "react";
import { Divider, Form, Message, SelectPicker, toaster } from "rsuite";
import { t } from "i18next";

import { FormGroup } from "components/forms";
import OnboardCountModuleForm from "./forms/OnboardCountModuleForm";
import CrossSectionRoadCountModuleForm from "./forms/CrossSectionRoadCountModuleForm";
import { deleteCrossSectionRoadCount, deleteOnboardCount, postCrossSectionRoadCount, postOnboardCount } from "../webmapAPI";


const ONBOARD_COUNT = 'onboardCount';
const CROSS_SECTION_ROAD_COUNT = 'crossSectionRoadCount';

const MapModules = ({
    webMap,
    updateWebmap,
    ...props
}) => {

    const defaultType = Object.keys(webMap.modules || {}).includes(CROSS_SECTION_ROAD_COUNT) 
        ? CROSS_SECTION_ROAD_COUNT 
        : ONBOARD_COUNT;

    const [moduleType, setModuleType] = React.useState(defaultType)
    
    const moduleTypeOptions = [{
        value: ONBOARD_COUNT,
        label: t('Map/OnboardCount'),
    }, {
        value: CROSS_SECTION_ROAD_COUNT,
        label: t('Map/CrossSectionRoadCount'),
    }]

    function submitOnboardCount (data) {
        if (data) {
            postOnboardCount(webMap.id, {
                version: webMap?.version,
                ...data
            })
            .then(({data}) => updateWebmap(data))
            .catch(err => {
                console.error(err);
                toaster.push(<Message type="error" showIcon duration={4000} >
                    {t('Map/SaveFailed')}
                </Message>);
            })
        } else {
            deleteOnboardCount(webMap.id)
            .then(({data}) => updateWebmap(data))
            .catch(err => {
                console.error(err);
                toaster.push(<Message type="error" showIcon duration={4000} >
                    {t('Map/SaveFailed')}
                </Message>);
            })
        }
    }

    function submitCrossSectionRoadCount (data) {
        if (data) {
            postCrossSectionRoadCount(webMap.id, {
                version: webMap?.version,
                ...data
            })
            .then(({data}) => updateWebmap(data))
            .catch(err => {
                console.error(err);
                toaster.push(<Message type="error" showIcon duration={4000} >
                    {t('Map/SaveFailed')}
                </Message>);
            })
        } else {
            deleteCrossSectionRoadCount(webMap.id)
            .then(({data}) => updateWebmap(data))
            .catch(err => {
                console.error(err);
                toaster.push(<Message type="error" showIcon duration={4000} >
                    {t('Map/SaveFailed')}
                </Message>);
            })
        }
    }

    return <>
        <Form>
            <FormGroup 
                name="moduleType"
                label={ t("Map/ModuleType") }
                accepter={ SelectPicker }
                data={ moduleTypeOptions }
                value={ moduleType }
                onChange={ setModuleType }
                cleanable={ false }
                searchable={ false }
            />
        </Form>

        <Divider />

        { moduleType === ONBOARD_COUNT ? (
            <OnboardCountModuleForm 
                webMap={webMap}
                onSubmit={submitOnboardCount}
            />
        ) : <></> }

        { moduleType === CROSS_SECTION_ROAD_COUNT ? (
            <CrossSectionRoadCountModuleForm 
                webMap={webMap} 
                onSubmit={submitCrossSectionRoadCount}
            />
        ) : <></> }
    </>
};


export default MapModules;
