import { noop } from "utils/constants";

import PageListItem from "./PageListItem";
import PlaceholderList from "./PlaceholderList";


const PageList = ({
    data,
    endpoint,
    pageSize,
    renderItem = noop,
    itemHeight,
    ...props
}) => {
    return (
        <PlaceholderList 
            data={data} 
            renderItem={(item, index) => renderItem(item, index) || (
                <PageListItem 
                    key={index}
                    endpoint={endpoint} 
                    item={item}
                    {...props}
                />
            )}
            pageSize={pageSize}
            itemHeight={itemHeight}
        />
    );
};


export default PageList;
