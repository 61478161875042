import { onboardCountColors } from "features/onboardCount/utils/colors";


const DEFAULT_COLOR = onboardCountColors.area


function areaRenderer ({ color = DEFAULT_COLOR } = {}) {
    return {
        type: 'simple',
        symbol: {
            type: 'simple-fill',
            color: color.setAlpha(0.2).array,
            outline: {
                color: color.array,
                width: 1,
            },
        },
    };
};

export default areaRenderer;

