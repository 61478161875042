import CIMSymbol from '@arcgis/core/symbols/CIMSymbol';
import { crossSectionRoadCountColors } from 'features/crossSectionRoadCount/utils/colors';
import roadSegmentSymbol from "features/crossSectionRoadCount/data/roadSegmentSymbol.json";


function getSymbol (width, color = "black") {

    return new CIMSymbol({
        data: {
            type: 'CIMSymbolReference',
            symbol: {
                "type": "CIMLineSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidStroke",
                    "primitiveName": "fill",
                    "enable": true,
                    "colorLocked": true,
                    "capStyle": "Butt",
                    "joinStyle": "Round",
                    "lineStyle3D": "Strip",
                    "miterLimit": 10,
                    "width": width - 0.5,
                    "color": [
                      255,
                      255,
                      255,
                      255
                    ]
                  },
                  {
                    "type": "CIMSolidStroke",
                    "primitiveName": "outline",
                    "enable": true,
                    "capStyle": "Butt",
                    "joinStyle": "Miter",
                    "lineStyle3D": "Strip",
                    "miterLimit": 10,
                    "width": width,
                    "color": color,
                    "colorLocked": false
                  }
                ]
              },
        }
    })
}


const roadNetworkRenderer = (field = 'KUTKA') => {
    return {
        type: 'unique-value',
        field,
        uniqueValueInfos: [{
            value: '1',
            symbol: getSymbol(2.2, [0, 0, 0, 255]),
        }, {
            value: '2',
            symbol: getSymbol(2, [0, 0, 0, 255]),
        }, {
            value: '3',
            symbol: getSymbol(1.7, [0, 0, 0, 255]),
        }, {
            value: '4',
            symbol: getSymbol(1.5, [0, 0, 0, 255]),
        }, {
            value: '5',
            symbol: getSymbol(0.8, [128, 128, 128, 255]),
        }, {
            value: '6',
            symbol: getSymbol(0.5, [128, 128, 128, 255]),
        }, {
            value: '7',
            symbol: getSymbol(0.5, [128, 128, 128, 255]),
        }]
    }
}

export default roadNetworkRenderer