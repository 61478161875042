import React from 'react'
import { Panel } from 'rsuite'
import { t } from 'i18next'

import { PanelHeader, PanelMenu } from 'components/panels'
import ModuleTableToolbar from './ModuleTableToolbar'
import { PageSection } from 'components/pages'


const ModuleTablePanel = ({
    module,
    title,
    children
}) => {
    const toolbarHeight = module?.toolbarRef?.current?.offsetHeight || 0;

    return (
        <PageSection id='tablePanel' offset={toolbarHeight + 10}>
            <Panel
                className='tablePanel'
                bordered
                shaded
                header={<PanelHeader
                    title={title || t('Table')}
                    toolbar={<ModuleTableToolbar module={module} />}
                />}
            >
                <PanelMenu navs={module?.tableMenu?.navs} module={module} />
                {children}

            </Panel>

        </PageSection>
    )
};

export default ModuleTablePanel;