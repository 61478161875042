import forwardSectionSymbol from 'features/webmap/data/forwardSectionSymbol.json';
import backwardSectionSymbol from 'features/webmap/data/backwardSectionSymbol.json';
import { onboardCountColors } from "features/onboardCount/utils/colors"
import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";

import { countCeilingRound } from "utils/math";


const SIZE_STOP_MODIFIERS = {
    200000: 0.25,
    50000: 0.5,
    20000: 1,
    10000: 2,
    2000: 4
};


function getStops({ width = 6 } = {}) {
    return Object.entries(SIZE_STOP_MODIFIERS).map(([value, modifier]) => ({
        value,
        size: width * modifier
    })).sortBy('value');
}


function getOffsetExpression({ backward, width = 6} = {}) {
    let expression = '';

    const stops = getStops({ width });


    let defaultExpression = '';
    stops.map((stop, index) => {
        const value = stops?.[index]?.value;
        const size = stop?.size;

        const offset = (backward ? 1 : -1) * (size);

        if (value === undefined) {
            defaultExpression += `
                return ${offset}
            `;
        } else {
            expression += `
                if ( $view.scale < ${value} ) {
                    return ${offset}
                }
        
            `;
        }
    })

    expression += defaultExpression;

    return expression;
};


const SectionSymbol = ({ backward, width = 6 }) => new CIMSymbol({
    data: {
        type: 'CIMSymbolReference',
        symbol: backward ? backwardSectionSymbol : forwardSectionSymbol,
        primitiveOverrides: [
            {
                type: "CIMPrimitiveOverride",
                primitiveName: "lineOffset",
                propertyName: "offset",
                valueExpressionInfo: {
                    type: "CIMExpressionInfo",
                    expression: getOffsetExpression({backward, width}),
                    returnType: 'Numeric',
                }
            }, {
                type: "CIMPrimitiveOverride",
                primitiveName: "labels",
                propertyName: "offsetY",
                valueExpressionInfo: {
                    type: "CIMExpressionInfo",
                    expression: getOffsetExpression({backward, width}),
                    returnType: 'Numeric',
                }
            }, {
                type: "CIMPrimitiveOverride",
                primitiveName: "markerPlacement",
                propertyName: "offset",
                valueExpressionInfo: {
                    type: "CIMExpressionInfo",
                    expression: getOffsetExpression({backward, width}),
                    returnType: 'Numeric',
                }
            }, {
                type: "CIMPrimitiveOverride",
                primitiveName: "labelFill",
                propertyName: "Color",
                valueExpressionInfo: {
                    type: "CIMExpressionInfo",
                    expression: `if ( $view.scale > 20000 ) {
                        return 'transparent'
                    } else {
                        return 'white'
                    }`,
                    returnType: 'String',
                }
            }, {
                type: "CIMPrimitiveOverride",
                primitiveName: "markerFill",
                propertyName: "Color",
                valueExpressionInfo: {
                    type: "CIMExpressionInfo",
                    expression: `if ( $view.scale > 20000 ) {
                        return 'transparent'
                    } else {
                        return 'white'
                    }`,
                    returnType: 'String',
                }
            }, {
                type: "CIMPrimitiveOverride",
                primitiveName: "textOverride",
                propertyName: "TextString",
                valueExpressionInfo: {
                    type: "CIMExpressionInfo",
                    expression: `if ( $view.scale > 20000 ) {
                        return ''
                    } else {
                        return $feature.${backward ? 'backward' : 'forward'}
                    }`,
                    returnType: "Default"
                }
            }
        ]
    },
});


function sectionRenderer({
    field,
    min,
    med,
    max,
    width = 6,
    backward
}) {
    return {
        type: 'simple',
        symbol: SectionSymbol({ backward }),
        visualVariables: [{
            type: "size",
            valueExpression: "$view.scale",
            stops: getStops({width}),
        }, {
            type: 'color',
            field,
            stops: [
                { value: min, color: onboardCountColors.onwardStart.array },
                { value: med, color: onboardCountColors.onward.array },
                { value: countCeilingRound(max), color: onboardCountColors.onwardEnd.array }
            ]
        }]
    };
};


export default sectionRenderer;
