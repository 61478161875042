import Color from "utils/color";

export const crossSectionRoadCountColors = {
    black: Color([0, 0, 0]),
    white: Color([255, 255, 255]),
    transparent: Color([0, 0, 0, 0.0]),
    traffic: Color('#d0d068'),
    trafficMin: Color('#0000ff'),
    trafficMax: Color('#ff0000'),
    highwaySymbol: Color([216, 216, 216]),
    mainRoadSymbol: Color([224, 224, 224]),
    roadSymbol: Color([232, 232, 232]),
    highway: Color([0, 64, 255]),
    mainRoad: Color([0, 128, 0]),
    settlement: Color([160, 160, 160]),
    A: Color("#115f9a"),
    A1: Color("#115f9a"),
    A2: Color("#1984c5"),
    B1: Color("#76c68f"),
    B2: Color("#a6d75b"),
    C: Color("#e1a692"),
    C1k: Color("#e1a692"),
    "C1n+C2": Color("#de6e56"),
    D: Color("#e14b31"),
    E: Color("#c23728"),
    F: Color("#991f17"),
    G: Color("#ffb400"),
    H: Color("#d2980d"),
    I: Color("#a57c1b"),
    traffic0: Color([136, 169, 205]),
    traffic1: Color([108, 151, 166]),
    traffic2: Color([71, 143, 141]),
    traffic4: Color([104, 153, 115]),
    traffic6: Color([137, 164, 89]),
    traffic8: Color([170, 174, 63]),
    traffic10: Color([203, 184, 37]),
    traffic15: Color([235, 195, 11]),
    traffic20: Color([236, 195, 11]),
    traffic25: Color([233, 182, 21]),
    traffic30: Color([230, 170, 32]),
    traffic40: Color([227, 157, 43]),
    traffic50: Color([224, 145, 54]),
    traffic60: Color([221, 133, 65]),
    traffic80: Color([218, 120, 76]),
    traffic100: Color([215, 108, 87]),
    traffic150: Color([213, 96, 98]),
}
