import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Form, InputNumber, SelectPicker, TreePicker } from "rsuite";
import { t } from "i18next";

import { noop } from "utils/constants";
import { FormGroup } from 'components/forms';
import { getOrganizations } from 'features/onboardCount/onboardCountAPI';


const OnboardCountModuleForm = ({ 
    webMap,
    onSubmit = noop,
    onCancel = noop,
    ...props 
}) => {

    const [ organizations, setOrganizations ] = React.useState();

    const [ stopPlaceElementId, setStopPlaceElementId ] = React.useState(webMap?.modules?.onboardCount?.stopPlaceElementId);
    const [ sectionElementId, setSectionElementId ] = React.useState(webMap?.modules?.onboardCount?.sectionElementId);
    const [ operatorId, setOperatorId ] = React.useState(webMap?.modules?.onboardCount?.operatorId);
    const [ organizerId, setOrganizerId ] = React.useState(webMap?.modules?.onboardCount?.organizerId);
    const [ minTrafficSize, setMinTrafficSize ] = React.useState(webMap?.modules?.onboardCount?.minTrafficSize);
    const [ maxTrafficSize, setMaxTrafficSize ] = React.useState(webMap?.modules?.onboardCount?.maxTrafficSize);

    const elementOptions = webMap?.elements.map(element => ({
        label: element.name,
        value: element.id
    }))

    const submitData = {
        stopPlaceElementId,
        sectionElementId,
        operatorId,
        organizerId,
        minTrafficSize,
        maxTrafficSize,
    }

    const organizationOptions = Object.values(organizations || {}).map(organization => ({
        label: organization.name,
        value: organization.id
    })).sortBy('label')

    React.useEffect(() => {
        getOrganizations()
            .then(({data}) => setOrganizations(data));
    }, [])

    return (
        <Form {...props}>

            <FormGroup 
                name={`stopPlaceElementId`}
                label={`${t('FT/StopPlacesElement')}`}
                accepter={SelectPicker}
                data={elementOptions}
                value={stopPlaceElementId}
                cleanable={ false }
                onChange={ setStopPlaceElementId }
            />

            <FormGroup 
                name={`sectionElementId`}
                label={`${t('FT/SegmentsElement')}`}
                accepter={SelectPicker}
                data={elementOptions}
                value={sectionElementId}
                cleanable={ false }
                onChange={ setSectionElementId }
            />

            <FormGroup 
                name={`organizerId`}
                label={`${t('FT/Organizer')}`}
                accepter={SelectPicker}
                data={ organizationOptions }
                value={organizerId}
                cleanable={ false }
                onChange={ setOrganizerId }
            />

            <FormGroup 
                name={`operatorId`}
                label={`${t('FT/Operator')}`}
                accepter={SelectPicker}
                data={ organizationOptions }
                value={operatorId}
                cleanable={ false }
                onChange={ setOperatorId }
            />

            <FormGroup 
                name="minTrafficSize"
                label={t('FT/MinTrafficSize')}
                accepter={InputNumber}
                value={minTrafficSize}
                onChange={setMinTrafficSize}
            />
            
            <FormGroup 
                name="maxTrafficSize"
                label={t('FT/MaxTrafficSize')}
                accepter={InputNumber}
                value={maxTrafficSize}
                onChange={setMaxTrafficSize}
            />

            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit && onSubmit(submitData)}>
                        { t('Save') }
                    </Button>
                    <Button appearance="primary" color="red" onClick={() => onSubmit && onSubmit()}>
                        { t('Delete') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default OnboardCountModuleForm;
