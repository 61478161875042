import 'assets/css/layout.css'

import React from "react";
import { useTranslation } from "react-i18next";
import { Affix, Dropdown, Nav, Navbar as RSNavbar, Toggle } from "rsuite";
import { useNavigate } from 'react-router-dom';

import { AvatarCard } from 'components/cards';
import { AdminIcon, LogoutIcon, PlusIcon, SubscriptionIcon, UserIcon } from 'components/icons';
import { AuthContext } from 'features/auth';
import { getOrganizationList } from 'features/organization';
import { openInNewTab } from 'utils/browser';
import { WebMapModal } from 'features/webmap';
import MediaQueryContext from 'context/MediaQueryContext';

export const CustomNavbar = ({ 
    children, 
    ...props 
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, adminVisible, showAdminTools, isAdmin, logout } = React.useContext(AuthContext);
    const { isMobile } = React.useContext(MediaQueryContext);
    
    const [organizations, setOrganizations] = React.useState();
    const [createMapVisible, setCreateMapVisible] = React.useState(false);
    const [createCollectionVisible, setCreateCollectionVisible] = React.useState(false);

    React.useEffect(() => {
        getOrganizationList().then(({data}) => {
            if (!data) { return }
            setOrganizations(data);
        });
    }, [user]);

    return <>
        <RSNavbar className="navbar" appearance='subtle' {...props} >
            <RSNavbar.Brand href="/">
                <img alt="brandImg" className="brandImg" src='/oneplanet_portal_logo.svg' /> 
            </RSNavbar.Brand>
            <Nav>
                { children }
                { !user?.blogActive ? <></> : (
                    <Nav.Item onClick={() => navigate('/blog')}>
                        {t('Blog')}
                    </Nav.Item>
                )}
                { isAdmin && (
                    <Nav.Item onClick={() => openInNewTab('/admin')}>
                        {t('Navigation/Admin')}
                    </Nav.Item>
                )}
            </Nav>
            <Nav pullRight>
                { isAdmin && (
                    <Toggle 
                        title={adminVisible ? t('Navigation/HideAdminTools') : t('Navigation/ShowAdminTools')}
                        checkedChildren={<AdminIcon />}
                        unCheckedChildren={<AdminIcon />}
                        checked={adminVisible} 
                        onChange={showAdminTools} />
                )}
                { user && <>
                    <Dropdown title={isMobile ? '' : user?.username} placement="bottomEnd" icon={<UserIcon />}>
                        { isMobile && (
                        <Dropdown.Item panel style={{padding: '.5rem', width: '200px'}}>
                            {user?.username}
                        </Dropdown.Item>
                        )}
                        { organizations?.length > 0 && <>
                            <Dropdown.Item panel style={{padding: '.5rem', width: '200px'}}>
                                <h6>{t('Navigation/Organizations')}</h6>
                            </Dropdown.Item>
                            <div className='organizations'>
                                { organizations?.map( organization => (
                                    <Dropdown.Item 
                                        key={`${organization.id}`} 
                                        eventKey={`${organization.id}`} 
                                        style={{padding: 0}}
                                        onClick={() => navigate(`/organizations/${organization.id}`)}
                                    >
                                        <AvatarCard bordered={false} usePlaceholder title={organization.name} imageURL={organization?.thumbnailUrl} />
                                    </Dropdown.Item>
                                ))}
                            </div>
                            <Dropdown.Separator />
                        </>}
                        
                        <Dropdown.Item 
                            eventKey='userAccount'
                            icon={<UserIcon />}
                            onClick={() => navigate('/profile')}
                        >
                            {t('Profile/UserAccount')}
                        </Dropdown.Item>
                        { isAdmin && <>
                            <Dropdown.Item 
                                eventKey='registerUser'
                                icon={<PlusIcon />}
                                onClick={() => navigate('/register_other')}
                            >
                                {t('Security/RegisterUser')}
                            </Dropdown.Item>
                    </>}
                        <Dropdown.Item 
                            eventKey='logout' 
                            icon={<LogoutIcon />}
                            onClick={() => logout().then(() => navigate('/'))}
                        >
                            {t('Navigation/Logout')}
                        </Dropdown.Item>
                    </Dropdown>
                </> }
            </Nav>
        </RSNavbar>
        <WebMapModal visible={createMapVisible} setVisible={setCreateMapVisible} />
    </>;
};

export const FloatingNavbar = ({
    children,
    ...props 
}) => {
    return (
        <Affix>
            <CustomNavbar {...props} >
                {children}
            </CustomNavbar>
        </Affix>
    );
};
const Navbar = ({
    floating,
    children,
    ...props 
}) => {
    return floating 
        ? <FloatingNavbar {...props}>{children}</FloatingNavbar>
        : <CustomNavbar {...props}>{children}</CustomNavbar>
};

export default Navbar