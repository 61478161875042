import React from 'react'

const HeaderCellContent = ({
    name,
    unit,
    hint
}) => {
    return (
        <div title={hint} style={{textAlign: 'center'}}>
            <div>{name}</div>
            {unit && (
                <div>[{unit}]</div>
            )}
        </div>
    )
}

export default HeaderCellContent