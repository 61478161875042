import React from 'react';

import { CrossSectionRoadCountChartPanel, CrossSectionRoadCountContext, CrossSectionRoadCountTablePanel } from 'features/crossSectionRoadCount';
import { OnboardCountChartPanel, OnboardCountContext, OnboardCountTablePanel } from 'features/onboardCount';
import TutorialLayover from './layovers/TutorialLayover';

const ModuleContent = () => {

    const onboardCount = React.useContext(OnboardCountContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);

    return <>
        { crossSectionRoadCount?.moduleParameters?.id && (
            <TutorialLayover position="bottom-center"
                step={crossSectionRoadCount?.tutorialStep}
                total={crossSectionRoadCount?.tutorialSteps}
                onChange={crossSectionRoadCount?.setTutorialStep}
            />
        )}
        <OnboardCountTablePanel />
        <OnboardCountChartPanel />
        <CrossSectionRoadCountTablePanel />
        <CrossSectionRoadCountChartPanel />
        
    </>;
};

export default ModuleContent;