import 'assets/css/webmapModule.css';

import React from 'react';
import { Panel } from 'rsuite';
import { t } from 'i18next';

import { PanelHeader } from 'components/panels';
import ModuleChartToolbar from './ModuleChartToolbar';
import PanelMenu from 'components/panels/PanelMenu';
import { PageSection } from 'components/pages';


const ModuleChartPanel = ({
    module,
    title,
    children
}) => {
    const toolbarHeight = module?.toolbarRef?.current?.offsetHeight || 0;

    return (
        <PageSection id='chartPanel' offset={toolbarHeight + 10}>
            <Panel
                className='chartPanel'
                bordered
                shaded
                header={<PanelHeader
                    title={title || t('Chart')}
                    toolbar={<ModuleChartToolbar module={module} />}
                />}
            >
                <PanelMenu navs={module?.chartMenu?.navs} module={module} />
                {children}

            </Panel>
        </PageSection>
    )
};

export default ModuleChartPanel;