import React from 'react'
import { t } from 'i18next';

import { ModuleStatus } from 'features/webmapModule';
import OnboardCountContext from '../context/OnboardCountContext';
import { MONTH_INTERVAL, TIMETABLE_INTERVAL, WEEK_INTERVAL } from '../utils/constants';
import CountInterval from '../elements/CountInterval';


const OnboardCountStatus = () => {
    const count = React.useContext(OnboardCountContext);

    const {
        countIntervals = {},
    } = count?.elements || {};

    const weekIntervals = Object.values(countIntervals)
        .filter(interval => interval?.type === WEEK_INTERVAL);
    const monthIntervals = Object.values(countIntervals)
        .filter(interval => interval?.type === MONTH_INTERVAL);

    let timetableIntervalIds = [];
    Object.values(countIntervals)
        .filter(interval => interval?.type === TIMETABLE_INTERVAL)
        .map(interval => CountInterval({...interval, dayTypeId: undefined}))
        .forEach(interval => {
            if (!timetableIntervalIds.includes(interval.str)) {
                timetableIntervalIds.push(interval.str)
            }
        });
    const timetableIntervals = timetableIntervalIds.map(id => CountInterval(id));

    const lastWeekInterval = weekIntervals.length && 
        weekIntervals[weekIntervals.length - 1];
    const lastMonthInterval = monthIntervals.length && 
        monthIntervals[monthIntervals.length - 1];
    const lastTimetableInterval = timetableIntervals.length && 
        timetableIntervals[timetableIntervals.length - 1];

    return (
        <ModuleStatus>
            <div style={{ fontWeight: 'bold' }}>
                {t('FT/LastCountWeek')}:
            </div>
            <div>
                { lastWeekInterval ? 
                    `${lastWeekInterval.label}, ${lastWeekInterval.intervalStr}` : 
                    t('FT/NoData')
                }
            </div>
            <div style={{ fontWeight: 'bold', marginTop: 10 }}>
                {t('FT/LastCountMonth')}:
            </div>
            <div>
                { lastMonthInterval ? 
                    `${lastMonthInterval.label}, ${lastMonthInterval.intervalStr}` : 
                    t('FT/NoData')
                }
            </div>
            <div style={{ fontWeight: 'bold', marginTop: 10 }}>
                {t('FT/LastCountInterval')}:
            </div>
            <div>
                { lastTimetableInterval ? 
                    lastTimetableInterval.intervalStr : 
                    t('FT/NoData')
                }
            </div>
        </ModuleStatus>
    );
};


export default OnboardCountStatus;
