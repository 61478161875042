import React from "react";
import { noop } from "utils/constants";


const AuthContext = React.createContext({
    user: undefined,
    isAnonymous: true,
    isAdmin: false,
    adminVisible: false,
    showAdminTools: noop,
    login: () => console.error('Login function not provided.'),
    register: () => console.error('Register function not provided.'),
    logout: () => console.error('Logout function not provided.'),
});


export default AuthContext;