import { WebmapContext } from 'features/webmap';
import React from 'react'
import useCrossSectionRoadCount from '../hooks/useCrossSectionRoadCount';

const CrossSectionRoadCountContext = React.createContext();


export const CrossSectionRoadCountContextProvider = ({
    children
}) => {
    const {webmap, arcGISMap, setJoins} = React.useContext(WebmapContext);
    const count = useCrossSectionRoadCount({
        webmap,
        arcGISMap,
        setJoins
    })

    return (
        <CrossSectionRoadCountContext.Provider value={count}>
            { children }
        </CrossSectionRoadCountContext.Provider>
    );
};




export default CrossSectionRoadCountContext