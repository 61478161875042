import React from 'react'
import { t } from 'i18next';

import ModuleTablePanel from 'features/webmapModule/components/table/ModuleTablePanel';
import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount/context';
import { MONTHLY_TRAFFIC, MONTHLY_TRAFFIC_BY_YEAR, TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH, YEARLY_TRAFFIC_BY_YEAR } from 'features/crossSectionRoadCount/utils/constants';
import { MonthlyTrafficTable, YearlyTrafficTable } from '.';
import { Message } from 'rsuite';
import MonthlyIntervalForm from '../forms/MonthlyIntervalForm';

const CrossSectionRoadCountTablePanel = () => {

    const count = React.useContext(CrossSectionRoadCountContext);

    if (!count?.filters?.stationCode) return;

    const menu = count?.tableMenu;

    return (
        <ModuleTablePanel
            module={count}
            title={t('OKKF/StationTraffic')}
        >
            { menu?.values[1] === YEARLY_TRAFFIC_BY_YEAR && <>
                <YearlyTrafficTable                 
                    ref={count?.table} />
            </> }

            { menu?.values[1] === MONTHLY_TRAFFIC && <>
                <MonthlyTrafficTable             
                    ref={count?.table} />
            </> }

            { menu?.values[1] === MONTHLY_TRAFFIC_BY_YEAR && <>
                <div style={{padding: 4}}>
                    <MonthlyIntervalForm layout="inline" />
                </div>
                { count?.filters?.stationMonth && count?.filters?.stationDayTypeId ? (
                    <MonthlyTrafficTable
                        month={count?.filters?.stationMonth}  
                        dayTypeId={count?.filters?.stationDayTypeId}  
                        ref={count?.table} />
                ) : (
                    <Message type="warning">
                        {t('OKKF/SelectMonthlyView')}
                    </Message>
                )}
            </> }

            { [YEARLY_TRAFFIC_BY_YEAR, MONTHLY_TRAFFIC_BY_YEAR].includes(menu?.values[1]) && <>
            
                <Message showIcon type="info">
                        {t('OKKF/VehicleClasses2023Message')}
                </Message>
            </> }

            { menu?.values[1] === TRAFFIC_BY_MONTH && <>
                <MonthlyTrafficTable
                    month={count?.filters?.stationMonth}  
                    ref={count?.table} />
            </> }

            { menu?.values[1] === TRAFFIC_BY_DAY_TYPE && <>
                <MonthlyTrafficTable
                    dayTypeId={count?.filters?.stationDayTypeId}  
                    ref={count?.table} />
            </> }


        </ModuleTablePanel>
    )
}

export default CrossSectionRoadCountTablePanel