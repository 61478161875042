import React from 'react';
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import { t } from 'i18next';


const TransportLineCard = ({
    size = 'md',
    name,
    color,
    inline
}) => {
    return (
        <AvatarCard
            size={size}
            icon={<TransportLineBadge size={size} name={name} color={color} />}
            title={t('FT/AllServicePatterns')}
            inline={inline}
        />
    );
};


export default TransportLineCard;
