import { crossSectionRoadCountColors } from "features/crossSectionRoadCount/utils/colors";


function roadNetworkLabeling () {

    const font = {
        family: "Arial",
        style: "normal",
        weight: "bold",
        size: 9
    };

    const baseLabeling = {
        deconflictionStrategy: 'static', // 'static' or 'none'
        labelPlacement: "always-horizontal",
        labelPosition: "parallel",
        labelExpressionInfo: {
            expression: `$feature.KSZAM`
        },
        allowOverrun: false,
        repeatLabel: true,
        repeatLabelDistance: 200,
    }

    const highwayLabeling = {
        symbol: {
            type: 'text',
            color: crossSectionRoadCountColors.white.array,
            haloColor: crossSectionRoadCountColors.highway.array,
            haloSize: 1.5,
            borderLineSize: 1,
            font: {
                ...font,
                size: 10,
            }
        },
        ...baseLabeling,
        where: "KUTKA IN ('1', '2')",
    };

    const mainRoadLabeling =  {
        symbol: {
            type: 'text',
            color: crossSectionRoadCountColors.white.array,
            haloColor: crossSectionRoadCountColors.mainRoad.array,
            haloSize: 1.2,
            borderLineSize: 1,
            font: {
                ...font,
                size: 10,
            }
        },
        ...baseLabeling,
        where: "KUTKA IN ('3', '4')",
        minScale: 1500000,
    };


    const roadLabeling = {
        symbol: {
            type: 'text',
            color: crossSectionRoadCountColors.white.array,
            haloColor: crossSectionRoadCountColors.black.array,
            haloSize: 1,
            font: {
                ...font,
                size: 8,
            }
        },
        ...baseLabeling,
        where: "KUTKA IN ('5', '6', '7')",
        minScale: 150000,
    };


    return [highwayLabeling, mainRoadLabeling, roadLabeling];
};


export default roadNetworkLabeling;

