import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Form, Input, SelectPicker } from "rsuite";
import { t } from "i18next";

import { FormGroup } from 'components/forms';
import { noop } from "utils/constants";


const MapPropertiesForm = ({ 
    webMap,
    onSubmit = noop,
    onCopyVersion = noop,
    ...props 
}) => {

    const [name, setName] = React.useState(webMap?.name || '');
    const [location, setLocation] = React.useState(webMap?.location || '');
    const [version, setVersion] = React.useState(webMap?.versionName || '');
    const [published, setPublished] = React.useState(webMap?.public || false);
    const [basemap, setBasemap] = React.useState(webMap?.basemap);
    const [extentId, setExtentId] = React.useState(webMap?.extentId);
    const [legendPosition, setLegendPosition] = React.useState(webMap?.legend?.position);

    const publishedOptions = [
        {label: t('Public'), value: 1},
        {label: t('Private'), value: 0}
    ];

    const basemapOptions = [
        {
            label: '-',
            value: '-',
        },
        ...Object.entries(webMap?.basemaps || {}).map(([esriName, name]) => ({
            label: name,
            value: esriName,
        }))
    ];

    const extentOptions = Object.entries(webMap?.extents || {}).map(([id, name]) => ({
        label: name,
        value: parseInt(id),
    }));

    const widgetPositions = Object.entries(webMap?.widgetPositions || {}).map(([esriName, name]) => ({
        label: name,
        value: esriName,
    }));

    const submitData = {
        name,
        version,
        location,
        public: published,
        baseMapName: basemap,
        extentId,
        legendPositionName: legendPosition,
    };

    return (
        <Form {...props}>
            <FormGroup
                name='name'
                label={ t('Name') }
                accepter={Input}
                value={name}
                onChange={setName}
            />
            <FormGroup 
                name='location'
                label={ t('Map/Location') }
                accepter={Input}
                value={location}
                onChange={setLocation}
            />
            <FormGroup 
                name='version'
                label={ t('Version') }
                accepter={Input}
                value={version}
                onChange={setVersion}
            />
            <FormGroup 
                name='published'
                label={ t('Map/Published') }
                accepter={ SelectPicker }
                searchable={false}
                cleanable={false}
                data={publishedOptions} 
                value={published ? 1 : 0}
                onChange={setPublished}
            />
            <FormGroup 
                name='basemap'
                label={ t('Map/Basemap') }
                accepter={ SelectPicker }
                data={basemapOptions} 
                value={basemap}
                onChange={setBasemap}
            />
            <FormGroup 
                name='extent'
                label={ t('Map/Extent') }
                accepter={ SelectPicker }
                data={extentOptions} 
                value={extentId}
                onChange={setExtentId}
            />
            <FormGroup 
                name='legendPosition'
                label={ t('Map/LegendPosition') }
                accepter={ SelectPicker }
                data={widgetPositions} 
                value={legendPosition}
                onChange={setLegendPosition}
            />
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit(submitData)}>
                        { t('Save') }
                    </Button>
                    <Button appearance="subtle" onClick={onCopyVersion}>
                        { t('Map/CopyVersion') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default MapPropertiesForm;
