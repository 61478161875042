import React from 'react';
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import StartTimeBadge from '../badges/StartTimeBadge';
import StopTimeBadge from '../badges/StopTimeBadge';
import { secondsToString } from 'features/onboardCount/utils/time';


const JourneyStopCard = ({
    size="md",
    journey: {
        servicePattern,
        startTime,
    } = {},
    arrivalTime,
    departureTime,
}) => {
    return (
        <AvatarCard
            size={size}
            icon={<>
                <TransportLineBadge size={size} {...servicePattern.transportLine} />
                <StartTimeBadge size={size} startTime={startTime} />
                { startTime !== arrivalTime && startTime !== departureTime && (
                    <StopTimeBadge size={size} arrivalTime={arrivalTime} departureTime={departureTime} />
                ) }
            </>}
            title={servicePattern.routeName}
            subtitle={servicePattern.name}
            hint={`${secondsToString(startTime)} ${servicePattern.transportLine.name} ${servicePattern.routeName} (${servicePattern.name})`}
        />
    );
};


export default JourneyStopCard;
