import React from 'react'
import { t } from 'i18next'
import DataTable from 'features/dataTable/components/DataTable'
import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount/context'
import { YEARLY_COUNT_METHODS } from 'features/crossSectionRoadCount/utils/constants'
import HeaderCellContent from './HeaderCellContent'


function getColumnTypes (vehicleClasses) {
    return [{
        values: [{
            key: 'year',
            label: t('Year'),
        }, {
            key: 'calculationType',
            label: t('OKKF/CalculationType'),
            cellProps: {
                width: 100
            }
        }, {
            key: 'averageUnits',
            label: <HeaderCellContent name={t('OKKF/AADT')} unit={t('OKKF/UnitVehiclePerDay')} hint={t('OKKF/AnnualAverageDailyTraffic')} />,
            csvLabel: t('OKKF/AADT'),
            cellProps: {style: {fontWeight: 'bold'}},
        }, {
            key: 'peakHourUnits',
            label: <HeaderCellContent name={t('OKKF/HHT')} unit={t('OKKF/UnitVehiclePerHour')} hint={t('OKKF/HighestHourlyTraffic')} />,
            csvLabel: t('OKKF/HHT'),
        }, {
            key: 'axisUnits',
            label: <HeaderCellContent name={t('OKKF/ADUA')} unit={t('OKKF/UnitAxisPerDay')} hint={t('OKKF/AverageDailyUnitAxes')} />,
            csvLabel: t('OKKF/ADUA'),
        }, ...Object.values(vehicleClasses || {}).map(vehicleClass => ({
            key: vehicleClass.code,
            label: <HeaderCellContent name={vehicleClass.code} unit={t('OKKF/VehiclePerDay')} hint={vehicleClass.name} />,
            csvLabel: vehicleClass.code,
            cellProps: {
                nullValue: 0
            },
        }))]
    }]
}


const YearlyTrafficTable = React.forwardRef(({
    ...props
}, ref) => {

    const count = React.useContext(CrossSectionRoadCountContext);
    
    const { station } = count?.selectedElements;
    const { vehicleClasses } = count?.elements;
    const data = Object.values(station?.yearlyTrafficByYear || {})
        .sortBy('year', true)
        .map(row => {
            const newRow = {
                year: Math.round(row.year),
                amount: Math.round(row.amount),
                averageUnits: Math.round(row.averageUnits),
                axisUnits: Math.round(row.axisUnits),
                peakHourUnits: Math.round(row.peakHourUnits),
                calculationType: YEARLY_COUNT_METHODS[row?.calculationType]
            }

            Object.values(row?.vehicleClasses || {}).forEach(vehicleClass => {
                newRow[vehicleClass.code] = Math.round(vehicleClass.amount || 0)
            });

            return newRow
        })

    const columnTypes = getColumnTypes(vehicleClasses);

    return (
        <DataTable
            ref={ref}
            title={t('OKKF/YearlyTrafficTableTitle')}
            exportName={t('OKKF/AnnualAverageDailyTrafficTimeSeries')}

            hover
            bordered
            cellBordered
            columnWidth={64}

            data={data}
            columnTypes={columnTypes}
            getRowKey = {row => row.year}

            rowHeight={40}
            headerHeight={40}
            height={500}
            {...props}
        />
    )
})

export default YearlyTrafficTable