import React from 'react'

function useMenu({
    data = [],
    defaultValues = [],
}) {
    const [ values, setValues ] = React.useState(defaultValues);

    const navs = [{
        data,
        value: undefined,
        setValue: value => setValues([value])
    }];
    
    values.map((activeKey, i) => {
        const parent = navs[navs.length - 1]
        parent.value = activeKey;
        const nextNav = parent.data.filter(nav => nav.key === activeKey)[0]?.children;
        if (nextNav) {
            navs.push({
                data: nextNav,
                value: undefined,
                setValue: value => setValues([...values.slice(0, i + 1), value])
            })
        }
    })

    return {
        navs,
        values,
        setValues,
    }
}

export default useMenu