import React from 'react';
import { t } from "i18next";

import { PageList, PageListItem } from "components/lists";
import { PaginatedPanel } from "features/pagination";

import { AuthContext } from "features/auth";
import { getSubscriptionList } from '../subscriptionAPI';
import { formatDate } from 'utils/format';


const SubscriptionListPanel = ({
    valid,
    past,
    future,
    defaultPageSize,
    pageSizeOptions,
}) => {

    let title;
    if (valid) {
        title = t('ValidSubscriptions')
    } else if (past) {
        title = t('PastSubscriptions')
    } else if (future) {
        title = t('FutureSubscriptions')
    } else {
        title = t('Subscriptions')
    }

    return (
        <PaginatedPanel 
            title={title}
            getItems={({page, pageSize}) => getSubscriptionList({
                valid,
                past,
                future,
                page,
                pageSize,
            })}
            renderItems={({data, pageSize}) => <PageList 
                data={data}
                pageSize={pageSize}
                getTitle={item => <>
                    {item.name}{item?.organization ? ` (${item.organization})` : ''}
                </>}
                getSubtitle={item => <>
                    {t('ValidUntil')}: {formatDate(new Date(item.validUntil), {dateSeparator: '.'})}
                    
                </>}
            />}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
        />
    )
};


export default SubscriptionListPanel;
