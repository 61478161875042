import React from "react";
import { List, Modal, Panel } from "rsuite";
import { t } from "i18next";

import { AvatarCard } from "components/cards";
import { ESRIIcon } from "components/icons";
import { noop } from "utils/constants";

import MapElementForm from "./forms/MapElementForm";
import PopupForm from "./forms/PopupForm";


const SettingsWrapper = ({ level, itemKey, index, children }) => {
    return  level ? (
        <div style={{marginLeft: 16*level}}>
            {children}
        </div>
    ) : (
        <List.Item key={itemKey} index={index}>
            {children}
        </List.Item>
    );
};


const MapElementSettings = ({
    element,
    onChange = noop,
    level = 0
}) => {

    const [subLayersVisible, setSubLayersVisible] = React.useState(false);
    const [popupVisible, setPopupVisible] = React.useState(false);
    const [editorVisible, setEditorVisible] = React.useState(false);

    const columns = element?.fields.map(fieldName => ({
        label: fieldName,
        value: fieldName,
    }));

    const onParamChange = params => onChange({
        id: element.id,
        type: element.type,
        params
    });
    
    const isMapLayer = ['map_image_layer', 'tile_layer'].includes(element?.type);

    const actions = [{
        appearance: element?.visible ? 'primary' : 'subtle',
        icon: <ESRIIcon name={element?.visible ? "visible" : "non-visible"} />,
        title: t('Map/Visible'),
        onClick: () => onParamChange({visible: !element?.visible})
    }, {
        appearance: element?.legendEnabled ? 'primary' : 'subtle',
        icon: <ESRIIcon name="legend" />,
        title: t('Map/Legend'),
        onClick: () => onParamChange({legend: !element?.legendEnabled})
    }];
    
    if (!isMapLayer) {
        actions.push({
            appearance: element?.labelItems ? 'primary' : 'subtle',
            icon:<ESRIIcon name="labels" />,
            title: t('Map/LabelItems'),
            onClick: () => onParamChange({labelItems: !element?.labelItems})
        }, {
            appearance: element?.popupTemplate ? 'primary' : 'subtle',
            icon: <ESRIIcon name="configure-popup" />,
            title: t('Map/PopupEditor'),
            onClick: () => setPopupVisible(!popupVisible)
    
        });
    }

    if (element?.subLayers?.length) {
        actions.push({
            appearance: subLayersVisible ? 'primary' : 'subtle',
            icon: <ESRIIcon name={subLayersVisible ? 'up-arrow' : 'down-arrow'} />,
            title: t('Map/Sublayers'),
            onClick: () => setSubLayersVisible(!subLayersVisible)
        });
    }

    if (element?.type) {
        actions.push({
            appearance: "primary",
            color: "yellow",
            icon: <ESRIIcon name="edit" />,
            title: t('Edit'),
            onClick: () => setEditorVisible(true)
        }, {
            appearance: "primary",
            color: "red",
            icon: <ESRIIcon name="trash" />,
            title: t('Remove'),
            onClick: () => onParamChange({delete: true})
        });
    }

    return (
        <SettingsWrapper 
            key={element?.id} 
            itemKey={element?.id} 
            level={level} 
            index={element?.orderNumber} 
        >
            <AvatarCard 
                title={element?.name}
                actions={actions} />
                        
            { subLayersVisible ? (
                <Panel className="sublayerSettings">
                    { element.subLayers.map( subLayer => (
                        <MapElementSettings 
                            key={subLayer?.layerId}
                            element={subLayer}
                            onChange={onChange}
                            level={level + 1} /> 
                    ))}
                </Panel>
            ) : <></>}

            <Modal 
                size="xs"
                open={popupVisible} 
                onClose={() => setPopupVisible(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Map/PopupEditor')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PopupForm
                        fluid
                        columns={columns}
                        title={element?.popupTemplate?.title}
                        content={element?.popupTemplate?.content}
                        onSubmit={value => {
                            setPopupVisible(false);
                            onParamChange({
                                popupTemplate: value
                            });
                        }}
                        onCancel={() => setPopupVisible(false)} />
                </Modal.Body>
            </Modal>

            <Modal 
                size="xs"
                open={editorVisible} 
                onClose={() => setEditorVisible(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Map/EditElement')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MapElementForm
                        fluid
                        element={{
                            name: element?.name,
                            path: element?.path,
                            type: element?.type
                        }}
                        onSubmit={value => {
                            setEditorVisible(false);
                            onParamChange(value);
                        }}
                        onCancel={() => setEditorVisible(false)} />
                </Modal.Body>
            </Modal>
        </SettingsWrapper>
    );
};


export default MapElementSettings;
