import Journey, { compareJourneys } from './Journey';
import { noop } from 'utils/constants';
import StopPlace from './StopPlace';


function JourneyStop({
    id,
    journeyId,
    servicePatternId,
    lineId,
    lineType,
    lineName,
    routeName,
    servicePatternName,
    startTime,
    orderNum,
    stopPlaceId,
    areaId,
    areaName,
    name,
    arrivalTime,
    departureTime,
    traffic,
}) {
    const journeyStop = {
        elementType: 'journeyStop',
        elementId: id,
        id,
        journey: Journey({
            id: journeyId,
            servicePatternId,
            lineId,
            lineType,
            lineName,
            routeName,
            servicePatternName,
            startTime,
        }),
        stopPlace: StopPlace({
            id: stopPlaceId,
            name,
            areaId,
            areaName,
        }),
        orderNum,
        arrivalTime,
        departureTime,
        traffic,
        compare: noop,
    };

    journeyStop.compare = other => compareJourneyStops(journeyStop, other);

    return journeyStop;
};


export function compareJourneyStops(a, b) {
    return a.arrivalTime - b.arrivalTime ||
        a.departureTime - b.departureTime ||
        compareJourneys(a.journey, b.journey);
};


export default JourneyStop;
