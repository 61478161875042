import React from "react";

import { noop } from "utils/constants";

import useWebmap from "../hooks/useWebmap";
import useArcGISMap from "../hooks/useArcGISMap";
import { logEvent } from "features/logging";


const WebmapContext = React.createContext({
    webmap: undefined,
    updateWebmap: noop,
    clearWebmapCache: noop,
    arcGISMap: undefined,
    joins: {},
    setJoins: noop,
});


export const WebmapContextProvider = ({
    id,
    containerId,
    children
}) => {
    const [ joins, setJoins ] = React.useState({});
    const { webmap, updateWebmap } = useWebmap(id);
    
    const arcGISMap = useArcGISMap({
        containerId: containerId || id,
        webmap,
    });

    React.useEffect(() => {
        console.log('Joins changed in webmap context.')
        logEvent('joinsChanged')
    }, [joins])

    React.useEffect(() => {
        console.log('Webmap changed in webmap context.')
    }, [webmap])

    return (
        <WebmapContext.Provider value={{ webmap, updateWebmap, arcGISMap, joins, setJoins }}>
            { children }
        </WebmapContext.Provider>
    );
};


export default WebmapContext;