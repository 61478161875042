import 'assets/css/form.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonToolbar, Form, Input } from 'rsuite';
import { t } from 'i18next';
import { FormGroup } from 'components/forms';


const LoginForm = ({
    layout,
    loading,
    onSubmit
}) => {
    const navigate = useNavigate();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    return (
        <Form layout={layout}>
            <FormGroup>
                <h5>{ t('Security/Login') }</h5>
            </FormGroup>
            <FormGroup
                name="username"
                label={t('Username')}
                accepter={Input}
                value={username}
                onChange={setUsername}
            />
            <FormGroup
                name="password"
                label={t('Password')}
                accepter={Input}
                type="password"
                autoComplete="off"
                value={password}
                onChange={setPassword} />
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" loading={loading} onClick={() => onSubmit({username, password})}>
                        { t('Security/Login') }
                    </Button>
                    
                    {/* <Button appearance="subtle" onClick={() => navigate('/register')}>
                        { t('Security/Register') }
                    </Button> */}

                    <Button appearance="subtle" onClick={() => navigate('/forgot_password')}>
                        { t('Security/ForgotPassword') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );   
};

export default LoginForm;
