import React from "react";

import { noop } from "utils/constants";

import MapLayer from "./MapLayer";
import WebmapContext from "../context/WebmapContext";


const MapElement = ({
    element,
    onRenderStarted = noop,
    onRendered = noop,
}) => {
    const { arcGISMap, joins } = React.useContext(WebmapContext);
    const elementJoins = joins?.[element.id];

    if (!elementJoins?.length) {
        const layerId = `element-${element.id}-layer-0`;
        return (
            <MapLayer 
                id={layerId}
                element={element}
                arcGISMap={arcGISMap}
                onRenderStarted={() => onRenderStarted(layerId)}
                onRendered={() => onRendered(layerId)}
            />
        );
    } else {
        return <>
            {elementJoins.map((join, i) => {
                const layerId = `element-${element.id}-layer-${i}`;
                return (
                    <MapLayer 
                        id={layerId}
                        key={i}
                        element={element}
                        join={join}
                        arcGISMap={arcGISMap}
                        onRenderStarted={() => onRenderStarted(layerId)}
                        onRendered={() => onRendered(layerId)}
                    />
                )
            })}
        </>;
    }
};


export default MapElement;
