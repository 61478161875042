import { noop } from "utils/constants";

const DAY_TYPE_ORDER = [
    1,  // mindennap
    2,  // munkanap
    9,  // hétfői munkanap (KEKO)
    10, // kedd-péntek munkanap (KEKO)
    7,  // iskolaszüneti nap
    5,  // szabadnap
    6,  // munkaszüneti nap
    8,  // Régiségvásár (MVK) 
];


const DayType = ({
    id, 
    name,
}) => {
    const dayType = {
        elementType: 'dayType',
        elementId: Number(id),
        id: Number(id),
        name,
        priority: 0,
        compare: noop, 
    };

    dayType.priority = DAY_TYPE_ORDER.indexOf(dayType.id);
    dayType.compare = function (other) {
        return compareDayTypes(dayType, other);
    };

    return dayType;
};


export function compareDayTypes (a, b) {
    return a?.priority - b?.priority;
}


export default DayType;
