import React from "react";
import { useParams } from "react-router-dom";
import { Col, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";
import SimplePage from "layouts/SimplePage";
import { WebMapTilesPanel } from "features/webmap";

import useCollection from "../hooks/useCollection";


function CollectionPage () {

    const { collectionId } = useParams();
    const collection = useCollection(collectionId);

    return (
        <SimplePage>
            
            <Jumbotron backgroundUrl={collection?.coverUrl} >
                <PageTitle item={collection} />
            </Jumbotron>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        { collection?.id && <WebMapTilesPanel collectionId={collection?.id} /> }
                    </Col>
                </Row>
            </Grid>  

        </SimplePage>
    )
};


export default CollectionPage;
