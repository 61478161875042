import FormGroup from 'components/forms/FormGroup';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import { AADT_VIEW, MADT_VIEW } from 'features/crossSectionRoadCount/utils/constants';
import { t } from 'i18next';
import React from 'react'
import { Col, Form, Row, SelectPicker } from 'rsuite'

const StationForm = ({
    oneLine
}) => {

    const module = React.useContext(CrossSectionRoadCountContext);

    const {
        firstYear = 2021,
        lastYear = 2022,
    } = module?.moduleParameters || {};

    const {
        dayTypes = {},
    } = module?.elements;

    const {
        stationIntervalType,
        stationYear,
        stationMonth,
        stationDayTypeId,
    } = module?.filters

    const years = [];
    for (let i = firstYear; i <= lastYear; i++) {
        years.push(i);
    };

    const intervalTypeOptions = [{
        label: t('OKKF/AnnualAverageDailyTraffic'),
        value: AADT_VIEW,
    }, {
        label: t('OKKF/MonthlyAverageDailyTraffic'),
        value: MADT_VIEW,
    }];

    const yearOptions = years.map(y => ({label: `${y}`, value: y}));

    const monthOptions = [];
    for (let i = 1; i <= 12; i++) {
        monthOptions.push({label: `${i}`, value: i});
    };

    const dayTypeOptions = Object.values(dayTypes).map(dayType => ({
        label: `${dayType.id} - ${dayType.name}`,
        value: dayType.id
    }))

    return <Form>
        <Row>
            <Col xs={oneLine ? 12 : 24}>
                <FormGroup
                    name="intervalType"
                    label={t('OKKF/CountIntervalType')}
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    data={intervalTypeOptions}
                    value={stationIntervalType}
                    onChange={stationIntervalType => module.applyFilters({stationIntervalType})}
                />
            </Col>
            <Col xs={oneLine ? 6 : 12}>
                <FormGroup
                    name="year"
                    label={t('OKKF/Year')}
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    data={yearOptions}
                    value={stationYear}
                    onChange={stationYear => module.applyFilters({stationYear})}
                />
            </Col>
            <Col xs={oneLine ? 3 : 6}>
                <FormGroup
                    name="month"
                    label={t('OKKF/MonthAbbrev')}
                    disabled={stationIntervalType !== MADT_VIEW}
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    data={monthOptions}
                    value={stationMonth}
                    onChange={stationMonth => module.applyFilters({stationMonth})}
                />
            </Col>
            <Col xs={oneLine ? 3 : 6}>
                <FormGroup
                    name="dayType"
                    label={t('OKKF/DayType')}
                    disabled={stationIntervalType !== MADT_VIEW}
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    data={dayTypeOptions}
                    value={stationDayTypeId}
                    onChange={stationDayTypeId => module.applyFilters({stationDayTypeId})}
                />
            </Col>
        </Row>
    </Form>
}

export default StationForm