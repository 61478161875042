import 'assets/css/onboardCount.css';

import React from 'react'


const TransportLineBadge = ({
    size="md",
    color,
    name,
}) => {
    return (
        <div className={`transportLineBadge ${size}`} style={{backgroundColor: color?.hex, color: 'white'}}>
            {name}
        </div>
    )
}

export default TransportLineBadge