import React from 'react'
import FormModal from 'components/modals/FormModal'
import { createCollection, postCollection } from '../collectionAPI'
import CollectionForm from '../forms/CollectionForm'
import { t } from 'i18next'
import { useMediaQuery } from 'rsuite'
import { noop } from 'utils/constants'

const CollectionModal = ({
    open,
    setOpen = noop,
    onClose = noop,
    item,
}) => {
    
    const [isMobile] = useMediaQuery('xs');
   
    return (
        <FormModal
            title={item?.id ? t('EditCollection') : t('CreateCollection')}
            size="lg"
            layout={isMobile ? "vertical" : "horizontal"}
            open={open}
            setOpen={setOpen}
            formData={item}
            FormClass={CollectionForm}
            onSubmit={data => {
                item?.id
                    ? postCollection(item.id, data)
                    : createCollection(data)
            }}
            onClose={onClose}
        />
    )
}

export default CollectionModal