import { t } from 'i18next'

import { Join } from 'features/webmap'
import roadNetworkLabeling from 'features/crossSectionRoadCount/lib/arcGISLabels/roadNetworkLabeling'
import roadNetworkRenderer from 'features/crossSectionRoadCount/lib/arcGISRenderers/roadNetworkRenderer'
import { LAYER_ORDER, ROAD_NETWORK_LAYER } from '../constants'

export const getRoadNetworkJoins = (joinProps = {}) => {
    return [Join({
        index: LAYER_ORDER.indexOf(ROAD_NETWORK_LAYER),
        name: ROAD_NETWORK_LAYER,
        legendEnabled: false,
        renderer: roadNetworkRenderer('KUTKA'),
        label: t('OKKF/RoadNetwork'),
        labelingInfo: roadNetworkLabeling(),
        baseField: 'KSZAM',
        targetField: 'roadName',
        ...joinProps
    })]
}
