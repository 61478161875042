import 'assets/css/crossSectionRoadCount.css';

import React from 'react'
import Badge from 'components/badge';
import { StationIcon } from '../icons';
import { t } from 'i18next';


const StationBadge = ({
    size="md",
    code
}) => {
    return <div title={t('OKKF/StationCode')}>
        <Badge size={size} type="stationBadge">
            <StationIcon style={{marginRight: 4}} />
            { code }
        </Badge>
    </div>
}

export default StationBadge