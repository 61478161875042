import React from 'react'
import { AlightBoardIcon, BoardIcon, LineTrafficIcon, OnwardIcon, SectionIcon, ServicePatternIcon, StopPlaceIcon } from '../components/icons';
import { t } from 'i18next';
import useMenu from 'features/webmapModule/hooks/useMenu';
import { ALIGHT_BOARD_COUNT, BOARD_COUNT, CROSS_SECTION_COUNT, DAILY_TRAFFIC_DISTRIBUTION, LINE_TRAFFIC_DISTRIBUTION, SECTION_TRAFFIC, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from '../utils/constants';


function useOnboardCountMenu() {

    const mainMenuData = [{
        key: TRANSPORT_LINE_TRAFFIC, 
        icon:<ServicePatternIcon/>,
        label: t('FT/TransportLineTraffic')
    }, {
        key: STOP_PLACE_TRAFFIC, 
        disabled: (module) => Boolean(module?.filters?.sectionId),
        icon:<StopPlaceIcon/>,
        label: t('FT/StopPlaceTraffic')
    }, {
        key: SECTION_TRAFFIC,
        disabled: (module) => Boolean(!module?.filters?.sectionId),
        title: (module) => module?.filters?.sectionId ? undefined : t('FT/SelectionTypeMustBeSection'),
        icon:<SectionIcon/>,
        label: t('FT/SectionTraffic')
    }];

    const tableMenu = useMenu({
        data: mainMenuData.map(({key, icon, label, ...props}) => {
            let children;
            switch (key) {
                case TRANSPORT_LINE_TRAFFIC:
                    children = [{
                        key: BOARD_COUNT,
                        icon: <BoardIcon />,
                        label: t('FT/DailyAverageTraffic')
                    }, {
                        key: ALIGHT_BOARD_COUNT,
                        disabled: module => module?.filters?.servicePatternIds?.length !== 1,
                        title: (module) => module?.filters?.servicePatternIds?.length === 1 ? undefined : t('FT/SelectServicePatternForPivot'),
                        icon: <AlightBoardIcon />,
                        label: t('FT/AlightBoardCount')
                    }, {
                        key: CROSS_SECTION_COUNT,
                        disabled: module => module?.filters?.servicePatternIds?.length !== 1,
                        title: (module) => module?.filters?.servicePatternIds?.length === 1 ? undefined : t('FT/SelectServicePatternForPivot'),
                        icon: <OnwardIcon />,
                        label: t('FT/CrossSectionTraffic')
                    }];
                    break;
                default:
                    children = [];
            }

            return {
                key,
                icon,
                label,
                ...props,
                children
            }
        }),
        defaultValues: [TRANSPORT_LINE_TRAFFIC, BOARD_COUNT]
    })

    const chartMenu = useMenu({
        data: mainMenuData.map(({key, icon, label, ...props}) => {
            let children;
            switch (key) {
                case TRANSPORT_LINE_TRAFFIC:
                    children = [{
                        key: BOARD_COUNT,
                        icon: <BoardIcon />,
                        label: t('FT/DailyAverageTraffic'),
                    }, {
                        key: DAILY_TRAFFIC_DISTRIBUTION,
                        icon: <LineTrafficIcon />,
                        label: t('FT/DailyTrafficDistribution'),
                    }, {
                        key: LINE_TRAFFIC_DISTRIBUTION,
                        disabled: module => module?.filters?.servicePatternIds?.length !== 1,
                        title: (module) => module?.filters?.servicePatternIds?.length === 1 ? undefined : t('FT/SelectServicePatternForChart'),
                        icon: <LineTrafficIcon />,
                        label: t('FT/LineTrafficDistribution'),
                    }];
                    break;
                default:
                    children = [{
                        key: BOARD_COUNT,
                        icon: <BoardIcon />,
                        label: t('FT/DailyAverageTraffic'),
                    }, {
                        key: DAILY_TRAFFIC_DISTRIBUTION,
                        icon: <LineTrafficIcon />,
                        label: t('FT/DailyTrafficDistribution'),
                    }]
            }

            return {
                key,
                icon,
                label,
                ...props,
                children,
            }
        }),
        defaultValues: [TRANSPORT_LINE_TRAFFIC, BOARD_COUNT]
    });

    React.useEffect(() => {

        if (tableMenu.values[0] !== chartMenu.values[0]) {
            chartMenu.setValues([tableMenu.values[0]]);
        }

        if (tableMenu.values[0] === TRANSPORT_LINE_TRAFFIC) {
            if (!tableMenu.values[1]) {
                tableMenu.setValues([TRANSPORT_LINE_TRAFFIC, BOARD_COUNT]);
            }

            if( tableMenu.values[1] === BOARD_COUNT && chartMenu.values[1] === LINE_TRAFFIC_DISTRIBUTION ) {
                chartMenu.setValues([TRANSPORT_LINE_TRAFFIC, BOARD_COUNT])
            }

            if ( [ALIGHT_BOARD_COUNT, CROSS_SECTION_COUNT].includes(tableMenu.values[1]) ) {
                chartMenu.setValues([TRANSPORT_LINE_TRAFFIC, LINE_TRAFFIC_DISTRIBUTION]);
            }
        }

    }, [tableMenu.values[0], tableMenu.values[1]]);

    React.useEffect(() => {

        if (chartMenu.values[0] && !chartMenu.values[1]) {
            chartMenu.setValues([chartMenu.values[0], BOARD_COUNT]);
        }

        if (tableMenu.values[0] !== chartMenu.values[0]) {
            tableMenu.setValues([chartMenu.values[0]]);
        }

        if (chartMenu.values[0] === TRANSPORT_LINE_TRAFFIC) {

            if( chartMenu.values[1] === BOARD_COUNT && tableMenu.values[1] !== BOARD_COUNT ) {
                tableMenu.setValues([TRANSPORT_LINE_TRAFFIC, BOARD_COUNT])
            }

            if ( chartMenu.values[1] === LINE_TRAFFIC_DISTRIBUTION && ![ALIGHT_BOARD_COUNT, CROSS_SECTION_COUNT].includes(tableMenu.values[1]) ) {
                tableMenu.setValues([TRANSPORT_LINE_TRAFFIC, ALIGHT_BOARD_COUNT]);
            }
        }

    }, [chartMenu.values[0], chartMenu.values[1]]);

    return {
        tableMenu,
        chartMenu
    };
};

export default useOnboardCountMenu