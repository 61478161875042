import { t } from "i18next";
import { formatString } from "utils/format";

export function getChartLabels (module) {

    const {
        area,
        stopPlace,
        section,
        servicePatterns = [],
        transportLines = []
    } = module?.selectedElements || {};

    let selectionTitle = '';

    if (area) {
        selectionTitle = ' ' + formatString(t('FT/InArea'), area);
    }

    if (stopPlace) {
        selectionTitle = ' ' + formatString(t('FT/InStopPlace'), stopPlace);
    }

    if (section?.id) {
        selectionTitle = ' ' + formatString(t('FT/InSection'), {
            name: section?.name || section.id
        });
    }

    let servicePatternTitle = '';

    if (transportLines.length) {
        servicePatternTitle = ` (${transportLines.map(transportLine => transportLine?.name).join(', ')})`;
    }
    if (servicePatterns.length) {
        servicePatternTitle = ` (${servicePatterns.map(servicePattern => `${servicePattern?.transportLine?.name} - ${servicePattern?.name}`).join(', ')})`;
    }

    return {
        selection: selectionTitle,
        servicePatterns: servicePatternTitle
    }
}