import { t } from 'i18next';
import { Join } from 'features/webmap';

import { LAYER_ORDER, ROAD_SECTION_LAYER } from '../constants';
import roadSectionRenderer from 'features/crossSectionRoadCount/lib/arcGISRenderers/roadSectionRenderer';
import roadSectionLabeling from 'features/crossSectionRoadCount/lib/arcGISLabels/roadSectionLabeling';


export const getRoadSectionJoins = () => {
    return [Join({
        index: LAYER_ORDER.indexOf(ROAD_SECTION_LAYER),
        name: ROAD_SECTION_LAYER,
        legendEnabled: false,
        renderer: roadSectionRenderer(),
        label: t('OKKF/RoadSections'),
        labelingInfo: roadSectionLabeling(),
        baseField: 'FID',
        targetField: 'FID',
        minScale: 750000,
    })]
}
