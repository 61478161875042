import React from 'react'

function useDelayedState (defaultValue, defaultSetter, timeout=1000) {
    const [value, setValue] = React.useState(defaultValue);
    const timeoutRef = React.useRef();
    
    React.useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => defaultSetter(value), timeout);
    }, [value])


    return [value, setValue]
}

export default useDelayedState