import 'assets/css/card.css';

import React from "react";
import { Panel } from "rsuite";

import CardHeader from "./CardHeader";
import ActionToolbar from './ActionToolbar';
import ActionButton from './ActionButton';


const IMAGE_PLACEHOLDER_URL = "https://shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png";


const CoverImage = ({ height, url, ...props}) => {
    return (
        <div className="coverImage" style={{ 
            height, 
            backgroundImage: `url(${url || IMAGE_PLACEHOLDER_URL})`,
            ...props.style
        }} /> 
    );
};


const CoverCard = ({
    title,
    height,
    imageURL,
    imageHeight,
    actions,
    children,
    onClick,
    ...props
}) => {

    const showCover = (imageURL || imageHeight) ? true : false;
    return (
        <Panel 
            className="card coverCard" 
            bordered 
            shaded 
            bodyFill 
            style={isNaN(height + imageHeight) ? {} : {height: height + imageHeight}} 
            {...props} 
        >
            { showCover ? (
                <CoverImage height={imageHeight} url={imageURL}/>
            ) : <></>}
            <Panel header={<CardHeader title={title}/>}>
                {children}
                { actions.length > 0 && (
                    <ActionToolbar style={{margin: '-15px -10px 10px 0'}}>
                        {actions.map((action, i) => <ActionButton key={i} {...action} />)}
                    </ActionToolbar>
                )}
            </Panel>

            {onClick ? <div className="clickable" onClick={onClick} /> : <></>}

        </Panel>
    );
};


export default CoverCard;
