import React from "react";
import { Button, IconButton } from "rsuite";


const ActionButton = ({ label, ...props }) => {
    const ButtonClass = props.icon ? IconButton : Button;
    return (
        <ButtonClass appearance="primary" size="xs" {...props}>
            {label}
        </ButtonClass>
    );
};


export default ActionButton;
